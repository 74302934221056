import { useEffect } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import Pusher from "pusher-js";

import scrollToTop from "../../helpers/scroll.to.top";

import { useDispatch, useSelector } from "react-redux";
import {
  getOrderHistoryList,
  getOrderOngoingList,
  getReceiptInfo,
  setData,
  setHasMore,
  setInitialDone,
  setLength,
  setStart,
} from "../../redux/reducer/orderReducer";
import {
  openCongratsModal,
  openReceiptModal,
} from "../../redux/reducer/modalReducer";
import {
  getCartId,
  getCartOwner,
  getGuest,
  getLogin,
  getToken,
  getUserId,
  getUsername,
  setFullLoading,
} from "../../redux/reducer/commonReducer";

import Login from "../Login";
import Receipt from "./modals/receipt";
import OrderBody from "./components/order.body";
import OrderHeader from "./components/order.header";
import StoreInfo from "../Merchant/modals/store.info";
import SelectPayment from "../Cart/modals/select.payment";
import Congratulation from "../../components/common/congratulation";
import PromoDetails from "../../components/common/promo.details";
import Faq from "../../components/common/faq";
import ResubmitRefund from "../../components/common/resubmit.refund";

export default function Order() {
  const dispatch = useDispatch();
  const { store } = useParams();
  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const orderId = searchParams.get("order_id");

  const { data } = useSelector((state) => state.order);
  const {
    method,
    token,
    token_checked,
    login,
    guest,
    merchantId,
    userId,
    username,
  } = useSelector((state) => state.common);
  const {
    isOpenRefundModal,
    isOpenFaqModal,
    isOpenPromotionDetailsModal,
    isOpenStoreInfoModal,
    isOpenCongratsModal,
    isOpenPaymentModal,
    isOpenReceiptModal,
    isOpenLoginModal,
  } = useSelector((state) => state.modal);

  const getInitialOrderHistory = () => {
    dispatch(setStart(0));
    dispatch(setLength(10));
    dispatch(setInitialDone(false));

    dispatch(
      getOrderHistoryList({
        merchant_id: merchantId,
        start: 0,
        length: 10,
      })
    )
      .unwrap()
      .then((res) => {
        dispatch(setData(res.data.records));
        if (data.length < res.data.total_records) {
          dispatch(setHasMore(true));
        }
      })
      .catch((ex) => {});
  };

  useEffect(() => {
    scrollToTop();
    dispatch(getLogin());
    dispatch(getToken());
    dispatch(getGuest());
    dispatch(getUserId());
    dispatch(getUsername());
    dispatch(getCartId());
    dispatch(getCartOwner());
  }, []);

  useEffect(() => {
    if (
      token_checked &&
      token &&
      merchantId &&
      (login === "partial" || login === "full" || guest === "true")
    ) {
      getInitialOrderHistory();

      dispatch(
        getOrderOngoingList({
          merchant_id: merchantId,
        })
      );
    }
  }, [token_checked, merchantId, login, guest]);

  useEffect(() => {
    if (orderId) {
      dispatch(
        getReceiptInfo({
          order_id: orderId,
        })
      )
        .unwrap()
        .then((res) => {
          setSearchParams("");

          if (res.data === null) {
            return;
          }

          if (
            res.data.cashback === 0 &&
            !res.data.spin_token &&
            !res.data.check_in_stamp &&
            !res.data.free_promotion_qty
          ) {
            if (res.data.payment_status === "success") {
              dispatch(openReceiptModal());
            }
          } else {
            dispatch(openCongratsModal());
          }
        })
        .catch((ex) => {});
    }
  }, [orderId]);

  useEffect(() => {
    if (token && token_checked && userId && username) {
      const pusher = new Pusher(process.env.REACT_APP_SOCKET_API_KEY, {
        cluster: "ap1",
        authEndpoint: process.env.REACT_APP_SOCKET_API_URL,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            username: username ? (username === "null" ? null : username) : null,
            "user-version": process.env.REACT_APP_VERSION,
          },
        },
      });

      const channel = pusher.subscribe("presence-user" + userId);
      var socketId = null;
      pusher.connection.bind("connected", () => {
        socketId = pusher.connection.socket_id;
      });

      channel.bind("order-status-update-event", (data) => {
        if (data.data?.refresh_type?.length > 0) {
          if (data.data.refresh_type.length === 2) {
            dispatch(
              getOrderOngoingList({
                merchant_id: merchantId,
              })
            );

            getInitialOrderHistory();
          } else {
            if (data.data.refresh_type[0] === "processing") {
              dispatch(
                getOrderOngoingList({
                  merchant_id: merchantId,
                })
              );
            } else {
              getInitialOrderHistory();
            }
          }
        }
      });

      channel.bind("order-paid-event", (data) => {
        if (data) {
          dispatch(setFullLoading(false));

          if (data.data.payment_status && data.data.order_id) {
            if (
              data.data.payment_status === "active" &&
              data.data.order_id !== null
            ) {
              if (pathname === `/${encodeURIComponent(store?.trim())}/order`) {
                getInitialOrderHistory();

                dispatch(
                  getOrderOngoingList({
                    merchant_id: merchantId,
                  })
                );
                dispatch(
                  getReceiptInfo({
                    order_id: data.data.order_id,
                  })
                )
                  .unwrap()
                  .then((res) => {
                    if (res.data === null) {
                      return;
                    }

                    if (
                      res.data.cashback === 0 &&
                      !res.data.spin_token &&
                      !res.data.check_in_stamp &&
                      !res.data.free_promotion_qty
                    ) {
                      if (res.data.payment_status === "success") {
                        dispatch(openReceiptModal());
                      }
                    } else {
                      dispatch(openCongratsModal());
                    }
                  })
                  .catch((ex) => {});
              }
            } else {
              getInitialOrderHistory();

              dispatch(
                getOrderOngoingList({
                  merchant_id: merchantId,
                })
              );
            }
          }
        }
      });

      return () => {
        channel.unbind("order-status-update-event");
        channel.unbind("order-paid-event");
        pusher.disconnect();
      };
    }
  }, [token, token_checked, userId, username]);

  return (
    <>
      <article className="order-container">
        <OrderHeader method={method} />
        <OrderBody method={method} />

        {isOpenPaymentModal && <SelectPayment />}
        {isOpenReceiptModal && <Receipt />}
        {isOpenPromotionDetailsModal && <PromoDetails />}
        {isOpenStoreInfoModal && <StoreInfo />}
        {isOpenFaqModal && <Faq />}
        {isOpenRefundModal && <ResubmitRefund />}
        {isOpenLoginModal && <Login type="partial" />}
        {isOpenCongratsModal && <Congratulation />}
      </article>
    </>
  );
}
