import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api.service";
import { Avatar, DefaultAvatar } from "../../assets/images";

export const getMerchantInfo = createAsyncThunk(
  "getMerchantInfo",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getMerchantInfo(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const initialData = {
  themeColor: {
    bg_color: "#f6f5f5",
    btn_color: "#6C5FE6",
    font_color: " #0f0e0e",
    main_color: "#ff903b",
    second_color: "#D8D7E5",
    third_color: "#e2dffa",
  },
  historyIcon: null,
  voucherIcon: null,
  marketplaceIcon: null,
  topUpIcon: null,
  profileIcon: null,
  queueIcon: null,
  deliveryIcon: null,
  dineInIcon: null,
  selfPickIcon: null,
  afterStamp: null,
  beforeStamp: null,
  storeIcon: null,
  merchantInfo: {},
  merchantInfoLoading: null,
};

const dataSlice = createSlice({
  name: "merchant",
  initialState: initialData,
  reducers: {
    setMerchantInfoLoading: (state, action) => {
      state.merchantInfoLoading = action.payload;
    },
    setThemeColor: (state, action) => {
      state.themeColor = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantInfo.pending, (state) => {
        state.merchantInfoLoading = "spashing";
      })
      .addCase(getMerchantInfo.fulfilled, (state, action) => {
        const merchantInfo = action.payload.data;

        state.merchantInfoLoading = "splashed";
        state.merchantInfo = merchantInfo;

        state.themeColor = merchantInfo.color;
        state.afterStamp =
          merchantInfo.after_stamp.length > 0
            ? merchantInfo.after_stamp[0].url
            : null;
        state.beforeStamp =
          merchantInfo.before_stamp.length > 0
            ? merchantInfo.before_stamp[0].url
            : null;

        state.avatarIcon =
          merchantInfo.avatar_icon.length > 0
            ? merchantInfo.avatar_icon[0].url
            : null;
        state.historyIcon =
          merchantInfo.history_icon.length > 0
            ? merchantInfo.history_icon[0].url
            : null;
        state.voucherIcon =
          merchantInfo.voucher_icon.length > 0
            ? merchantInfo.voucher_icon[0].url
            : null;
        state.topUpIcon =
          merchantInfo.topup_icon.length > 0
            ? merchantInfo.topup_icon[0].url
            : null;
        state.profileIcon =
          merchantInfo.coming_soon_icon.length > 0
            ? merchantInfo.coming_soon_icon[0].url
            : null;
        state.logoutIcon =
          merchantInfo.logout_icon.length > 0
            ? merchantInfo.logout_icon[0].url
            : null;
        state.marketplaceIcon =
          merchantInfo.marketplace_icon.length > 0
            ? merchantInfo.marketplace_icon[0].url
            : null;
        state.storeIcon =
          merchantInfo.store_icon.length > 0
            ? merchantInfo.store_icon[0].url
            : null;

        state.spinWinIcon =
          merchantInfo.spin_and_win_icon.length > 0
            ? merchantInfo.spin_and_win_icon[0].url
            : null;
        state.checkInIcon =
          merchantInfo.check_in_icon.length > 0
            ? merchantInfo.check_in_icon[0].url
            : null;
        state.feedbackIcon =
          merchantInfo.feedback_icon.length > 0
            ? merchantInfo.feedback_icon[0].url
            : null;
        state.reservationIcon =
          merchantInfo.reservation_icon?.length > 0
            ? merchantInfo.reservation_icon[0].url
            : null;

        state.queueIcon =
          merchantInfo.queue_icon.length > 0
            ? merchantInfo.queue_icon[0].url
            : null;
        state.deliveryIcon =
          merchantInfo.delivery_icon.length > 0
            ? merchantInfo.delivery_icon[0].url
            : null;
        state.dineInIcon =
          merchantInfo.dine_in_icon.length > 0
            ? merchantInfo.dine_in_icon[0].url
            : null;
        state.selfPickIcon =
          merchantInfo.self_pick_icon.length > 0
            ? merchantInfo.self_pick_icon[0].url
            : null;
      })
      .addCase(getMerchantInfo.rejected, (state) => {
        state.merchantInfoLoading = "spashing";
      });
  },
});

export const { setThemeColor, setMerchantInfoLoading } = dataSlice.actions;

export default dataSlice.reducer;
