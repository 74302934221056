import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import pinyin from "pinyin";
import { Icon } from "@iconify/react";

import { useToast } from "../../../components/common/toast.provider";

import { useDispatch, useSelector } from "react-redux";
import { getMenuList, setMenuList } from "../../../redux/reducer/menuReducer";
import { getConfirmLocale } from "antd/es/modal/locale";

export default function MenuSearch() {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { store } = useParams();

  const {
    deliveryMenuList,
    dineInMenuList,
    takeAwayMenuList,
    menuListLoading,
  } = useSelector((state) => state.menu);
  const { method, merchantId, tableId, sessionNo } = useSelector(
    (state) => state.common
  );

  const [searchQuery, setSearchQuery] = useState({
    value: "",
    type: "initial",
  });
  const [originalMenuList, setOriginalMenuList] = useState([]);

  const menuList =
    method === "delivery"
      ? deliveryMenuList
      : method === "dine in"
      ? dineInMenuList
      : takeAwayMenuList;

  const handleSearchChange = (event) => {
    setSearchQuery({
      value: event.target.value,
      type: "null",
    });
  };

  const searchMenu = () => {
    const cleanSearchQuery = pinyin(searchQuery.value, { style: "normal" })
      .join("")
      .toLowerCase()
      .replace(/[^a-zA-Z0-9\u4e00-\u9fff]/g, "");

    const searchedMenu = originalMenuList.map((category) => {
      const filteredMenu = category.menu.filter((menuItem) => {
        const pinyinChineseName = menuItem.chinese_name
          ? pinyin(menuItem.chinese_name, { style: "normal" })
              .join("")
              .toLowerCase()
              .replace(/[^a-zA-Z0-9\u4e00-\u9fff]/g, "")
          : "";
        const pinyinName = pinyin(menuItem.menu_name, { style: "normal" })
          .join("")
          .toLowerCase()
          .replace(/[^a-zA-Z0-9\u4e00-\u9fff]/g, "");
        const englishName = menuItem.menu_name.toLowerCase();
        const menuCode = menuItem.menu_code?.toLowerCase();

        return (
          pinyinChineseName?.includes(cleanSearchQuery) ||
          englishName.includes(cleanSearchQuery) ||
          pinyinName.includes(cleanSearchQuery) ||
          menuCode?.includes(cleanSearchQuery)
        );
      });

      return {
        ...category,
        menu: filteredMenu,
      };
    });

    dispatch(setMenuList(searchedMenu));
  };

  const handleClearSearch = () => {
    setSearchQuery({
      value: "",
      type: "null",
    });
  };

  useEffect(() => {
    if (
      !menuListLoading &&
      menuList.length !== 0 &&
      originalMenuList.length === 0
    ) {
      setOriginalMenuList(menuList);
    }
  }, [menuListLoading, menuList]);

  useEffect(() => {
    if (searchQuery.type !== "initial") {
      if (searchQuery.length === 0) {
        dispatch(setMenuList(originalMenuList));

        // dispatch(
        //   getMenuList({
        //     merchant_id: merchantId,
        //     remark: "food",
        //     method: method,
        //     table_id: method === "dine in" ? tableId ? tableId === 'null' ? null : tableId : null : null,
        //     session_no: method === "dine in" ? sessionNo ? sessionNo === 'null' ? null : sessionNo : null : null,
        //   })
        // ).catch((ex) => {
        //   if (ex && ex.response?.status === 422) {
        //     const errors = ex.response.data.errors;
        //     if (errors && Object.keys(errors).length > 0) {
        //       Object.keys(errors).map((item, i) => {
        //         if (item === "table_id") {
        //           navigate(`/${store}`);
        //         }
        //         toast.error(errors[item][0]);
        //       });
        //     }
        //   }
        // });
      } else {
        searchMenu();
      }
    }
  }, [searchQuery]);

  return (
    <section className="menu-search">
      <Icon icon="tabler:search" />
      <input
        type="text"
        placeholder="Search by name"
        value={searchQuery.value}
        onChange={handleSearchChange}
      />
      {searchQuery.value.length > 0 && (
        <article className="pointer" onClick={() => handleClearSearch()}>
          <Icon
            className="clear-icon"
            icon="lets-icons:close-ring-duotone-line"
          />
        </article>
      )}
    </section>
  );
}
