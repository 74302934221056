import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api.service";
import authService from "../../services/auth.service";
import { P } from "../../assets/images";

export const getGuestToken = createAsyncThunk(
  "getGuestToken",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getGuestToken(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTopUpPaymentList = createAsyncThunk(
  "getTopUpPaymentList",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getTopUpPaymentList(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTopUpPackageList = createAsyncThunk(
  "getTopUpPackageList",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getTopUpPackageList(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getPromotionInfo = createAsyncThunk(
  "getPromotionInfo",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getPromotionInfo(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getMerchantList = createAsyncThunk(
  "getMerchantList",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getMerchantList(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSupermerchantList = createAsyncThunk(
  "getSupermerchantList",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getSupermerchantList(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const subscribeNotification = createAsyncThunk(
  "subscribeNotification",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.subscribeNotification(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const handleLogout = createAsyncThunk(
  "logout",
  async (payload, thunkAPI) => {
    try {
      const response = await authService.logout(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const initialData = {
  footerListing: [
    {
      id: 1,
      title: "Service",
      active: false,
      // icon: 'ic:outline-keyboard-arrow-down',
      item: [],
    },
    {
      id: 2,
      title: "About",
      active: false,
      // icon: 'ic:outline-keyboard-arrow-down',
      item: [],
    },
    {
      id: 3,
      title: "Support",
      active: false,
      // icon: 'ic:outline-keyboard-arrow-down',
      item: [
        // {
        //   label: "Support",
        //   to: "",
        // },
      ],
    },
  ],
  navigation_item: [
    {
      label: "Home",
      navigate: "/",
      image: P,
      animation: P,
      icon: "fluent:home-48-filled",
    },
    {
      label: "Store",
      navigate: "/store",
      image: P,
      animation: P,
      icon: "fluent:home-48-filled",
    },
    {
      label: "MyCar",
      navigate: "/car",
      image: P,
      animation: P,
      icon: "fluent:home-48-filled",
    },
    {
      label: "Profile",
      navigate: "/profile",
      image: P,
      animation: P,
      icon: "fluent:home-48-filled",
    },
  ],
  fullLoading: false,
  token: "",
  userId: "",
  cartOwner: "",
  token_checked: false,
  tableId_checked: false,
  voucherPopup_checked: false,
  login: "",
  guest: null,
  username: "",
  deviceId: "",
  deviceId_checked: false,
  queueId: "",
  reserveId: "",
  reserveData: [],
  addressId: "",
  merchantId: "",
  voucherPopup: "",
  isPopupDisplayed: true,
  cartId: "",
  tableId: "",
  sessionNo: "",
  topUpPaymentList: [],
  topUpPaymentLoading: false,
  topUpPackageList: [],
  topUpPackageLoading: false,
  promoInfoLoading: false,
  confirmItem: {},
  useNowItem: {},
  prizeItem: {},
  promoInfo: {},
  merchantListLoading: false,
  supermerchantListLoading: false,
  merchantList: [],
  supermerchantList: [],
  type: "",
  dineIn: "",
  isShowModalLoading: false,
  faqList: {
    Order: [
      {
        title: "How do I place an order using FoodLink?",
        content:
          "You can easily place an order by scanning the QR code provided by the merchant. This will direct you to their FoodLink page where you can browse the menu and place your order.",
      },
      {
        title: "What payment methods are accepted on FoodLink?",
        content:
          "Payment methods vary based on the merchant’s settings. Typically, FoodLink accepts online banking, e-wallets, and credit/debit cards. Some merchants may also offer pay-at-counter options (for dine-in orders only) or credit payment.",
      },
      {
        title: "Can I place an order without logging in?",
        content:
          "Yes, for dine-in orders, you can continue as a guest. However, for self-pickup and delivery orders, you need to be logged in to place an order.",
      },
      {
        title: "Can I pay later at the counter?",
        content:
          "This depends on the merchant’s settings. If the merchant enables the pay-at-counter option, you can select it as a payment method and will be redirected to the order confirmation page without going through a payment gateway. This option is available for dine-in orders only.",
      },
      {
        title: "Can I schedule an order for delivery or self-pickup?",
        content:
          "Yes, FoodLink allows scheduled orders for both delivery and self-pickup. You can choose your preferred time on the cart page before placing your order.",
      },
      {
        title: "Can my order be cancelled?",
        content:
          "Yes, your order can be cancelled by the merchant. Delivery orders may also be cancelled if a delivery rider cannot be assigned within 30 minutes from the order time. Please keep an eye on your order status.",
      },
      {
        title:
          "What should I do if there is an issue with my order (e.g. incorrect items, damaged or spilled)?",
        content:
          "You can contact the merchant directly by obtaining their contact information from the store info section on the homepage, located next to the login area.",
      },
    ],
    Refund: [
      {
        title: "Will I get a refund if my order is cancelled?",
        content:
          "Yes, if your order is cancelled, a full refund will be processed back to your original payment method.",
      },
      {
        title: "Why is my refund taking more than 48 hours?",
        content:
          "Refund processing times can be affected by your payment provider’s procedures, holidays, or working hours. Please allow for additional time in such cases.",
      },
      {
        title: "What should I do if my refund fails?",
        content:
          "If a refund fails, the amount will be credited to your FoodLink credit wallet. You can use this credit for future orders or submit a manual refund request, specifying your preferred refund method and providing the necessary information. Please note that manual refunds may take additional time to process. If you paid using FPX, you will need to submit a manual refund request as FPX refunds cannot be processed automatically.",
      },
      {
        title: "Why does my refund fail if I paid using FPX?",
        content:
          "If you paid using FPX, refunds cannot be processed automatically and will always fail. In such cases, the refund amount will be credited to your FoodLink credit wallet, and you will need to submit a manual refund request to receive your refund via another method.",
      },
      {
        title: "Can I request a refund after 7 days?",
        content:
          "Refunds are only available within 7 days of the original transaction. After 7 days, refund requests cannot be processed.",
      },
      {
        title: "Why was my refund cancelled?",
        content:
          "Refunds may be cancelled by the merchant. Please check the provided reason for cancellation, or if no reason is provided, contact the merchant directly for further assistance.",
      },
    ],
    Voucher: [
      {
        title: "How do I claim and apply a voucher on FoodLink?",
        content:
          "You can claim vouchers directly from the homepage (if available). Once claimed, you can select the voucher on the cart page before checkout to apply it to your order.",
      },
      {
        title: "Why can't I use my voucher?",
        content:
          "Please click on the voucher to view its terms and conditions, as well as the expiry date. Ensure that your order meets the voucher's requirements.",
      },
      {
        title:
          "How do I use the voucher specified on the receipt under the 'Use it on next visit' section?",
        content:
          "The voucher is automatically added to your voucher bag, and you can use it for your next order.",
      },
    ],
  },
};

const dataSlice = createSlice({
  name: "common",
  initialState: initialData,
  reducers: {
    setShowModalLoading: (state, action) => {
      state.isShowModalLoading = action.payload;
    },
    setPrizeItem: (state, action) => {
      state.prizeItem = action.payload;
    },
    setConfirmItem: (state, action) => {
      state.confirmItem = action.payload;
    },
    setUseNowItem: (state, action) => {
      state.useNowItem = action.payload;
    },
    setFullLoading: (state, action) => {
      state.fullLoading = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setReserveId: (state, action) => {
      state.reserveId = action.payload;
    },
    getToken: (state, action) => {
      state.token = localStorage.getItem("token");
      state.token_checked = true;
    },
    getLogin: (state, action) => {
      state.login = localStorage.getItem("login");
    },
    getGuest: (state, action) => {
      state.guest = localStorage.getItem("guest");
    },
    getUserId: (state, action) => {
      state.userId = localStorage.getItem("user_id");
    },
    getCartOwner: (state, action) => {
      state.cartOwner = localStorage.getItem("cart_owner");
    },
    getVoucherPopup: (state, action) => {
      state.voucherPopup = localStorage.getItem("voucher_popup");
      state.voucherPopup_checked = true;
    },
    getMethod: (state, action) => {
      state.method = localStorage.getItem("method");
    },
    getDineIn: (state, action) => {
      state.dineIn = localStorage.getItem("dine_in");
    },
    getUsername: (state, action) => {
      state.username = localStorage.getItem("username");
    },
    getDeviceId: (state, action) => {
      state.deviceId = localStorage.getItem("device_id");
      state.deviceId_checked = true;
    },
    getTableId: (state, action) => {
      state.tableId = localStorage.getItem("table_id");
      state.tableId_checked = true;
    },
    getSessionNo: (state, action) => {
      state.sessionNo = localStorage.getItem("session_no");
    },
    getCartId: (state, action) => {
      state.cartId = localStorage.getItem("cart_id");
    },
    getAddressId: (state, action) => {
      state.addressId = localStorage.getItem("address_id");
    },
    getMerchantId: (state, action) => {
      state.merchantId = localStorage.getItem("merchant_id");
    },
    getMerchantName: (state, action) => {
      state.merchantName = localStorage.getItem("merchant_name");
    },
    getQueueId: (state, action) => {
      state.queueId = localStorage.getItem("queue_id");
    },
    getReserveData: (state, action) => {
      state.reserveData =
        JSON.parse(localStorage.getItem("reserve_data")) || [];
    },
    getPopupDisplayed: (state, action) => {
      state.isPopupDisplayed = localStorage.getItem("is_popup_displayed");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTopUpPaymentList.pending, (state) => {
        state.topUpPaymentLoading = true;
      })
      .addCase(getTopUpPaymentList.fulfilled, (state, action) => {
        state.topUpPaymentLoading = false;
        state.topUpPaymentList = action.payload.data;
      })
      .addCase(getTopUpPaymentList.rejected, (state) => {
        state.topUpPaymentLoading = false;
      })

      .addCase(getTopUpPackageList.pending, (state) => {
        state.topUpPackageLoading = true;
      })
      .addCase(getTopUpPackageList.fulfilled, (state, action) => {
        state.topUpPackageLoading = false;
        state.topUpPackageList = action.payload.data;
      })
      .addCase(getTopUpPackageList.rejected, (state) => {
        state.topUpPackageLoading = false;
      })

      .addCase(getPromotionInfo.pending, (state) => {
        state.promoInfoLoading = true;
      })
      .addCase(getPromotionInfo.fulfilled, (state, action) => {
        state.promoInfoLoading = false;
        state.promoInfo = action.payload.data;
      })
      .addCase(getPromotionInfo.rejected, (state) => {
        state.promoInfoLoading = false;
      })

      .addCase(getMerchantList.pending, (state) => {
        state.merchantListLoading = true;
      })
      .addCase(getMerchantList.fulfilled, (state, action) => {
        state.merchantListLoading = false;
        state.merchantList = action.payload.data;
      })
      .addCase(getMerchantList.rejected, (state) => {
        state.merchantListLoading = false;
      })

      .addCase(getSupermerchantList.pending, (state) => {
        state.supermerchantListLoading = true;
      })
      .addCase(getSupermerchantList.fulfilled, (state, action) => {
        state.supermerchantListLoading = false;
        state.supermerchantList = action.payload.data.aaData;
      })
      .addCase(getSupermerchantList.rejected, (state) => {
        state.supermerchantListLoading = false;
      });
  },
});

export const {
  getToken,
  getUsername,
  getDeviceId,
  getPopupDisplayed,
  getLogin,
  getGuest,
  getUserId,
  getCartOwner,
  getVoucherPopup,
  getMethod,
  getAddressId,
  getMerchantId,
  getMerchantName,
  getTableId,
  getCartId,
  getQueueId,
  getReserveData,
  getSessionNo,
  setReserveId,
  setUseNowItem,
  setConfirmItem,
  setPrizeItem,
  setFullLoading,
  setShowModalLoading,
  setType,
  getDineIn,
} = dataSlice.actions;

export default dataSlice.reducer;
