import { useEffect, useRef, useState } from "react";

import { Icon } from "@iconify/react";
import { MDBModalBody } from "mdb-react-ui-kit";

import scrollToTop from "../../../helpers/scroll.to.top";
import { useToast } from "../../../components/common/toast.provider";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import {
  closeVoucherModal,
  openPromotionDetailsModal,
} from "../../../redux/reducer/modalReducer";
import {
  getCartInfo,
  getMyPromotion,
  setAvailableData,
  setHasMore,
  setStart,
} from "../../../redux/reducer/cartReducer";
import {
  getCartId,
  getPromotionInfo,
} from "../../../redux/reducer/commonReducer";

import Button from "../../../components/element/button";
import HalfModal from "../../../components/modal/half.modal.box";
import ModalHeader from "../../../components/header/modal.header";

import apiService from "../../../services/api.service";
import { Skeleton } from "primereact/skeleton";
import InfiniteScroll from "react-infinite-scroll-component";

export default function SelectVoucher() {
  const toast = useToast();
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const { realWidth, width, height } = useWindowDimensions();

  const {
    start,
    length,
    hasMore,
    availableData,
    availableLoading,
    cartInfo,
    scheduleDate,
    scheduleTime,
  } = useSelector((state) => state.cart);
  const { isOpenVoucherModal } = useSelector((state) => state.modal);
  const { cartId, merchantId, method } = useSelector((state) => state.common);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(
    cartInfo.promotion_id ?? null
  );
  const [selectedBagVoucher, setSelectedBagVoucher] = useState(
    cartInfo.user_promotion_bag_id ?? null
  );

  let scrollHeight = height - (width > 550 ? height * 0.2 : height * 0.4);

  const getDateTime = (dateTime) => {
    const dateObject = new Date(dateTime);

    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");
    const hours = String(dateObject.getHours()).padStart(2, "0");
    const minutes = String(dateObject.getMinutes()).padStart(2, "0");

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;

    return formattedDateTime;
  };

  const getMoreData = () => {
    dispatch(
      getMyPromotion({
        cart_id: cartId,
        merchant_id: merchantId,
        status: "pending",
        start: start + 14,
        length: length,
      })
    )
      .unwrap()
      .then((res) => {
        dispatch(setStart(start + 14));

        let newData = [];
        for (var key in res.data.promotion_bag) {
          newData.push({
            ...res.data.promotion_bag[key],
          });
        }

        dispatch(setAvailableData([...availableData, ...newData]));

        if (availableData?.length + newData.length >= res.data.total_records) {
          dispatch(setHasMore(false));
        }
      })
      .catch((ex) => {});
  };

  const handleCloseVoucherModal = () => {
    dispatch(closeVoucherModal());
    setSelectedVoucher("");
  };

  const handleApplyVoucher = (id, bagId) => {
    if (bagId !== null) {
      setSelectedVoucher(id);
      setSelectedBagVoucher(bagId);
    } else {
      setSelectedVoucher(id);
      setSelectedBagVoucher(null);
    }
  };

  const handleConfirmVoucher = async () => {
    setConfirmLoading(true);
    try {
      const response = await apiService.updateCart({
        cart_id: cartId,
        cart_item_id: null,
        remark: "",
        quantity: 0,
        promotion_id: selectedVoucher,
        user_promotion_bag_id: selectedBagVoucher,
        do_not_use_any: selectedVoucher ? 0 : 1,
        ...((method === "take away" || method === "delivery") &&
          scheduleTime?.time && {
            selfpick_mode: scheduleTime?.asap ? "ASAP" : "scheduled",
            ...(!scheduleTime?.asap &&
              scheduleTime?.time !== "ASAP" && {
                scheduled_at: `${scheduleDate.full_date} ${scheduleTime["24hr_format"]}:00`,
              }),
          }),
      });

      if (response) {
        dispatch(
          getCartInfo({
            merchant_id: merchantId,
            order_method: method,
          })
        )
          .then((res) => {
            setConfirmLoading(false);
            handleCloseVoucherModal();

            if (selectedVoucher) {
              toast.success(
                "Apply voucher successful. Please double confirm when checkout.",
                "success"
              );
            }
          })
          .catch((ex) => {});
      }
    } catch (ex) {
      setConfirmLoading(false);
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              toast.error(errors[item][0]);
            });
          }
        }

        if (ex.response?.status === 404) {
          localStorage.removeItem("cart_id");
          dispatch(getCartId());
          handleCloseVoucherModal();
          toast.error("Your cart has been outdated. Please refresh.");

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    }
  };

  // const promotionBagCopy =
  //   myPromotion.promotion_bag?.length > 0 && myPromotion.promotion_bag.slice();
  // const sortedPromotionBag = (myPromotion.promotion_bag?.slice() || []).sort(
  //   (a, b) => {
  //     if (b.is_usable !== a.is_usable) {
  //       return b.is_usable - a.is_usable;
  //     }
  //     const aIncludesMethod = a.method?.includes(method) ? 1 : 0;
  //     const bIncludesMethod = b.method?.includes(method) ? 1 : 0;
  //     return bIncludesMethod - aIncludesMethod;
  //   }
  // );

  const handleOpenPromoDetails = (id) => {
    dispatch(openPromotionDetailsModal());
    dispatch(closeVoucherModal());
    dispatch(
      getPromotionInfo({
        id: id,
      })
    );
  };

  useEffect(() => {
    if (cartInfo.promotion_id) {
      setSelectedVoucher(cartInfo.promotion_id);
    }
  }, [cartInfo]);

  useEffect(() => {
    if (modalRef.current) {
      scrollToTop(modalRef.current);
    }
  }, [isOpenVoucherModal]);

  return (
    <>
      <HalfModal
        type={realWidth >= 450 ? "mobile" : ""}
        show={isOpenVoucherModal}
        backButton={handleCloseVoucherModal}
        desktopModal={width >= 991 ? true : false}
        screenSize={width >= 991 ? "xl" : ""}
        content={
          <>
            <ModalHeader
              title="Save More with Voucher"
              backTo={handleCloseVoucherModal}
              backToNoAnimation={handleCloseVoucherModal}
              type={`model2 ${realWidth < 450 ? "half" : ""}`}
            />
            <MDBModalBody ref={modalRef} className="fixed-body overflow-hidden">
              <article className="select-voucher-modal">
                {/* <section className="voucher-listing"> */}
                <InfiniteScroll
                  className="voucher-listing p-0"
                  dataLength={availableData?.length}
                  height={`${scrollHeight}px`}
                  hasMore={hasMore}
                  next={() => {
                    getMoreData();
                  }}
                  loader={
                    hasMore && (
                      <div className="text-center mt-4 infinite-loading-label">
                        Loading..
                      </div>
                    )
                  }
                >
                  {(hasMore || !availableLoading) && availableData?.length > 0
                    ? availableData.map((myPromo, myPromoIndex) => (
                        <article
                          className={`voucher-grid ${
                            myPromo[
                              myPromo.applicable_type === "AI"
                                ? "id"
                                : "user_promotion_bag_id"
                            ] ===
                            (myPromo.applicable_type === "AI"
                              ? selectedVoucher
                              : selectedBagVoucher)
                              ? "--active"
                              : myPromo.is_usable === 0 ||
                                (myPromo.method &&
                                  !myPromo.method?.includes(method))
                              ? "--disabled"
                              : ""
                          }`}
                          key={myPromoIndex}
                        >
                          <article
                            className="voucher-info w-100"
                            onClick={() => handleOpenPromoDetails(myPromo.id)}
                          >
                            <h4>{myPromo.title}</h4>
                            {myPromo.unavailable_reason ? (
                              <p className="reason">
                                {myPromo.unavailable_reason}
                                <span>&nbsp;&nbsp;T&C</span>
                              </p>
                            ) : myPromo.method &&
                              !myPromo.method?.includes(method) ? (
                              <p className="reason">
                                This promotion is valid for
                                {myPromo.method?.map((method, methodIndex) => (
                                  <>
                                    &nbsp;{method}
                                    {methodIndex !==
                                    myPromo.method.length - 1 ? (
                                      methodIndex ===
                                      myPromo.method.length - 2 ? (
                                        <>&nbsp;and</>
                                      ) : (
                                        <>,</>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ))}
                                &nbsp;only
                                <span>&nbsp;&nbsp;T&C</span>
                              </p>
                            ) : myPromo.expiry_date ? (
                              <p>
                                Valid till {getDateTime(myPromo.expiry_date)}
                                <span>&nbsp;&nbsp;T&C</span>
                              </p>
                            ) : (
                              <p>
                                <span>T&C</span>
                              </p>
                            )}
                          </article>
                          <article
                            className="voucher-divider"
                            onClick={() => {
                              if (
                                myPromo.is_usable === 1 &&
                                myPromo.method &&
                                myPromo.method?.includes(method)
                              ) {
                                handleApplyVoucher(
                                  myPromo.id,
                                  myPromo.user_promotion_bag_id
                                );
                              }
                            }}
                          >
                            <Icon icon="ic:twotone-circle" />
                          </article>
                        </article>
                      ))
                    : !hasMore && availableLoading
                    ? Array.from({ length: 2 }, (_, voucherSkeletonIndex) => (
                        <Skeleton
                          className="voucher-grid border-0"
                          key={voucherSkeletonIndex}
                        />
                      ))
                    : ""}
                  {!hasMore &&
                  !availableLoading &&
                  availableData?.length > 0 ? (
                    <div className="text-center mt-4 infinite-loading-label --nothing">
                      Nothing more..
                    </div>
                  ) : !hasMore &&
                    !availableLoading &&
                    availableData?.length === 0 ? (
                    <article className="empty-container">
                      <p>You didn't claim any promotion yet.</p>
                    </article>
                  ) : (
                    ""
                  )}
                </InfiniteScroll>
                {!availableLoading && (
                  <article
                    className={`voucher-grid ${
                      selectedVoucher === "" || !selectedVoucher
                        ? "--no-border-active"
                        : "--no-border"
                    }`}
                    onClick={() => handleApplyVoucher("")}
                  >
                    <article className="voucher-info">
                      <h4>Don't use any now</h4>
                    </article>
                    <article className="voucher-divider --no-border">
                      <Icon icon="ic:twotone-circle" />
                    </article>
                  </article>
                )}
              </article>
            </MDBModalBody>
            <section className="model2-summary --fixed --no-shadow">
              <Button
                className="w-100"
                btnClassName="w-100"
                disabled={confirmLoading || availableLoading}
                onClick={() => handleConfirmVoucher()}
              >
                Confirm Voucher
              </Button>
            </section>
          </>
        }
      />
    </>
  );
}
