import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api.service";

export const getCheckInList = createAsyncThunk(
  "getCheckInList",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getCheckInList(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const initialData = {
  stampLeft: 0,
  checkInList: [],
  checkInPrizeList: [],
  checkInPeriod: {},
  checkInReward: [],
  checkInHistory: [],
  checkInLoading: true,
  stampCollection: [],
  checkInStampTerms: [
    {
      content: `Welcome to FoodLink's Check-in Stamp Program! By participating in our Check-in Stamp Program, you agree to comply with the following terms and conditions:`,
    },
    {
      title: "Eligibility",
      content:
        "The FoodLink Check-in Stamp Program is open to all registered users of the FoodLink platform.",
    },
    {
      title: "Minimum Check-in Amount",
      content:
        "To receive a check-in stamp, users must hit a minimum spending amount as specified by FoodLink. The minimum amount required for each check-in stamp will be clearly communicated to users.",
    },
    {
      title: "Check-in Stamp Rewards",
      content:
        "Each check-in stamp earned entitles the user to a chance to win exciting rewards. Rewards may include discounts, coupons, freebies, or other promotional offers determined by FoodLink.",
    },
    {
      title: "Claiming Rewards",
      content:
        "Users who receive a stamp reward must claim their reward within the specified timeframe provided by FoodLink. Failure to claim the reward within the designated period may result in forfeiture of the reward.",
    },
    {
      title: "Reward Expiration",
      content:
        "Stamp rewards may have an expiration date specified by FoodLink. If the user fails to claim the reward before the expiration date, the reward may become invalid, and the user will no longer be able to claim it.",
    },
    {
      title: "Reward Redemption",
      content:
        "Stamp rewards can typically be redeemed on FoodLink's platform during checkout or at participating locations, as specified by FoodLink. Specific redemption instructions will be provided along with the reward.",
    },
    {
      title: "Prohibited Activities",
      content:
        "The Check-in Stamp Program is intended for genuine participation only. Any attempt to manipulate or abuse the program, including but not limited to fraudulent check-ins or multiple accounts, will result in disqualification from the program and forfeiture of rewards.",
    },
    {
      title: "Changes to Terms",
      content:
        "FoodLink reserves the right to modify, suspend, or terminate the Check-in Stamp Program or its terms and conditions at any time without prior notice. Any changes will be effective immediately upon posting on our website or app.",
    },
    {
      title: "Acceptance of Terms",
      content:
        "By participating in the Check-in Stamp Program, you acknowledge that you have read, understood, and agreed to these terms and conditions.",
    },
    {
      content: `For any questions or concerns regarding the Check-in Stamp Program, please contact our customer support team at`,
      email: process.env.REACT_APP_SUPPORT_EMAIL,
    },
    {
      content: `Thank you for participating in FoodLink's Check-in Stamp Program! We hope you enjoy the rewards and benefits.`,
    },
  ],

  hasMore: false,
  length: 10,
  start: 0,
  data: [],
};

const dataSlice = createSlice({
  name: "check_in",
  initialState: initialData,
  reducers: {
    setHasMore: (state, action) => {
      state.hasMore = action.payload;
    },
    setStart: (state, action) => {
      state.start = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLength: (state, action) => {
      state.length = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCheckInList.pending, (state) => {
        state.checkInLoading = true;
      })
      .addCase(getCheckInList.fulfilled, (state, action) => {
        state.checkInLoading = false;
        state.checkInPeriod = {
          start_at: action.payload.data.activate_start_at,
          end_at: action.payload.data.activate_end_at,
        };
        state.checkInList = action.payload.data.current_cycle;
        state.checkInReward = action.payload.data.merchant_check_in_listing;
        state.checkInPrizeList = action.payload.data.prize_list;
        state.checkInHistory = action.payload.data.user_check_in_history;
        state.stampCollection =
          action.payload.data.last_six_merchant_check_in_listing;
        state.checkInProgress = {
          checked_in: action.payload.data.current_check_in_index ?? 0,
          next_reward: action.payload.data.next_reward_index,
          total_check_in: action.payload.data.total_check_in_item,
        };
        state.amountGetStamp =
          action.payload.data.min_check_in_amount_per_order;
      })
      .addCase(getCheckInList.rejected, (state) => {
        state.checkInLoading = false;
      });
  },
});

export const { setHasMore, setStart, setData, setLength } = dataSlice.actions;

export default dataSlice.reducer;
