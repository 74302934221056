import { useSearchParams } from "react-router-dom";
import { useEffect, useRef } from "react";

import { Icon } from "@iconify/react";
import domtoimage from "dom-to-image";
import { MDBContainer, MDBModalBody, MDBRow, MDBCol } from "mdb-react-ui-kit";

import scrollToTop from "../../../helpers/scroll.to.top";
import { useToast } from "../../../components/common/toast.provider";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import {
  closeReceiptModal,
  openPromotionDetailsModal,
} from "../../../redux/reducer/modalReducer";

import Button from "../../../components/element/button";
import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";
import Coupon from "../../../components/common/coupon";
import { getPromotionInfo } from "../../../redux/reducer/commonReducer";

export default function Receipt() {
  const dispatch = useDispatch();
  const toast = useToast();
  const modalRef = useRef(null);
  const { width } = useWindowDimensions();

  const [searchParams, setSearchParams] = useSearchParams();

  const { receiptInfo, receiptInfoLoading } = useSelector(
    (state) => state.order
  );
  const { isOpenReceiptModal } = useSelector((state) => state.modal);

  const getDateTime = (dateTime) => {
    const dateObject = new Date(dateTime);

    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const year = dateObject.getFullYear();
    const hours = String(dateObject.getHours()).padStart(2, "0");
    const minutes = String(dateObject.getMinutes()).padStart(2, "0");

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;

    return formattedDateTime;
  };

  const getImage = () => {
    const receiptContainer = document.querySelector(".receipt-container");
    const paymentInfoContainer = document.querySelector(
      ".payment-info-container"
    );

    if (receiptContainer && receiptInfo?.receipt_no !== null) {
      domtoimage
        .toPng(receiptContainer, {
          height:
            receiptContainer.scrollHeight - paymentInfoContainer.scrollHeight,
        })
        .then((dataUrl) => {
          fetch(dataUrl)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File([blob], `${receiptInfo.receipt_no}.png`, {
                type: "image/png",
              });

              const shareData = {
                files: [file],
                title: "Receipt",
                text: "Here's the receipt.",
              };

              if (
                navigator.canShare &&
                navigator.canShare({ files: shareData.files })
              ) {
                navigator.share(shareData).catch((error) => toast.error(error));
              } else {
                const link = document.createElement("a");
                link.href = dataUrl;
                link.download = "receipt.png";
                link.click();
              }
            });
        })
        .catch((error) => {
          console.error("Failed to capture the receipt as an image");
        });
    } else {
      console.error("Receipt container not found");
    }
  };

  const handleCloseReceipt = () => {
    dispatch(closeReceiptModal());
    setSearchParams("");
  };

  const handleOpenPromoDetails = (id) => {
    dispatch(openPromotionDetailsModal("open-receipt"));
    dispatch(closeReceiptModal());
    dispatch(
      getPromotionInfo({
        id: id,
      })
    );
  };

  function flattenAndGroupOrderItems(receiptInfo) {
    if (!receiptInfo || !receiptInfo.order) {
      return [];
    }

    const allOrderItems = receiptInfo.order.flatMap((order) =>
      order.order_items_array.map((item) => ({
        ...item,
        menu_detail: item.menu_detail.map((detail) => ({
          id: detail.id,
          option_item_id: detail.option_item_id,
          name: detail.name,
          price: detail.price,
        })),
      }))
    );

    const groupedByMenuName = allOrderItems.reduce((acc, item) => {
      const key = item.menu_name;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    const combinedAndSortedOrderItems = Object.entries(groupedByMenuName)
      .sort((a, b) => a[0].localeCompare(b[0])) // Sort groups by menu name
      .flatMap(([_, items]) => items); // Flatten to a single array

    return combinedAndSortedOrderItems;
  }

  const combinedOrderItems = flattenAndGroupOrderItems(receiptInfo);

  useEffect(() => {
    if (modalRef.current) {
      scrollToTop(modalRef.current);
    }
  }, [isOpenReceiptModal]);

  return (
    <FullModal
      show={isOpenReceiptModal}
      backButton={handleCloseReceipt}
      contentClassName="--white"
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      content={
        <>
          <ModalHeader
            title="Receipt"
            backTo={handleCloseReceipt}
            backToNoAnimation={handleCloseReceipt}
            type="model2"
          />
          <MDBModalBody ref={modalRef} className="fixed-body">
            {!receiptInfoLoading ? (
              <article className="receipt-container" id="generate-receipt">
                <MDBRow className="gap-3">
                  <div className="_label --outlet-name">
                    {receiptInfo.store_name}
                  </div>
                  <div className="_label --outlet-info">
                    {receiptInfo.store_address}
                  </div>
                  {receiptInfo.sales_service_tax > 0 && (
                    <div className="_label --outlet-info">
                      SST ID : {receiptInfo.sst_no}
                    </div>
                  )}
                </MDBRow>
                <MDBRow className="dotted-line mt-4 gap-1">
                  <div className="_label --invoice">Invoice</div>
                  <div className="_label --invoice-info">
                    Receipt no : {receiptInfo.receipt_no ?? ""}
                  </div>
                  <div className="_label --invoice-info">
                    Paid at :{" "}
                    {receiptInfo.paid_at
                      ? getDateTime(receiptInfo.paid_at)
                      : ""}
                  </div>
                  <div className="mb-4" />
                </MDBRow>
                <MDBRow className="mt-4">
                  <div className="_label --invoice-info text-center">
                    *** {receiptInfo.dine_in_method} ***
                  </div>
                  <article>
                    {combinedOrderItems?.length > 0 &&
                      combinedOrderItems.map((orderItem, orderItemIndex) => (
                        <article key={orderItemIndex}>
                          <div className="flex justify-between items-start _label --menu-info mt-4">
                            <div className="ps-2">{orderItem.quantity}</div>
                            <div className="menu-detail">
                              <div className="mb-2">{orderItem.menu_name}</div>
                              {orderItem.menu_detail?.length > 0 &&
                                orderItem.menu_detail.map(
                                  (detail, detailIndex) => (
                                    <div
                                      className="_label --menu-details"
                                      key={detailIndex}
                                    >
                                      {detail.name}
                                    </div>
                                  )
                                )}
                              {orderItem.remark && (
                                <div className="_label --menu-details">
                                  ** {orderItem.remark}
                                </div>
                              )}
                              {orderItem.redemption_remark && (
                                <div className="_label --menu-details">
                                  {orderItem.redemption_remark}
                                </div>
                              )}
                              {orderItem.redemption_remark &&
                                orderItem.promotion_title && (
                                  <div className="_label --menu-details">
                                    {orderItem.promotion_title} Voucher Applied
                                  </div>
                                )}
                            </div>
                            <div>
                              {parseFloat(orderItem.total_price).toFixed(2)}
                            </div>
                          </div>
                          {orderItem.promotion_free_promotion
                            ?.promotion_title && (
                            <div
                              className="promo-item _label --menu-info mt-3 mb-3"
                              key={orderItemIndex}
                            >
                              <div>
                                <Icon icon="tdesign:discount" />
                              </div>
                              <div className="promo-detail">
                                <div className="_label --promo-title">
                                  {orderItem.promotion_title} Voucher Applied
                                </div>
                              </div>
                            </div>
                          )}
                        </article>
                      ))}
                    {receiptInfo.order?.length > 0 &&
                      receiptInfo.order.map(
                        (order, orderIndex) =>
                          order.promotion_free_promotion?.promotion_title && (
                            <div
                              className="promo-item _label --menu-info mt-4"
                              key={orderIndex}
                            >
                              <div>
                                <Icon icon="tdesign:discount" />
                              </div>
                              <div className="promo-detail">
                                <div className="_label --promo-title">
                                  {order.promotion_title} Voucher Applied
                                </div>
                              </div>
                            </div>
                          )
                      )}
                  </article>
                  <div className="mb-4" />
                </MDBRow>
                <MDBRow className="dotted-line mt-4 gap-1">
                  {receiptInfo.remark && (
                    <>
                      <div className="_label --invoice-info">
                        Special Remark : {receiptInfo.remark}
                      </div>
                      <div className="mb-4" />
                    </>
                  )}
                </MDBRow>
                <MDBRow className="mt-4 gap-1">
                  <div className="flex items-baseline _label --invoice-info">
                    <MDBCol className="col-9 p-0">
                      <label>Subtotal</label>
                    </MDBCol>
                    <MDBCol className="col-3 text-end">
                      <label>
                        {receiptInfo.subtotal
                          ? parseFloat(receiptInfo.subtotal).toFixed(2)
                          : "0.00"}
                      </label>
                    </MDBCol>
                  </div>
                  {receiptInfo.promotion_deduct_amount > 0 && (
                    <div className="flex items-baseline _label --invoice-info">
                      <MDBCol className="col-9 p-0">
                        <label>Promotion</label>
                      </MDBCol>
                      <MDBCol className="col-3 text-end">
                        <label>
                          -{" "}
                          {parseFloat(
                            receiptInfo.promotion_deduct_amount
                          ).toFixed(2)}
                        </label>
                      </MDBCol>
                    </div>
                  )}
                  {receiptInfo.referral_discount > 0 && (
                    <div className="flex items-baseline _label --invoice-info">
                      <MDBCol className="col-9 p-0">
                        <label>Referral Discount</label>
                      </MDBCol>
                      <MDBCol className="col-3 text-end">
                        <label>
                          -{" "}
                          {parseFloat(receiptInfo.referral_discount).toFixed(2)}
                        </label>
                      </MDBCol>
                    </div>
                  )}
                  {receiptInfo.merchant_deducted_amount > 0 && (
                    <div className="flex items-baseline _label --invoice-info">
                      <MDBCol className="col-9 p-0">
                        <label>
                          Merchant Discount{" "}
                          {receiptInfo.merchant_discount_amount &&
                            receiptInfo.merchant_discount_type ===
                              "percent" && (
                              <>
                                (
                                {parseFloat(
                                  receiptInfo.merchant_discount_amount
                                ).toFixed(0)}
                                %)
                              </>
                            )}
                        </label>
                      </MDBCol>
                      <MDBCol className="col-3 text-end">
                        <label>
                          -{" "}
                          {parseFloat(
                            receiptInfo.merchant_deducted_amount
                          ).toFixed(2)}
                        </label>
                      </MDBCol>
                    </div>
                  )}
                  {receiptInfo.sst > 0 && (
                    <div className="flex items-baseline _label --invoice-info">
                      <MDBCol className="col-9 p-0">
                        <label>
                          SST ({parseFloat(receiptInfo.sst_percent).toFixed(0)}
                          %)
                        </label>
                      </MDBCol>
                      <MDBCol className="col-3 text-end">
                        <label>{parseFloat(receiptInfo.sst).toFixed(2)}</label>
                      </MDBCol>
                    </div>
                  )}
                  {receiptInfo.service_charge > 0 && (
                    <div className="flex items-baseline _label --invoice-info">
                      <MDBCol className="col-9 p-0">
                        <label>
                          Service Charge (
                          {parseFloat(
                            receiptInfo.service_charge_percent
                          ).toFixed(0)}
                          %)
                        </label>
                      </MDBCol>
                      <MDBCol className="col-3 text-end">
                        <label>
                          {receiptInfo.service_charge
                            ? parseFloat(receiptInfo.service_charge).toFixed(2)
                            : "0.00"}
                        </label>
                      </MDBCol>
                    </div>
                  )}
                  {receiptInfo.delivery_fees && (
                    <div className="flex items-baseline _label --invoice-info">
                      <MDBCol className="col-9 p-0">
                        <label>Delivery Fees</label>
                      </MDBCol>
                      <MDBCol className="col-3 text-end">
                        <label>
                          {receiptInfo.delivery_fees > 0
                            ? parseFloat(receiptInfo.delivery_fees).toFixed(2)
                            : "0.00"}
                        </label>
                      </MDBCol>
                    </div>
                  )}
                  <div className="flex items-baseline _label --invoice-info">
                    <MDBCol className="col-9 p-0">
                      <label>Rounding</label>
                    </MDBCol>
                    <MDBCol className="col-3 text-end">
                      <label>{receiptInfo.rounding_adjustment}</label>
                    </MDBCol>
                  </div>
                </MDBRow>
                <MDBRow>
                  <div className="mt-4 _label --total-paid">
                    Total Paid: RM{" "}
                    {receiptInfo.total > 0
                      ? parseFloat(receiptInfo.total).toFixed(2)
                      : "0.00"}
                  </div>
                </MDBRow>

                {(receiptInfo.order?.some(
                  (order) => order.promotion_free_promotion?.promotion_title
                ) ||
                  combinedOrderItems?.some(
                    (item) => item.promotion_free_promotion?.promotion_title
                  )) && (
                  <MDBRow className="text-start">
                    <div className="_label --payment-info">
                      Use On Next Visit
                    </div>
                    {receiptInfo.order?.length > 0 &&
                      receiptInfo.order.map(
                        (order, orderIndex) =>
                          order.promotion_free_promotion?.promotion_title && (
                            <article className="mb-4" key={orderIndex}>
                              <Coupon
                                promoInfo={{
                                  title:
                                    order.promotion_free_promotion
                                      .promotion_title,
                                  id: order.promotion_free_promotion
                                    .promotion_id,
                                  media: order.promotion_media,
                                  expiry_date: order.promotion_expiry_date,
                                  tnc: "123",
                                  type: "receipt",
                                }}
                                textClassName="--receipt"
                                handleOpenPromoDetails={handleOpenPromoDetails}
                              />
                            </article>
                          )
                      )}
                    {combinedOrderItems?.length > 0 &&
                      combinedOrderItems.map((orderItem, orderItemIndex) => {
                        return (
                          orderItem.promotion_free_promotion
                            ?.promotion_title && (
                            <article className="mb-4" key={orderItemIndex}>
                              <Coupon
                                promoInfo={{
                                  title:
                                    orderItem.promotion_free_promotion
                                      .promotion_title,
                                  id: orderItem.promotion_free_promotion
                                    .promotion_id,
                                  media: orderItem.promotion_media,
                                  expiry_date: orderItem.promotion_expiry_date,
                                  tnc: "123",
                                  type: "receipt",
                                }}
                                textClassName="--receipt"
                                handleOpenPromoDetails={handleOpenPromoDetails}
                              />
                            </article>
                          )
                        );
                      })}
                  </MDBRow>
                )}

                <MDBRow className="text-start">
                  <div className="_label --payment-info mt-4">Payment Info</div>
                  <MDBContainer className="payment-info-container">
                    <div className="flex items-center">
                      <MDBCol className="col-6 p-0">
                        <label className="_label --payment-label">Status</label>
                      </MDBCol>
                      <MDBCol className="col-6 text-end">
                        <label className="_label --payment-no">
                          {receiptInfo.payment_status === "success"
                            ? "Paid"
                            : "Unpaid"}
                        </label>
                      </MDBCol>
                    </div>
                    <div className="flex items-center mt-3">
                      <MDBCol className="col-6 p-0">
                        <label className="_label --payment-label">
                          Payment
                        </label>
                      </MDBCol>
                      <MDBCol className="col-6 text-end">
                        <label className="_label --payment-no text-capitalize">
                          {receiptInfo.payment_method}
                        </label>
                      </MDBCol>
                    </div>
                  </MDBContainer>
                </MDBRow>
              </article>
            ) : (
              <article className="general-loading --receipt">
                <Icon icon="svg-spinners:12-dots-scale-rotate" />
              </article>
            )}
          </MDBModalBody>
          <section className="model2-summary --fixed">
            <Button
              className="w-100"
              disabled={receiptInfoLoading}
              btnClassName="w-100 share-receipt-btn"
              onClick={() => getImage()}
            >
              Share Receipt
              <Icon icon="solar:share-line-duotone" />
            </Button>
          </section>
        </>
      }
    />
  );
}
