//service
import http from "./http.service";

http.setToken(getToken());

async function login(payload) {
  const { data } = await http.post(
    process.env.REACT_APP_API_URL + "/validate_verification_code",
    payload
  );
  return data;
}

async function preRegister(payload) {
  const { data } = await http.post(
    process.env.REACT_APP_API_URL + "/verify_user_if_exist",
    payload
  );
  return data;
}

async function sendVerificationCode(payload) {
  const { data } = await http.post(
    process.env.REACT_APP_API_URL + `/send_verification_code`,
    payload
  );
  return data;
}

async function logout() {
  localStorage.clear();
}

function getToken() {
  return localStorage.getItem("token");
}

const authService = {
  login,
  preRegister,
  sendVerificationCode,
  logout,
  getToken,
};

export default authService;
