import { useEffect, useState } from "react";

import { Skeleton } from "primereact/skeleton";
import { MDBModalBody } from "mdb-react-ui-kit";

import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import {
  closePromotionModal,
  openCouponPopUp,
  openPromotionDetailsModal,
} from "../../../redux/reducer/modalReducer";

import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";
import {
  getMyPromotion,
  setAvailableData,
  setAvailableLoading,
  setData,
  setHasMore,
  setLength,
  setMyPromotionLoading,
  setStart,
  setUsedData,
  setUsedLoading,
} from "../../../redux/reducer/cartReducer";
import {
  getPromotionInfo,
  setUseNowItem,
} from "../../../redux/reducer/commonReducer";

import Coupon from "../../../components/common/coupon";
import InfiniteScroll from "react-infinite-scroll-component";

export default function MyVoucher() {
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();

  const { merchantId } = useSelector((state) => state.common);
  const { isOpenPromotionModal } = useSelector((state) => state.modal);
  const {
    usedLoading,
    availableLoading,
    start,
    length,
    usedData,
    availableData,
    hasMore,
  } = useSelector((state) => state.cart);

  const [currentStatus, setCurrentStatus] = useState("pending");

  let scrollHeight = height - (width > 550 ? height * 0.2 : height * 0.15);

  const voucherOption = [
    { value: "pending", title: "Available" },
    { value: "completed", title: "Used" },
  ];

  function formatExpiryDate(date) {
    const expiryDate = new Date(date);

    const day = expiryDate.getDate();
    const month = expiryDate.toLocaleString("default", { month: "short" });
    const year = expiryDate.getFullYear();

    const formattedDateString = `${day} ${month} ${year}`;

    return formattedDateString;
  }

  const getInitialPromoList = (status) => {
    dispatch(setStart(0));
    dispatch(setLength(14));
    dispatch(setHasMore(false));

    if (status === "pending") {
      dispatch(setAvailableLoading(true));
    } else {
      dispatch(setUsedLoading(true));
    }

    dispatch(
      getMyPromotion({
        cart_id: "",
        merchant_id: merchantId,
        status: status,
        start: 0,
        length: 14,
      })
    )
      .unwrap()
      .then((res) => {
        if (status === "pending") {
          dispatch(setAvailableData(res.data.promotion_bag));
          dispatch(setAvailableLoading(false));

          if (availableData.length < res.data.total_records) {
            dispatch(setHasMore(true));
          }
        } else {
          dispatch(setUsedData(res.data.promotion_bag));
          dispatch(setUsedLoading(false));

          if (usedData.length < res.data.total_records) {
            dispatch(setHasMore(true));
          }
        }
      })
      .catch((ex) => {});
  };

  const getMoreData = () => {
    dispatch(
      getMyPromotion({
        cart_id: "",
        merchant_id: merchantId,
        status: currentStatus,
        start: start + 14,
        length: length,
      })
    )
      .unwrap()
      .then((res) => {
        dispatch(setStart(start + 14));

        let newData = [];
        for (var key in res.data.promotion_bag) {
          newData.push({
            ...res.data.promotion_bag[key],
          });
        }

        if (currentStatus === "pending") {
          dispatch(setAvailableData([...availableData, ...newData]));

          if (availableData.length + newData.length >= res.data.total_records) {
            dispatch(setHasMore(false));
          }
        } else {
          dispatch(setUsedData([...usedData, ...newData]));

          if (usedData.length + newData.length >= res.data.total_records) {
            dispatch(setHasMore(false));
          }
        }
      })
      .catch((ex) => {});
  };

  const handleClosePromotion = () => {
    dispatch(closePromotionModal());
  };

  const handleOpenPromoDetails = (id) => {
    handleClosePromotion();
    dispatch(openPromotionDetailsModal("open-bag"));
    dispatch(
      getPromotionInfo({
        id: id,
      })
    );
  };

  const handleUseNow = (promo) => {
    dispatch(closePromotionModal());
    dispatch(
      setUseNowItem({
        promotion_id: promo.user_promotion_bag_id,
        media: promo.media,
        display_name: promo.title,
        expiry_date: formatExpiryDate(promo.expiry_date),
      })
    );
    dispatch(openCouponPopUp());
  };

  const handleFilterPromotion = (value) => {
    setCurrentStatus(value);
    getInitialPromoList(value);
  };

  useEffect(() => {
    getInitialPromoList(currentStatus);
  }, []);

  return (
    <FullModal
      show={isOpenPromotionModal}
      backButton={handleClosePromotion}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      contentClassName="--white"
      content={
        <>
          <ModalHeader
            title="My Voucher"
            backTo={handleClosePromotion}
            backToNoAnimation={handleClosePromotion}
            type="model2"
          />
          <MDBModalBody>
            <article className="my-voucher-modal">
              <section className="my-voucher-header">
                <article className="curve-bg --short"></article>
                <article className="voucher-option pointer">
                  {voucherOption.map((option, optionIndex) => (
                    <h6
                      className={
                        option.value === currentStatus ? "--active" : ""
                      }
                      onClick={() =>
                        !(
                          (currentStatus === "pending"
                            ? availableLoading
                            : usedLoading) && option.value === currentStatus
                        )
                          ? handleFilterPromotion(option.value)
                          : ""
                      }
                      key={optionIndex}
                    >
                      {option.title}
                    </h6>
                  ))}
                </article>
              </section>
              <InfiniteScroll
                className="voucher-listing"
                dataLength={
                  (currentStatus === "pending" ? availableData : usedData)
                    .length
                }
                height={`${scrollHeight}px`}
                hasMore={hasMore}
                next={() => {
                  getMoreData();
                }}
                loader={
                  hasMore && (
                    <div className="text-center mt-4 infinite-loading-label">
                      Loading..
                    </div>
                  )
                }
              >
                {(hasMore ||
                  !(currentStatus === "pending"
                    ? availableLoading
                    : usedLoading)) &&
                (currentStatus === "pending" ? availableData : usedData)
                  ?.length > 0
                  ? (currentStatus === "pending"
                      ? availableData
                      : usedData
                    ).map((myPromo, myPromoIndex) => (
                      <article className="relative w-100" key={myPromoIndex}>
                        {currentStatus === "completed" && (
                          <article className="used-badge">
                            <p>Used</p>
                          </article>
                        )}
                        <Coupon
                          tnc={currentStatus === "pending"}
                          promoInfo={myPromo}
                          handleUseNow={handleUseNow}
                          handleOpenPromoDetails={handleOpenPromoDetails}
                          status={currentStatus}
                          className={`--brown ${
                            currentStatus === "completed" ? "default" : ""
                          }`}
                        />
                      </article>
                    ))
                  : !hasMore &&
                    (currentStatus === "pending"
                      ? availableLoading
                      : usedLoading)
                  ? Array.from({ length: 2 }, (_, promoSkeletonIndex) => (
                      <Skeleton
                        width="100%"
                        height="85px"
                        border-radius="8px"
                        key={promoSkeletonIndex}
                      />
                    ))
                  : ""}
                {!hasMore &&
                !(currentStatus === "pending"
                  ? availableLoading
                  : usedLoading) ? (
                  (currentStatus === "pending" ? availableData : usedData)
                    .length > 0 ? (
                    <div className="text-center mt-4 infinite-loading-label --nothing">
                      Nothing more..
                    </div>
                  ) : currentStatus === "pending" ? (
                    <article className="empty-container">
                      <p>You didn't claim any promotion yet.</p>
                    </article>
                  ) : (
                    <article className="empty-container">
                      <p>You didn't used up any promotion yet.</p>
                    </article>
                  )
                ) : (
                  ""
                )}
              </InfiniteScroll>
            </article>
          </MDBModalBody>
        </>
      }
    />
  );
}
