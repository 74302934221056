import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Icon } from "@iconify/react";
import { MDBModalBody } from "mdb-react-ui-kit";

import { useDispatch, useSelector } from "react-redux";
import {
  closeSetAddressModal,
  openSaveAddressModal,
  setAnimationModal,
} from "../../../redux/reducer/modalReducer";
import {
  clearAddressInfo,
  getAddressList,
  setSearchedAddress,
  setSearchingAddress,
} from "../../../redux/reducer/addressReducer";

import scrollToTop from "../../../helpers/scroll.to.top";
import { useToast } from "../../../components/common/toast.provider";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import AddressList from "../components/address.list";
import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";
import AddressInput from "../../../components/common/google.search.address";
import { setShowModalLoading } from "../../../redux/reducer/commonReducer";

export default function SetAddress({ addresses }) {
  const toast = useToast();
  const modalRef = useRef(null);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const { store } = useParams();
  const { pathname } = useLocation();
  const { width } = useWindowDimensions();

  const [noAddressSet, setNoAddressSet] = useState(false);

  const { method, addressId } = useSelector((state) => state.common);
  const { isOpenSetAddressModal } = useSelector((state) => state.modal);
  const { isNoAddressFound, searchingAddress } = useSelector(
    (state) => state.address
  );

  const handleClear = () => {
    dispatch(setSearchedAddress({}));
    dispatch(setSearchingAddress(""));
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handlePlaceSelected = (place) => {
    dispatch(setSearchingAddress(""));
    dispatch(setSearchedAddress(place));
    handleSaveAddress();
  };

  const handleCloseSetAddress = () => {
    dispatch(setShowModalLoading(false));

    if (!noAddressSet) {
      dispatch(closeSetAddressModal());
    }
  };

  const handleSaveAddress = () => {
    dispatch(clearAddressInfo());
    dispatch(closeSetAddressModal());
    dispatch(openSaveAddressModal());
    dispatch(setAnimationModal(false));
  };

  useEffect(() => {
    dispatch(getAddressList());
    dispatch(setAnimationModal(true));
  }, []);

  useEffect(() => {
    if (
      method === "delivery" &&
      !addressId &&
      (pathname === `/${encodeURIComponent(store?.trim())}/menu` ||
        pathname === `/${encodeURIComponent(store?.trim())}/cart` ||
        pathname === `/${encodeURIComponent(store?.trim())}/order`)
    ) {
      setNoAddressSet(true);
      toast.error("Set a new address or select an existing one to proceed.");
    }
  }, [method, addressId, pathname]);

  useEffect(() => {
    if (modalRef.current) {
      scrollToTop(modalRef.current);
    }
  }, [isOpenSetAddressModal]);

  return (
    <FullModal
      show={isOpenSetAddressModal}
      backButton={!noAddressSet ? handleCloseSetAddress : undefined}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      content={
        <>
          <ModalHeader
            backTo={!noAddressSet ? handleCloseSetAddress : undefined}
            title="Set Your Address"
            type="model2"
          />
          <MDBModalBody ref={modalRef}>
            <article className="set-address-modal">
              <section className="search-bar">
                <article>
                  <Icon icon="eva:search-outline" />
                </article>
                <article className="ms-4 w-100">
                  <AddressInput
                    onPlaceSelected={handlePlaceSelected}
                    inputRef={inputRef}
                  />
                </article>
                {inputRef.current?.value && (
                  <article className="pointer" onClick={() => handleClear()}>
                    <Icon icon="bitcoin-icons:cross-filled" />
                  </article>
                )}
              </section>
              {isNoAddressFound && searchingAddress.length > 4 && (
                <p>
                  Couldn't find your location.{" "}
                  <span onClick={() => handleSaveAddress()}>Click here</span>
                </p>
              )}
              <AddressList />
            </article>
          </MDBModalBody>
        </>
      }
    />
  );
}
