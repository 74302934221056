import { useEffect, useRef } from "react";

import { Sample } from "../../../assets/images";

import { Icon } from "@iconify/react";
import { MDBModalBody } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

import scrollToTop from "../../../helpers/scroll.to.top";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import { closeStoreInfoModal } from "../../../redux/reducer/modalReducer";

import Button from "../../../components/element/button";
import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";

export default function StoreInfo() {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const { width } = useWindowDimensions();

  const { merchantInfo } = useSelector((state) => state.merchant);
  const { isOpenStoreInfoModal } = useSelector((state) => state.modal);

  const handleCloseStoreInfo = () => {
    dispatch(closeStoreInfoModal());
  };

  function convertToAmPm(time) {
    const [start, end] = time.split("-").map((t) => {
      let [hour, minute] = t.split(":");
      hour = parseInt(hour);
      const period = hour >= 12 ? "PM" : "AM";
      hour = hour % 12 || 12;
      return `${hour}:${minute} ${period}`;
    });
    return `${start} - ${end}`;
  }

  const operationDays = {
    Monday:
      merchantInfo.is_unavailable_on_monday === 0
        ? merchantInfo.operating_hour
        : "Closed",
    Tuesday:
      merchantInfo.is_unavailable_on_tuesday === 0
        ? merchantInfo.operating_hour
        : "Closed",
    Wednesday:
      merchantInfo.is_unavailable_on_wednesday === 0
        ? merchantInfo.operating_hour
        : "Closed",
    Thursday:
      merchantInfo.is_unavailable_on_thursday === 0
        ? merchantInfo.operating_hour
        : "Closed",
    Friday:
      merchantInfo.is_unavailable_on_friday === 0
        ? merchantInfo.operating_hour
        : "Closed",
    Saturday:
      merchantInfo.is_unavailable_on_saturday === 0
        ? merchantInfo.operating_hour
        : "Closed",
    Sunday:
      merchantInfo.is_unavailable_on_sunday === 0
        ? merchantInfo.operating_hour
        : "Closed",
  };

  useEffect(() => {
    if (modalRef.current) {
      scrollToTop(modalRef.current);
    }
  }, [isOpenStoreInfoModal]);

  return (
    <FullModal
      show={isOpenStoreInfoModal}
      backButton={handleCloseStoreInfo}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      content={
        <>
          <ModalHeader
            title="Store Info"
            backTo={handleCloseStoreInfo}
            backToNoAnimation={handleCloseStoreInfo}
            type="model2"
          />
          <MDBModalBody ref={modalRef}>
            <article className="store-info-modal">
              <section className="frame --store">
                <LazyLoadImage
                  src={merchantInfo.picture || Sample}
                  alt={merchantInfo.store_name || "merchant_store"}
                />
              </section>
              <section className="store-info">
                <h1>{merchantInfo.store_name}</h1>
                <article className="store-grid">
                  <section className="store-col">
                    <Icon icon="mynaui:store" />
                    <p>{merchantInfo.store_name}</p>
                  </section>
                  <section className="store-col">
                    <Icon icon="fluent:call-24-regular" />
                    <p>{merchantInfo.contact_no}</p>
                  </section>
                </article>
                <section className="store-col">
                  <article>
                    <Icon icon="carbon:location" />
                  </article>
                  <p>{merchantInfo.display_address}</p>
                </section>
                {(merchantInfo.google_map_link || merchantInfo.waze_link) && (
                  <article className="store-location">
                    {merchantInfo.waze_link !== null && (
                      <Button
                        onClick={() =>
                          merchantInfo.waze_link !== null
                            ? window.open(merchantInfo.waze_link, "_blank")
                            : ""
                        }
                      >
                        <Icon icon="simple-icons:waze" />
                      </Button>
                    )}
                    {merchantInfo.google_map_link !== null && (
                      <Button
                        onClick={() =>
                          merchantInfo.google_map_link !== null
                            ? window.open(
                                merchantInfo.google_map_link,
                                "_blank"
                              )
                            : ""
                        }
                      >
                        <Icon icon="simple-icons:googlemaps" />
                      </Button>
                    )}
                  </article>
                )}
                <article className="store-detail">
                  <section className="title">
                    <Icon icon="fluent-mdl2:date-time" className="me-2" />
                    <h3>Operation Hours</h3>
                  </section>
                  <section className="hour-content">
                    {Object.keys(operationDays).map((day, dayIndex) => (
                      <article className="flex" key={dayIndex}>
                        <p style={{ minWidth: "95px" }}>{day}</p>
                        <section>
                          {Array.isArray(operationDays[day]) &&
                          operationDays[day]?.length > 0 ? (
                            operationDays[day].map((hour, hourIndex) => (
                              <p key={hourIndex}>{convertToAmPm(hour)}</p>
                            ))
                          ) : (
                            <p>{operationDays[day]}</p>
                          )}
                        </section>
                      </article>
                    ))}
                  </section>
                </article>
                {merchantInfo.announcement?.length > 0 && (
                  <article className="store-detail">
                    <section className="title">
                      <Icon
                        icon="material-symbols-light:edit-note-outline"
                        className="bigger-icon"
                      />
                      <h3>Notice</h3>
                    </section>
                    <section className="notice-content">
                      {merchantInfo.announcement.map(
                        (announce, announceIndex) => (
                          <article className="content" key={announceIndex}>
                            <article>
                              <Icon icon="lucide:dot" />
                            </article>
                            <p key={announceIndex}>{announce.content}</p>
                          </article>
                        )
                      )}
                    </section>
                  </article>
                )}
              </section>
            </article>
          </MDBModalBody>
        </>
      }
    />
  );
}
