import { useNavigate, useParams } from "react-router-dom";

import { Icon } from "@iconify/react";

import { useDispatch, useSelector } from "react-redux";
import {
  openFaqModal,
  openLoginModal,
} from "../../../redux/reducer/modalReducer";

import PageHeader from "../../../components/header/page.header";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

export default function CartHeader({ method, setLoginType }) {
  const { store } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState("");

  const { cartInfo } = useSelector((state) => state.cart);
  const { login, guest } = useSelector((state) => state.common);

  const handleBackToMenu = () => {
    navigate(`/${store}/menu`);
  };

  const handleNavToOrder = () => {
    if (login === "partial" || login === "full" || guest === "true") {
      navigate(`/${store}/order`);
    } else {
      dispatch(openLoginModal());
      setLoginType("partial-cart");
    }
  };

  const handleOpenFAQ = () => {
    dispatch(openFaqModal());
  };

  return (
    <header
      className={`cart-header ${method === "dine in" ? "--dine-in" : ""}`}
    >
      <PageHeader
        backIcon="mdi:backburger"
        backTo={handleBackToMenu}
        navTo={handleNavToOrder}
        className="pb-0"
      />
      <section className="sub-header">
        <article className="flex items-center justify-between">
          <h1>Cart</h1>
          <article
            className="help-button pointer"
            onClick={() => handleOpenFAQ()}
          >
            <p>FAQ</p>
            <article className="help-icon">
              <Icon icon="akar-icons:chat-question" />
            </article>
          </article>
        </article>
        <article className="flex justify-between">
          <p>Order Summary</p>
        </article>
        <article className="steps mt-3 relative">
          <hr className="step-line" />
          <section className="grid center">
            <article className="step --checked">
              <Icon icon="material-symbols:check" />
            </article>
            <p className="step-text">Select</p>
          </section>
          <section className="grid center">
            <article className="step --checked">
              <Icon icon="material-symbols:check" />
            </article>
            <p className="step-text">Confirm</p>
          </section>
          <section className="grid center">
            <article className="step" />
            <p className="step-text">Order</p>
          </section>
        </article>
      </section>
    </header>
  );
}
