import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FullModal from "../modal/full.modal.box";
import { MDBContainer, MDBModalBody, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import Button from "../element/button";
import {
  Avatar,
  Cashback,
  Congrats,
  CongratsTop,
  Doubt,
  PromoOne,
} from "../../assets/images";
import {
  closeConfirmModal,
  closeCongratsModal,
  openLoginModal,
  openMarketModal,
  openPaymentModal,
  openReceiptModal,
} from "../../redux/reducer/modalReducer";
import { clearCart, getCartInfo } from "../../redux/reducer/cartReducer";
import { setConfirmItem } from "../../redux/reducer/commonReducer";
import apiService from "../../services/api.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useWindowDimensions from "../hook/use.window.dimensions";
import Confetti from "./confetti";

export default function Congratulation({ setLoginType }) {
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const { store } = useParams();
  const { pathname } = useLocation();

  const { receiptInfo } = useSelector((state) => state.order);
  const { login, token } = useSelector((state) => state.common);
  const { isOpenCongratsModal, receivePoint } = useSelector(
    (state) => state.modal
  );

  const [hoveredRow, setHoveredRow] = useState(null);

  const earnedRow = [
    {
      label: "Cashback",
      amount:
        receiptInfo?.cashback > 0
          ? parseFloat(receiptInfo?.cashback).toFixed(2)
          : null,
      navTo: "",
    },
    {
      label: "Spin wheel token",
      amount: receiptInfo?.spin_token,
      navTo: "/spin-wheel",
    },
    {
      label: "Check-in stamp",
      amount: receiptInfo?.check_in_stamp,
      navTo: "/check-in",
    },
    { label: "Voucher", amount: receiptInfo?.free_promotion_qty, navTo: "" },
  ];

  const handleOpenReceipt = () => {
    dispatch(closeCongratsModal());

    if (receiptInfo?.payment_status === "success") {
      dispatch(openReceiptModal());
    }
  };

  const handleNavTo = (to) => {
    // navigate(`/${store}${to}`)
    window.open(`/${store}${to}`, "_blank");
  };

  const handleViewPoint = () => {
    dispatch(closeCongratsModal());

    if (token && login === "full") {
      dispatch(openMarketModal("point"));
    } else {
      setLoginType("full-point");
      dispatch(openLoginModal());
    }
  };

  const filteredRows = earnedRow.filter((row) => row.amount !== null);

  return (
    <FullModal
      centered
      scrollable
      staticBackdrop={true}
      show={isOpenCongratsModal}
      backButton={""}
      screenSize={"xl"}
      className="--backdrop"
      dialogClassName={width >= 600 ? "--margin" : ""}
      contentClassName={`--transparent ${
        width >= 600 ? "--short --congrats" : ""
      }`}
      content={
        <>
          <MDBModalBody ref={modalRef}>
            {isOpenCongratsModal && <Confetti />}
            <article className="congrats-modal">
              <article className="congrats-figure">
                <article className="congrats-frame">
                  <LazyLoadImage src={CongratsTop} />
                  <h2>Congratulations!</h2>
                  <p>
                    Exciting news! You've{" "}
                    {pathname === `/${encodeURIComponent(store?.trim())}/order`
                      ? "earned rewards"
                      : `been credited with ${receivePoint}`}
                    <br />
                    {pathname === `/${encodeURIComponent(store?.trim())}/order`
                      ? "with this order"
                      : "points in your account"}
                    . Check your balance now!
                  </p>
                  {pathname !==
                    `/${encodeURIComponent(store?.trim())}/order` && (
                    <p className="--view" onClick={() => handleViewPoint()}>
                      <span>View Points</span>
                    </p>
                  )}
                </article>
              </article>
              {pathname === `/${encodeURIComponent(store?.trim())}/order` && (
                <section className="earned-info">
                  {filteredRows.map(
                    (row, index) =>
                      row.amount !== null && (
                        <article
                          key={index}
                          className={`info-flex ${
                            index === filteredRows.length - 1 ? "--last" : ""
                          } ${
                            row.amount > 0 && row.amount !== "-"
                              ? "pointer"
                              : ""
                          }`}
                          onMouseEnter={() =>
                            row.amount > 0 && row.amount !== "-"
                              ? setHoveredRow(index)
                              : ""
                          }
                          onMouseLeave={() =>
                            row.amount > 0 && row.amount !== "-"
                              ? setHoveredRow(null)
                              : ""
                          }
                          onClick={() => {
                            if (hoveredRow === index) {
                              handleNavTo(row.navTo);
                            }
                          }}
                        >
                          <h4>{row.label}</h4>
                          <p className={hoveredRow !== index ? "me-2" : ""}>
                            {hoveredRow === index ? (
                              <Icon icon="icon-park-outline:right" />
                            ) : (
                              row.amount
                            )}
                          </p>
                        </article>
                      )
                  )}
                </section>
              )}
              <section
                className={`earned-summary ${
                  pathname !== `/${encodeURIComponent(store?.trim())}/order`
                    ? "mt-5"
                    : ""
                }`}
              >
                <Button
                  btnClassName="close-btn"
                  onClick={() => handleOpenReceipt()}
                >
                  <Icon icon="maki:cross" />
                </Button>
              </section>
            </article>
          </MDBModalBody>
        </>
      }
    />
  );
}
