import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { FullLogo } from "../../assets/images";

import { Icon } from "@iconify/react";
import { MDBModalBody } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

import FullLogin from "./components/full.login";
import PreRegister from "./components/pre.register";
import FullModal from "../../components/modal/full.modal.box";

import { useToast } from "../../components/common/toast.provider";

import { useDispatch, useSelector } from "react-redux";
import { getCartInfo } from "../../redux/reducer/cartReducer";
import {
  closeLoginModal,
  openInstantTopUpModal,
  openPaymentModal,
  openTimeModal,
} from "../../redux/reducer/modalReducer";
import {
  getCartId,
  getCartOwner,
  getDeviceId,
  getTableId,
  getTopUpPaymentList,
  getUserId,
  getVoucherPopup,
  setFullLoading,
} from "../../redux/reducer/commonReducer";

import apiService from "../../services/api.service";

export default function Login({ type, setLoginType }) {
  const toast = useToast();
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { store } = useParams();

  const { walletBalance } = useSelector((state) => state.home);
  const { merchantInfo } = useSelector((state) => state.merchant);
  const { isOpenLoginModal } = useSelector((state) => state.modal);
  const {
    paymentMethod,
    cartInfo,
    scheduleTime,
    scheduleDate,
    pickUpTime,
    deliveryTime,
  } = useSelector((state) => state.cart);
  const { merchantId, method, cartId } = useSelector((state) => state.common);

  const handleDirectCheckout = async (id) => {
    // if (paymentMethod.name !== "Pay At Counter") {
    dispatch(setFullLoading(true));
    // }

    try {
      const response = await apiService.createOrder({
        cart_id: id || cartId,
        tenant_channel_id: paymentMethod.tenant_id ?? "",
        provider_channel_id: paymentMethod.provider_id ?? "",
        promotion_id: "",
        merchant_id: merchantId,
        payment_method:
          paymentMethod.name === "Credit Balance"
            ? "credit"
            : paymentMethod.name === "Pay At Counter"
            ? "pay at counter"
            : "online payment",
        ...((method === "take away" || method === "delivery") && {
          selfpick_mode:
            scheduleTime.asap ||
            (!scheduleTime.time &&
              method === "delivery" &&
              deliveryTime?.length > 0 &&
              deliveryTime[0] === "ASAP") ||
            (!scheduleTime.time &&
              method === "take away" &&
              pickUpTime?.length > 0 &&
              pickUpTime[0] === "ASAP")
              ? "ASAP"
              : "scheduled",
          ...(((scheduleTime.time && scheduleTime.time !== "ASAP") ||
            (!scheduleTime.time &&
              method === "delivery" &&
              deliveryTime?.length > 0 &&
              deliveryTime[0] !== "ASAP") ||
            (!scheduleTime.time &&
              method === "take away" &&
              pickUpTime?.length > 0 &&
              pickUpTime[0] !== "ASAP")) && {
            scheduled_at: scheduleTime.time
              ? `${scheduleDate.full_date} ${scheduleTime["24hr_format"]}:00`
              : method === "delivery"
              ? deliveryTime[0]
              : method === "take away"
              ? pickUpTime[0]
              : "",
          }),
        }),
      });

      if (response) {
        localStorage.removeItem("cart_id");
        localStorage.removeItem("cart_owner");
        dispatch(getCartOwner());
        localStorage.setItem("voucher_popup", true);
        dispatch(getVoucherPopup());

        if (paymentMethod.name === "Pay At Counter") {
          toast.success(
            "Order placed successful. Please pay at the counter later."
          );
          navigate(`/${store}/order?order_id=${response.data}`);
          dispatch(setFullLoading(false));
        } else if (paymentMethod.name === "Credit Balance") {
          toast.success("Order placed successful.");
          navigate(`/${store}/order?order_id=${response.data}`);
          dispatch(setFullLoading(false));
        } else {
          if (response.data.url) {
            window.parent.location.replace(response.data.url);
          } else if (response.data) {
            toast.success("Order placed successful.");
            navigate(`/${store}/order?order_id=${response.data}`);
            dispatch(setFullLoading(false));
          } else {
            toast.error(
              "Oops! Something went wrong unexpectedly. Please try other payment methods."
            );
          }
        }
      }
    } catch (ex) {
      dispatch(setFullLoading(false));

      if (ex && Object.keys(ex).length > 0) {
        let errorMsg = [];
        if (ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              errorMsg = errors[item][0];
            });

            if (errorMsg === "Insufficient balance. ") {
              dispatch(
                getTopUpPaymentList({
                  merchant_id: merchantId,
                  amount: cartInfo.total_price - walletBalance,
                  allow_online_payment: merchantInfo.allow_online_payment,
                })
              )
                .unwrap()
                .then((res) => {
                  dispatch(openInstantTopUpModal());
                })
                .catch((ex) => {
                  if (ex && ex.response?.status === 422) {
                    const errors = ex.response.data.errors;
                    if (errors && Object.keys(errors).length > 0) {
                      Object.keys(errors).map((item, i) => {
                        toast.error(errors[item][0]);
                      });
                    }
                  }
                });
            } else if (
              errorMsg === "The given time is outside the delivery time slots."
            ) {
              if (cartInfo.payment_status === "pending") {
                toast.error(
                  "There seems to be an issue with your cart. Please clear your cart and place your order again."
                );
              } else {
                toast.error(
                  "Selected delivery time is no longer available. Please choose a new delivery time for your order."
                );
                dispatch(openTimeModal());
              }

              return;
            }

            toast.error(errorMsg);
          }
        }
      }
    }
  };

  const handleCloseLogin = () => {
    dispatch(closeLoginModal());
  };

  useEffect(() => {
    dispatch(getDeviceId());
    dispatch(getTableId());
    dispatch(getUserId());
  }, []);

  return (
    <FullModal
      show={isOpenLoginModal}
      backButton={handleCloseLogin}
      screenSize={"xl"}
      className="--backdrop"
      dialogClassName="--margin"
      contentClassName="--short"
      content={
        <MDBModalBody ref={modalRef}>
          <article
            className={`login-container ${
              type === "full" || /^full-/.test(type) ? "--full" : ""
            }`}
          >
            <header className="login-header">
              <Icon icon="maki:cross" color="transparent" />
              <article
                className={`login-frame ${
                  merchantInfo.company_logo.length > 0 ? "--store-logo" : ""
                }`}
              >
                {/* <h6 className="text-center">All rewards will bind with this number</h6> */}
                <LazyLoadImage
                  src={
                    merchantInfo.company_logo.length > 0
                      ? merchantInfo.company_logo[0].url
                      : FullLogo
                  }
                  alt="store-logo"
                />
              </article>
              <article className="pointer" onClick={() => handleCloseLogin()}>
                <Icon icon="maki:cross" />
              </article>
            </header>

            {type === "full" || /^full-/.test(type) ? (
              <FullLogin type={type} />
            ) : (
              <PreRegister
                type={type}
                handleDirectCheckout={handleDirectCheckout}
              />
            )}
            <section className="consent-section">
              <p>
                By logging in, you agree to us using your contact info for
                personalized promotions and order facilitation.
              </p>
            </section>
          </article>
        </MDBModalBody>
      }
    />
  );
}
