//lib
import { useFormikContext } from "formik";
import {
  MDBCol,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBRow,
} from "mdb-react-ui-kit";

export default function Dropdown({
  value,
  label,
  name,
  options,
  placeholder,
  className,
}) {
  const { values, errors, setFieldValue } = useFormikContext();

  return (
    <MDBCol className={`element _dropdown ${className ? className : ''}`}>
      {label && (
        <MDBRow>
          <label className="form-label">{label}</label>
        </MDBRow>
      )}
      <MDBDropdown>
        <MDBDropdownToggle type="button">
          {name ?
            value ? value : values[name]
            : placeholder
            ? placeholder
            : "- Please Select -"}
        </MDBDropdownToggle>
        <MDBDropdownMenu>
          {options &&
            options.map((option, index) => (
              <MDBDropdownItem
                key={index}
                onClick={() => setFieldValue(name, option.value)}
              >
                {option.label}
              </MDBDropdownItem>
            ))}
        </MDBDropdownMenu>
      </MDBDropdown>
      {errors && errors[name] ? (
        <div className="text-danger text-right no-padding error-message">
          {errors[name]}
        </div>
      ) : null}
    </MDBCol>
  );
}
