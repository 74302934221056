// //lib

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import useWindowDimensions from "../hook/use.window.dimensions";
// import { closeChangeCartModal } from "../../redux/reducer/modalReducer";
// import { clearCart, getCartCount, getCartInfo, setCarts, updateCart } from "../../redux/reducer/checkoutReducer";
// import scrollToTop from "../../helpers/scroll.to.top";
import FullModal from "../modal/full.modal.box";
import { MDBContainer, MDBModalBody, MDBRow } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Icon } from "@iconify/react";
import Button from "../element/button";
import {
  Avatar,
  Doubt,
  Maintenance,
  NoInternet,
  PromoOne,
  SlowNetwork,
  UpdateApp,
} from "../../assets/images";
import {
  closeConfirmModal,
  openMarketModal,
  openPaymentModal,
  openPromotionDetailsModal,
  openSetAddressModal,
} from "../../redux/reducer/modalReducer";
import {
  clearCart,
  getAllPayments,
  getCartCount,
  getCartInfo,
  setCartInfoLoading,
  setPaymentMethod,
  setPaymentStatus,
} from "../../redux/reducer/cartReducer";
import {
  getCartId,
  getCartOwner,
  getMethod,
  setConfirmItem,
} from "../../redux/reducer/commonReducer";
import apiService from "../../services/api.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getMenuList } from "../../redux/reducer/menuReducer";
import { useToast } from "./toast.provider";
import {
  buyPromotion,
  getMarketplaceList,
  getPointBalance,
  getPromotionCount,
  getWalletBalance,
  setIsBuyingPromo,
} from "../../redux/reducer/homeReducer";
import useWindowDimensions from "../hook/use.window.dimensions";
// import { Change } from "../../assets/images";
// import Button from "../element/button";
// import { useNavigate } from "react-router-dom";
// import { clearOrderList, getWalletBalance, getWalletHistory, setCallWalletHistory } from "../../redux/reducer/orderReducer";
// import { getAddressList } from "../../redux/reducer/storeReducer";
// import { setConfirmItem } from "../../redux/reducer/commonReducer";
// import { toast } from "react-toastify";
// import { Icon } from "@iconify/react";
// import apiServices from "../../services/api.services";

//assets

//reducers

//components

export default function Confirmation({ cart }) {
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const { store } = useParams();
  const { pathname } = useLocation();

  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const { cartInfoLoading, paymentListLoading, scheduleDate, scheduleTime } =
    useSelector((state) => state.cart);
  const { merchantInfo } = useSelector((state) => state.merchant);
  const {
    confirmItem,
    merchantId,
    cartId,
    method,
    addressId,
    userId,
    tableId,
    sessionNo,
  } = useSelector((state) => state.common);
  const { isOpenConfirmModal } = useSelector((state) => state.modal);

  const handleClearCart = () => {
    setConfirmationLoading(true);
    dispatch(
      clearCart({
        id: confirmItem?.item,
      })
    )
      .unwrap()
      .then((res) => {
        setConfirmationLoading(false);
        localStorage.removeItem("cart_id");
        localStorage.removeItem("cart_owner");

        dispatch(getCartId());
        dispatch(getCartOwner());
        dispatch(
          getCartInfo({
            merchant_id: merchantId,
            order_method: method,
          })
        )
          .unwrap()
          .then((res) => {
            dispatch(getCartId());
          });
        dispatch(
          getCartCount({
            order_method: method,
            merchant_id: merchantId,
          })
        );
        handleBackTo();
      })
      .catch((ex) => {
        setConfirmationLoading(false);

        if (ex && ex.response?.status === 404) {
          localStorage.removeItem("cart_id", "");
          localStorage.removeItem("cart_owner", "");
          dispatch(getCartId());
          dispatch(getCartOwner());

          handleBackTo();
          toast.error("Your cart has been outdated. Please refresh.");

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      });
  };

  const handleUpdateCart = async () => {
    setConfirmationLoading(true);

    try {
      const response = await apiService.updateCart({
        cart_id: confirmItem.cart_id,
        cart_item_id: confirmItem.cart_item_id,
        remark: confirmItem.remark,
        promotion_id: confirmItem.promotion_id,
        user_promotion_bag_id: confirmItem.user_promotion_bag_id,
        quantity: 0,
        do_not_use_any: null,
        option: null,
        ...((method === "take away" || method === "delivery") &&
          scheduleTime?.time && {
            selfpick_mode: scheduleTime?.asap ? "ASAP" : "scheduled",
            ...(!scheduleTime?.asap &&
              scheduleTime?.time !== "ASAP" && {
                scheduled_at: `${scheduleDate.full_date} ${scheduleTime["24hr_format"]}:00`,
              }),
          }),
      });

      if (response) {
        setConfirmationLoading(false);
        dispatch(
          getCartInfo({
            merchant_id: merchantId,
            order_method: method,
          })
        );
        handleBackTo();
      }
    } catch (ex) {
      setConfirmationLoading(false);
      if (ex && Object.keys(ex).length > 0) {
        let errorMsg = [];
        if (ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              errorMsg = errors[item][0];
              toast.error(errorMsg);

              if (
                errorMsg === "There is cart that still in payment processing. "
              ) {
                dispatch(
                  setConfirmItem({
                    type: "cart processing",
                    item: cartId,
                  })
                );
              }
            });
          }
        }

        if (ex.response?.status === 404) {
          handleBackTo();
          localStorage.removeItem("cart_id");
          dispatch(getCartId());
          toast.error("Your cart has been outdated. Please refresh.");

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    }
  };

  const handleUseVoucher = async () => {
    setConfirmationLoading(true);

    try {
      const response = await apiService.useVoucherNow({
        id: confirmItem.item,
        // type: confirmItem.item_type
      });

      if (response) {
        handleBackTo();
        navigate(`/${store}/order/all`);
        setConfirmationLoading(false);
        toast.success("Use voucher successful!");
      }
    } catch (ex) {
      handleBackTo();
      setConfirmationLoading(false);

      if (ex && ex.response?.status === 422) {
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => {
            if (
              errors[item][0] === "There is no active session for this user. "
            ) {
              toast.error("Please scan the table QR to use this voucher.");
            } else {
              toast.error(errors[item][0]);
            }
          });
        }
      }
    }
  };

  const handleSwitchMethod = () => {
    setConfirmationLoading(true);

    if (cartId) {
      dispatch(
        clearCart({
          id: cartId,
        })
      )
        .unwrap()
        .then((res) => {
          setConfirmationLoading(false);
          localStorage.removeItem("cart_id");
          localStorage.removeItem("cart_owner");

          dispatch(getCartId());
          dispatch(getCartOwner());

          if (pathname === `/${encodeURIComponent(store?.trim())}/cart`) {
            dispatch(
              getCartInfo({
                merchant_id: merchantId,
                order_method: method === "delivery" ? "take away" : "delivery",
              })
            );
          } else if (
            pathname === `/${encodeURIComponent(store?.trim())}/menu`
          ) {
            dispatch(
              getMenuList({
                merchant_id: merchantId,
                remark: "food",
                method: method === "delivery" ? "take away" : "delivery",
                table_id:
                  method === "dine in"
                    ? tableId
                      ? tableId === "null"
                        ? null
                        : tableId
                      : null
                    : null,
                session_no:
                  method === "dine in"
                    ? sessionNo
                      ? sessionNo === "null"
                        ? null
                        : sessionNo
                      : null
                    : null,
              })
            );
            dispatch(
              getCartInfo({
                merchant_id: merchantId,
                order_method: method === "delivery" ? "take away" : "delivery",
              })
            );
            dispatch(
              getCartCount({
                order_method: method === "delivery" ? "take away" : "delivery",
                merchant_id: merchantId,
              })
            );
          }

          if (method === "delivery") {
            localStorage.setItem("method", "take away");
          } else {
            localStorage.setItem("method", "delivery");

            if (!addressId) {
              dispatch(openSetAddressModal());
            }
          }

          dispatch(getMethod());
          handleBackTo();
        })
        .catch((ex) => {
          setConfirmationLoading(false);

          if (ex && ex.response?.status === 404) {
            localStorage.removeItem("cart_id", "");
            localStorage.removeItem("cart_owner", "");
            dispatch(getCartId());
            dispatch(getCartOwner());

            handleBackTo();
            toast.error("Your cart has been outdated. Please refresh.");

            setTimeout(() => {
              window.location.reload();
            }, 1000);

            // if (pathname === `/${encodeURIComponent(store?.trim())}/cart`) {
            //   dispatch(
            //     getCartInfo({
            //       merchant_id: merchantId,
            //       order_method: method === "delivery" ? "take away" : "delivery",
            //     })
            //   );
            // } else if (pathname === `/${encodeURIComponent(store?.trim())}/menu`) {
            //   dispatch(
            //     getMenuList({
            //       merchant_id: merchantId,
            //       remark: "food",
            //       method: method === "delivery" ? "take away" : "delivery",
            //       table_id: method === "dine in" ? tableId ? tableId === 'null' ? null : tableId : null : null,
            //       session_no: method === "dine in" ? sessionNo ? sessionNo === 'null' ? null : sessionNo : null : null,
            //     })
            //   );
            //   dispatch(
            //     getCartInfo({
            //       merchant_id: merchantId,
            //       order_method: method === "delivery" ? "take away" : "delivery",
            //     })
            //   );
            //   dispatch(
            //     getCartCount({
            //       order_method: method === "delivery" ? "take away" : "delivery",
            //       merchant_id: merchantId,
            //     })
            //   );
            // }
          }
        });
    }
  };

  const handleSelectPaymentMethod = () => {
    handleBackTo();
    dispatch(setPaymentMethod(confirmItem.item));
  };

  const handleLeaveMenu = () => {
    setConfirmationLoading(true);
    dispatch(
      clearCart({
        id: cartId,
      })
    )
      .unwrap()
      .then((res) => {
        setConfirmationLoading(false);
        localStorage.removeItem("cart_id");
        localStorage.removeItem("cart_owner");

        dispatch(getCartId());
        dispatch(getCartOwner());

        navigate(`/${store}`);
        handleBackTo();
      })
      .catch((ex) => {
        setConfirmationLoading(false);

        if (ex && ex.response?.status === 404) {
          localStorage.removeItem("cart_id", "");
          localStorage.removeItem("cart_owner", "");
          dispatch(getCartId());
          dispatch(getCartOwner());

          handleBackTo();
          toast.error("Your cart has been outdated. Please refresh.");

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      });
  };

  const handleBuyPromotion = async () => {
    setConfirmationLoading(true);

    dispatch(
      buyPromotion({
        id: confirmItem.item,
      })
    )
      .unwrap()
      .then((res) => {
        dispatch(
          getPointBalance({
            merchant_id: merchantId,
          })
        );
        dispatch(
          getMarketplaceList({
            columns: "costing",
            order: "asc",
            merchant_id: merchantId,
            applicable_type: "marketplace",
          })
        )
          .unwrap()
          .then((res) => {
            if (confirmItem.item_type === "free credit") {
              dispatch(
                getWalletBalance({
                  merchant_id: merchantId,
                })
              );
            }
            handleBackTo();
            dispatch(openMarketModal());
            dispatch(setIsBuyingPromo(false));
            setConfirmationLoading(false);
            toast.success(
              `Redeem voucher successful! ${
                confirmItem.item_type === "free credit"
                  ? "Credit will be added directly to your wallet."
                  : ""
              }`
            );
          });
        dispatch(
          getPromotionCount({
            merchant_id: merchantId,
          })
        );
      })
      .catch((ex) => {
        handleBackTo();
        setConfirmationLoading(false);
        dispatch(openPromotionDetailsModal());

        if (ex && ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              toast.error(errors[item][0]);
            });
          }
        }
      });
  };

  const handleBackTo = () => {
    setConfirmationLoading(false);
    dispatch(closeConfirmModal());
    dispatch(setConfirmItem({}));
    dispatch(setPaymentStatus(null));
  };

  return (
    <FullModal
      centered
      scrollable
      staticBackdrop={true}
      show={true}
      backButton={
        confirmItem?.type !== "update_version" &&
        confirmItem?.type !== "under_maintenance"
          ? handleBackTo
          : undefined
      }
      screenSize={"xl"}
      dialogClassName="--margin"
      className={
        confirmItem?.type === "update_version" ||
        confirmItem?.type === "under_maintenance"
          ? "--update-version"
          : "--backdrop"
      }
      contentClassName="--short --white"
      content={
        <>
          <MDBModalBody ref={modalRef}>
            <MDBContainer
              className={`confirmation-modal ${
                confirmItem?.type === "update_version" ||
                confirmItem?.type === "under_maintenance"
                  ? "p-0 pb-4"
                  : ""
              }`}
            >
              <MDBRow className="flex justify-center">
                <div
                  className={`confirm-frame ${
                    confirmItem?.type === "update_version" ||
                    confirmItem?.type === "under_maintenance"
                      ? "--update-version"
                      : confirmItem?.type === "slow network"
                      ? "--slow-network"
                      : ""
                  }`}
                >
                  <img
                    src={
                      confirmItem?.type === "update_version"
                        ? UpdateApp
                        : confirmItem?.type === "under_maintenance"
                        ? Maintenance
                        : confirmItem?.type === "slow network"
                        ? NoInternet
                        : Doubt
                    } // || picture
                    alt="..."
                  />
                </div>
              </MDBRow>
              <MDBRow
                className={`p-5 pt-0 ${
                  confirmItem?.type === "under_maintenance" ? "pb-4" : "pb-0"
                }`}
              >
                <div
                  className={`_label ${
                    confirmItem?.type === "update_version" ||
                    confirmItem?.type === "under_maintenance"
                      ? "--update-version"
                      : confirmItem?.type !== "buy promotion" &&
                        confirmItem?.type !== "use now" &&
                        confirmItem?.type !== "slow network"
                      ? "--proceed-change"
                      : ""
                  } text-center text-capitalize ${
                    confirmItem?.type === "slow network" ? "mt-1" : "mt-4"
                  }`}
                >
                  {confirmItem?.type === "clear cart"
                    ? "Clear Cart Confirmation"
                    : confirmItem?.type === "remove cart"
                    ? "Remove Cart Item Confirmation"
                    : confirmItem?.type === "use now"
                    ? "Use Voucher Confirmation"
                    : confirmItem?.type === "update_version"
                    ? "New Version Available"
                    : confirmItem?.type === "under_maintenance"
                    ? "We'll Be Back Soon!"
                    : confirmItem?.type === "slow network"
                    ? "Wake up your connection!"
                    : confirmItem?.type === "switch method"
                    ? `Switch To ${
                        method === "delivery" ? "self pick" : "delivery"
                      }`
                    : confirmItem?.type === "leaving menu"
                    ? "Leave Menu Confirmation"
                    : confirmItem?.type === "buy promotion"
                    ? "Redeem Voucher Confirmation"
                    : "Payment Processing"}
                  {confirmItem?.type === "update_version" && (
                    <span> {process.env.REACT_APP_VERSION}</span>
                  )}
                </div>

                {confirmItem?.type === "clear cart" ||
                confirmItem?.type === "leaving menu" ||
                confirmItem?.type === "switch method" ? (
                  <div className="_label --proceed-change-desc text-center mt-4">
                    This action will clear the items in your current order cart.
                    <br />
                    Do you want to proceed?
                  </div>
                ) : confirmItem?.type === "remove cart" ? (
                  <div className="_label --proceed-change-desc text-center mt-4">
                    Are you sure you want to delete this cart item?
                  </div>
                ) : confirmItem?.type === "use now" ? (
                  <div className="_label --proceed-change-desc text-center mt-4">
                    Are you sure you want to use this voucher now?
                  </div>
                ) : confirmItem?.type === "update_version" ? (
                  <div className="_label --update-version-desc text-center mt-4">
                    We have added new features and fix some bugs to make your
                    experience seamless.
                  </div>
                ) : confirmItem?.type === "under_maintenance" ? (
                  <div className="_label --update-version-desc text-center mt-4">
                    We apologize for the inconvenience. Our site is currently
                    undergoing scheduled maintenance and upgrades. We will be
                    back shortly.
                    <br />
                    Thank you for your patience.
                  </div>
                ) : confirmItem?.type === "slow network" ? (
                  <div className="_label --update-version-desc text-center mt-4">
                    Your internet seems too slow to reach our server.
                  </div>
                ) : confirmItem?.type === "pay counter" ? (
                  <div className="_label --proceed-change-desc text-center mt-4">
                    Are you sure you want to use this payment method? <br />
                    <p>
                      *Warning: Selecting PAY AT COUNTER will exclude spin wheel
                      and check-in benefits.
                    </p>
                  </div>
                ) : confirmItem?.type === "buy promotion" ? (
                  <div className="_label --proceed-change-desc text-center mt-4">
                    Are you sure you want to use{" "}
                    {parseFloat(confirmItem.costing).toFixed(2)} points to
                    redeem this voucher?
                    <br />
                  </div>
                ) : (
                  <div className="_label --proceed-change-desc text-center mt-4">
                    Your cart payment is pending. Are you sure you want to
                    proceed?
                  </div>
                )}
              </MDBRow>
              {confirmItem?.type !== "under_maintenance" && (
                <div className="confirm-summary">
                  {confirmItem?.type !== "update_version" &&
                    confirmItem?.type !== "slow network" && (
                      <Button
                        disabled={confirmationLoading || paymentListLoading}
                        btnClassName={`confirm-button ${
                          confirmItem?.type !== "use now" &&
                          confirmItem?.type !== "buy promotion"
                            ? "--delete"
                            : "--not-delete"
                        }`}
                        onClick={() =>
                          confirmItem?.type === "clear cart" ||
                          confirmItem?.type === "cart processing"
                            ? handleClearCart()
                            : confirmItem?.type === "remove cart"
                            ? handleUpdateCart()
                            : confirmItem?.type === "use now"
                            ? handleUseVoucher()
                            : confirmItem?.type === "leaving menu"
                            ? handleLeaveMenu()
                            : confirmItem?.type === "pay counter"
                            ? handleSelectPaymentMethod()
                            : confirmItem?.type === "buy promotion"
                            ? handleBuyPromotion()
                            : handleSwitchMethod()
                        }
                      >
                        {confirmItem?.type === "cart processing"
                          ? "Clear Cart"
                          : "Yes"}
                        {confirmationLoading && (
                          <Icon
                            icon="line-md:loading-twotone-loop"
                            color=""
                            className="loading-icon --smaller ms-3 mb-1"
                          />
                        )}
                      </Button>
                    )}
                  <Button
                    disabled={confirmationLoading || paymentListLoading}
                    btnClassName={
                      confirmItem?.type === "update_version"
                        ? "update-button"
                        : "confirm-button"
                    }
                    variant="solid"
                    // {confirmItem !== null && !confirmItem?.type ? "solid" : "outline"}
                    className="mt-3"
                    onClick={() => {
                      if (confirmItem?.type === "cart processing") {
                        navigate(`/${encodeURIComponent(store?.trim())}/cart`);

                        if (confirmItem.total > 0) {
                          dispatch(
                            getWalletBalance({
                              merchant_id: merchantId,
                            })
                          );
                          dispatch(
                            getAllPayments({
                              cart_id: confirmItem.item,
                              amount: confirmItem.total,
                              type: "cart",
                              merchant_id: merchantId,
                              allow_online_payment:
                                merchantInfo.allow_online_payment,
                            })
                          )
                            .unwrap()
                            .then((res) => {
                              handleBackTo();
                              dispatch(setPaymentStatus("direct-pay"));
                              dispatch(openPaymentModal());
                            })
                            .catch((ex) => {
                              handleBackTo();

                              if (ex.response?.status === 422) {
                                const errors = ex.response.data.errors;
                                if (errors && Object.keys(errors).length > 0) {
                                  Object.keys(errors).map((item, i) => {
                                    if (item === "cart_id") {
                                      toast.error(
                                        "Your cart has been outdated. Please refresh."
                                      );

                                      setTimeout(() => {
                                        window.location.reload();
                                      }, 1000);
                                    } else {
                                      toast.error(errors[item][0]);
                                    }
                                  });
                                }
                              }
                            });
                        }

                        return;
                      }

                      if (confirmItem?.type === "pay counter") {
                        dispatch(openPaymentModal());
                      }

                      if (confirmItem?.type === "buy promotion") {
                        dispatch(openPromotionDetailsModal());
                      }

                      if (
                        confirmItem?.type === "update_version" ||
                        confirmItem?.type === "slow network"
                      ) {
                        setConfirmationLoading(true);
                        window.location.reload();

                        return;
                      }

                      handleBackTo();
                    }}
                  >
                    {confirmItem?.type === "cart processing"
                      ? "Proceed Payment"
                      : confirmItem?.type === "update_version"
                      ? "Update Now"
                      : confirmItem?.type === "slow network"
                      ? "Try Again"
                      : "No"}
                    {paymentListLoading && (
                      <Icon
                        icon="line-md:loading-twotone-loop"
                        color=""
                        className="loading-icon --smaller ms-3 mb-1"
                      />
                    )}
                  </Button>
                </div>
              )}
            </MDBContainer>
          </MDBModalBody>
        </>
      }
    />
  );
}
