import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DefaultAvatar } from "../../../assets/images";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { MDBModalBody } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

import scrollToTop from "../../../helpers/scroll.to.top";
import { useToast } from "../../../components/common/toast.provider";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import { closeProfileModal } from "../../../redux/reducer/modalReducer";

import Input from "../../../components/element/input";
import Button from "../../../components/element/button";
import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";

import apiService from "../../../services/api.service";
import { getPromotionList } from "../../../redux/reducer/homeReducer";

export default function Profile() {
  const toast = useToast();
  const modalRef = useRef(null);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const [dropGender, setDropGender] = useState(false);

  const [updatingProfile, setUpdatingProfile] = useState(false);

  const { avatarIcon } = useSelector((state) => state.merchant);
  const { merchantId } = useSelector((state) => state.common);
  const { isOpenProfileModal } = useSelector((state) => state.modal);
  const { genderOptions, profileInfo } = useSelector((state) => state.home);

  const formatDate = (value, format) => {
    if (format === "yyyy-mm-dd") {
      const [day, month, year] = value?.trim().split("/");
      return `${year}-${month}-${day}`;
    } else if (format === "dd-mm-yyyy") {
      const [year, month, day] = value?.trim().split("-");
      return `${day}-${month}-${year}`;
    } else {
      const [day, month, year] = value?.trim().split("/");
      return `${day}/${month}/${year}`;
    }
  };

  const returnFormattedBirthday = profileInfo.dob
    ? formatDate(profileInfo.dob, "dd/mm/yyyy")
    : "";

  const profileForm = {
    name: profileInfo.name ?? "",
    email: profileInfo.email ?? "",
    birthday: returnFormattedBirthday ? returnFormattedBirthday : "",
    gender: profileInfo.gender ?? "",
  };

  const profileSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    birthday: Yup.string().required("Birthday month is required"),
    gender: Yup.string().required("Gender is required"),
  });

  const handleSubmit = async ({ values, setFieldError }) => {
    setUpdatingProfile(true);
    const formattedBirthday = formatDate(values.birthday, "dd/mm/yyyy");

    try {
      const response = await apiService.updateProfile({
        name: values.name,
        email: values.email,
        date_of_birth: formattedBirthday,
        gender: values.gender,
      });

      if (response) {
        setUpdatingProfile(false);
        dispatch(
          getPromotionList({
            merchant_id: merchantId,
          })
        );
        handleCloseProfile();
        toast.success("Profile saved successful.");
      }
    } catch (ex) {
      setUpdatingProfile(false);
      if (ex && ex.response?.status === 422) {
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => {
            setFieldError(item, errors[item]);
          });
        }
      }
    }
  };

  const handleCloseProfile = () => {
    dispatch(closeProfileModal());
  };

  const handleChooseGender = (value, { setFieldValue }) => {
    setFieldValue("gender", value);
    setDropGender(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropGender(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropGender]);

  useEffect(() => {
    if (modalRef.current) {
      scrollToTop(modalRef.current);
    }
  }, [isOpenProfileModal]);

  return (
    <FullModal
      show={isOpenProfileModal}
      backButton={handleCloseProfile}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      contentClassName="--white"
      content={
        <>
          <ModalHeader
            title="My Profile"
            backTo={handleCloseProfile}
            backToNoAnimation={handleCloseProfile}
            type="model2"
          />
          <Formik
            initialValues={profileForm}
            validationSchema={profileSchema}
            enableReinitialize={true}
            onSubmit={(values, { errors, setFieldError }) => {
              handleSubmit({ values, errors, setFieldError });
            }}
          >
            {({ setFieldValue, errors }) => (
              <Form>
                <MDBModalBody ref={modalRef} className="fixed-body">
                  <article className="profile-modal">
                    <section className="curve-bg --short"></section>
                    <section className="rank-section">
                      <article className="avatar-frame mb-1">
                        <LazyLoadImage src={avatarIcon || DefaultAvatar} />
                      </article>
                    </section>
                    <section className="body-section">
                      <p className="modal-desc">
                        Complete your profile for personalized services and
                        exclusive offers! {width > 991 && <br />} Don't miss out
                        on birthday promotions and special notifications.
                        {/* Some profile details have a limited edit allowance. Please ensure accuracy as frequent changes may be limited. Your profile helps us tailor our services to your preferences, so let's keep it updated! */}
                        {/* {userRank.rank_description} */}
                      </p>
                      <article className="p-2 mt-4">
                        <Input
                          name="name"
                          placeholder="Full Name"
                          isRequired={true}
                        />
                        <Input
                          name="email"
                          placeholder="Email"
                          isRequired={true}
                        />
                        <Input
                          name="birthday"
                          placeholder="Birthday Month"
                          type="date"
                          inputClassName="--date"
                          isRequired={true}
                          values={
                            profileInfo.dob ? returnFormattedBirthday : ""
                          }
                        />
                        <section className="relative">
                          <Input
                            name="gender"
                            placeholder="Gender"
                            isRequired={true}
                            onClick={() => setDropGender(!dropGender)}
                            readOnly
                          />
                          <article
                            ref={dropdownRef}
                            className={`dropdown-container --gender ${
                              !dropGender ? "hide" : ""
                            }`}
                          >
                            {genderOptions?.map((option, optionIndex) => (
                              <section
                                className="row pointer"
                                onClick={() =>
                                  handleChooseGender(option.value, {
                                    setFieldValue,
                                  })
                                }
                                key={optionIndex}
                              >
                                <label className="pointer">
                                  {option.label}
                                </label>
                              </section>
                            ))}
                          </article>
                        </section>
                      </article>
                    </section>
                  </article>
                </MDBModalBody>
                <article className="model2-summary --must-fixed --fixed">
                  <Button
                    type="submit"
                    className="w-100"
                    disabled={updatingProfile}
                  >
                    Complete & Save
                  </Button>
                </article>
              </Form>
            )}
          </Formik>
        </>
      }
    />
  );
}
