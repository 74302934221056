import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useToast } from "../../components/common/toast.provider";

import { useDispatch, useSelector } from "react-redux";
import { getCartCount, getCartInfo } from "../../redux/reducer/cartReducer";
import { getMenuList, getRecommendMenu } from "../../redux/reducer/menuReducer";
import {
  getGuest,
  getTableId,
  getDeviceId,
  getAddressId,
  getSessionNo,
  getUserId,
  getCartId,
  getCartOwner,
} from "../../redux/reducer/commonReducer";

import Login from "../Login";
import PlaceOrder from "./modals/place.order";
import MenuHeader from "./components/menu.header";
import MenuListing from "./components/menu.listing";
import MenuFooter from "./components/menu.footer";
import Confirmation from "../../components/common/confirmation";
import scrollToTop from "../../helpers/scroll.to.top";

export default function Menu() {
  const menuRef = useRef(null);
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { store } = useParams();

  const { cartCount, cartInfoLoading } = useSelector((state) => state.cart);
  const { merchantId, tableId, sessionNo, method } = useSelector(
    (state) => state.common
  );
  const { isOpenLoginModal, isOpenConfirmModal, isOpenPlaceOrderModal } =
    useSelector((state) => state.modal);

  const [loginType, setLoginType] = useState("");

  useEffect(() => {
    dispatch(getCartOwner());
    dispatch(getCartId());
    dispatch(getUserId());
    dispatch(getGuest());
    dispatch(getDeviceId());
    dispatch(getAddressId());
    dispatch(getTableId());
    dispatch(getSessionNo());
  }, []);

  useEffect(() => {
    if (merchantId) {
      dispatch(
        getCartCount({
          order_method: method,
          merchant_id: merchantId,
        })
      );
    }
  }, [merchantId]);

  useEffect(() => {
    if (merchantId) {
      dispatch(
        getCartInfo({
          merchant_id: merchantId,
          order_method: method,
        })
      );
      dispatch(
        getRecommendMenu({
          type: "merchant recommendation",
          merchant_id: merchantId,
          method: method,
        })
      );
      dispatch(
        getMenuList({
          merchant_id: merchantId,
          remark: "food",
          method: method,
          table_id:
            method === "dine in"
              ? tableId
                ? tableId === "null"
                  ? null
                  : tableId
                : null
              : null,
          session_no:
            method === "dine in"
              ? sessionNo
                ? sessionNo === "null"
                  ? null
                  : sessionNo
                : null
              : null,
        })
      )
        .unwrap()
        .catch((ex) => {
          if (ex && ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                if (item === "table_id" || item === "session_no") {
                  navigate(`/${store}`);
                  localStorage.removeItem("table_id");
                  localStorage.removeItem("session_no");

                  dispatch(getTableId());
                  dispatch(getSessionNo());
                }

                toast.error(errors[item][0]);
              });
            }
          }
        });
    }
  }, [merchantId]);

  // for mobile chrome
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    };

    setTimeout(scrollToTop, 100);

    const handlePopState = () => {
      setTimeout(scrollToTop, 100);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <>
      <section className="menu-container">
        <MenuHeader setLoginType={setLoginType} />
        <MenuListing menuRef={menuRef} />
        {cartCount > 0 && !cartInfoLoading && <MenuFooter />}
      </section>
      {isOpenConfirmModal && <Confirmation />}
      {isOpenPlaceOrderModal && <PlaceOrder />}
      {isOpenLoginModal && <Login type={loginType} />}
    </>
  );
}
