import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Sample } from "../../../assets/images";

import { Icon } from "@iconify/react";
import { MDBModalBody } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

import scrollToTop from "../../../helpers/scroll.to.top";
import { useToast } from "../../../components/common/toast.provider";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import {
  closePaymentModal,
  openInstantTopUpModal,
  openLoginModal,
  openPaymentModal,
  openTimeModal,
} from "../../../redux/reducer/modalReducer";
import {
  getCartId,
  setFullLoading,
  getTopUpPaymentList,
  getCartOwner,
  getVoucherPopup,
} from "../../../redux/reducer/commonReducer";
import {
  getCartInfo,
  setPaymentMethod,
  setPaymentStatus,
} from "../../../redux/reducer/cartReducer";

import HalfModal from "../../../components/modal/half.modal.box";
import ModalHeader from "../../../components/header/modal.header";

import apiService from "../../../services/api.service";

export default function SelectPayment({ setLoginType }) {
  const toast = useToast();
  const { store } = useParams();
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { realWidth, width } = useWindowDimensions();

  const { walletBalance } = useSelector((state) => state.home);
  const { merchantInfo } = useSelector((state) => state.merchant);
  const { isOpenPaymentModal } = useSelector((state) => state.modal);
  const { login, merchantId, cartId, method } = useSelector(
    (state) => state.common
  );
  const {
    paymentList,
    paymentMethod,
    cartInfo,
    paymentStatus,
    scheduleTime,
    scheduleDate,
    pickUpTime,
    deliveryTime,
  } = useSelector((state) => state.cart);

  const [openPaymentItem, setOpenPaymentItem] = useState([]);

  const handleClosePaymentModal = () => {
    dispatch(setPaymentStatus(null));
    dispatch(closePaymentModal());
  };

  const handleSelectPaymentMethod = async (value) => {
    if (value.name === "Credit Balance" && login !== "full") {
      dispatch(closePaymentModal());
      dispatch(openLoginModal());
      if (paymentStatus === "direct-pay") {
        setLoginType("full-credit-pay");
      } else {
        setLoginType("full-credit");
      }
      dispatch(setPaymentStatus(null));

      return;
    }

    if (paymentStatus === "direct-pay") {
      localStorage.setItem("voucher_popup", true);
      handleClosePaymentModal();
      dispatch(setFullLoading(true));

      try {
        const response = await apiService.createOrder({
          cart_id: cartId,
          tenant_channel_id: paymentMethod.tenant_id ?? "",
          tenant_channel_id: value.tenant_id ?? "",
          provider_channel_id: value.provider_id ?? "",
          promotion_id: "",
          merchant_id: merchantId,
          payment_method:
            value.name === "Credit Balance"
              ? "credit"
              : value.name === "Pay At Counter"
              ? "pay at counter"
              : "online payment",
          ...((method === "take away" || method === "delivery") && {
            selfpick_mode:
              scheduleTime.asap ||
              (!scheduleTime.time &&
                method === "delivery" &&
                deliveryTime?.length > 0 &&
                deliveryTime[0] === "ASAP") ||
              (!scheduleTime.time &&
                method === "take away" &&
                pickUpTime?.length > 0 &&
                pickUpTime[0] === "ASAP")
                ? "ASAP"
                : "scheduled",
            ...(((scheduleTime.time && scheduleTime.time !== "ASAP") ||
              (!scheduleTime.time &&
                method === "delivery" &&
                deliveryTime?.length > 0 &&
                deliveryTime[0] !== "ASAP") ||
              (!scheduleTime.time &&
                method === "take away" &&
                pickUpTime?.length > 0 &&
                pickUpTime[0] !== "ASAP")) && {
              scheduled_at: scheduleTime.time
                ? `${scheduleDate.full_date} ${scheduleTime["24hr_format"]}:00`
                : method === "delivery"
                ? deliveryTime[0]
                : method === "take away"
                ? pickUpTime[0]
                : "",
            }),
          }),
        });

        if (response) {
          localStorage.removeItem("cart_id");
          localStorage.removeItem("cart_owner");
          dispatch(getCartOwner());

          if (value.name === "Pay At Counter") {
            toast.success(
              "Order placed successful. Please pay at the counter later."
            );
            navigate(`/${store}/order?order_id=${response.data}`);
            dispatch(setFullLoading(false));
          } else if (value.name === "Credit Balance") {
            toast.success("Order placed successful.");
            navigate(`/${store}/order?order_id=${response.data}`);
            dispatch(setFullLoading(false));
          } else {
            if (response.data.url) {
              window.parent.location.replace(response.data.url);
            } else if (response.data) {
              toast.success("Order placed successful.");
              navigate(`/${store}/order?order_id=${response.data}`);
              dispatch(setFullLoading(false));
            } else {
              toast.error(
                "Oops! Something went wrong unexpectedly. Please try other payment methods."
              );
            }
          }
        }
      } catch (ex) {
        localStorage.removeItem("voucher_popup");
        dispatch(setPaymentStatus(null));
        dispatch(setFullLoading(false));

        if (ex && Object.keys(ex).length > 0) {
          let errorMsg = [];
          if (ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                errorMsg = errors[item][0];
              });

              if (errorMsg === "Insufficient balance. ") {
                dispatch(
                  getTopUpPaymentList({
                    merchant_id: merchantId,
                    amount: cartInfo.total_price - walletBalance,
                    allow_online_payment: merchantInfo.allow_online_payment,
                  })
                )
                  .unwrap()
                  .then((res) => {
                    dispatch(openInstantTopUpModal());
                  })
                  .catch((ex) => {
                    if (ex && ex.response?.status === 422) {
                      const errors = ex.response.data.errors;
                      if (errors && Object.keys(errors).length > 0) {
                        Object.keys(errors).map((item, i) => {
                          toast.error(errors[item][0]);
                        });
                      }
                    }
                  });
              } else if (
                errorMsg ===
                "The given time is outside the delivery time slots."
              ) {
                if (cartInfo.payment_status === "pending") {
                  toast.error(
                    "There seems to be an issue with your cart. Please clear your cart and place your order again."
                  );
                } else {
                  if (method === "delivery") {
                    toast.error(
                      "Selected delivery time is no longer available. Please choose a new delivery time for your order."
                    );
                  } else {
                    toast.error(
                      "Selected pick up time is no longer available. Please choose a new self pick time for your order."
                    );
                  }

                  dispatch(openTimeModal());
                }

                return;
              }

              toast.error(errorMsg);
            }
          }
        }
      }
    } else {
      dispatch(setPaymentMethod(value));
      handleClosePaymentModal();
    }
  };

  const handleShowPaymentItem = (type) => {
    if (openPaymentItem.includes(type)) {
      setOpenPaymentItem(openPaymentItem.filter((id) => id !== type));
    } else {
      setOpenPaymentItem([...openPaymentItem, type]);
    }
  };

  useEffect(() => {
    if (modalRef.current) {
      scrollToTop(modalRef.current);
    }
  }, [isOpenPaymentModal]);

  return (
    <>
      <HalfModal
        type={realWidth >= 450 ? "mobile" : ""}
        show={isOpenPaymentModal}
        backButton={handleClosePaymentModal}
        desktopModal={width >= 991 ? true : false}
        screenSize={width >= 991 ? "xl" : ""}
        content={
          <>
            <ModalHeader
              title="Select Payment Method"
              backTo={handleClosePaymentModal}
              backToNoAnimation={handleClosePaymentModal}
              type={`model2 ${realWidth < 450 ? "half" : ""}`}
            />
            <MDBModalBody ref={modalRef}>
              <article className="select-payment-modal">
                {Object.entries(paymentList).map(
                  ([paymentType, paymentMethods]) => {
                    if (Array.isArray(paymentMethods)) {
                      return (
                        <section key={paymentType}>
                          <article
                            className={`payment-method 
                            ${
                              paymentType === "Online Banking" &&
                              cartInfo.total_price < 1
                                ? "pb-2"
                                : ""
                            }
                            ${
                              openPaymentItem.includes(paymentType)
                                ? "--withoutBorder"
                                : ""
                            }`}
                            onClick={() => handleShowPaymentItem(paymentType)}
                          >
                            <article className="flex items-center gap-4">
                              <Icon
                                icon="bi:credit-card-2-back"
                                className="icon --purple smaller"
                              />
                              <article>
                                <h4>{paymentType}</h4>
                                {paymentType === "Online Banking" &&
                                  cartInfo.total_price < 1 && (
                                    <p>
                                      Order amount must be above RM 1 only can
                                      choose this method
                                    </p>
                                  )}
                              </article>
                            </article>
                            <article>
                              <Icon
                                icon={
                                  openPaymentItem.includes(paymentType)
                                    ? "ion:chevron-down"
                                    : "ion:chevron-right"
                                }
                                className={`icon ${
                                  openPaymentItem.includes(paymentType)
                                    ? ""
                                    : "smaller"
                                }`}
                              />
                            </article>
                          </article>
                          {openPaymentItem.includes(paymentType) && (
                            <article className="p-4 pt-0 pb-0">
                              {paymentMethods.map((method) => (
                                <section
                                  key={method.tenant_channel_id}
                                  className="payment-method --withoutBorder"
                                  onClick={() =>
                                    handleSelectPaymentMethod({
                                      name: `${paymentType} - ${method.name}`,
                                      tenant_id: method.tenant_channel_id,
                                      provider_id: method.provider_channel_id,
                                    })
                                  }
                                >
                                  <article className="flex items-center gap-4">
                                    <article className="bank-frame">
                                      <LazyLoadImage
                                        src={method.image_url || Sample}
                                        alt={method.name}
                                      />
                                    </article>
                                    <h4>{method.display_name}</h4>
                                  </article>
                                </section>
                              ))}
                            </article>
                          )}
                        </section>
                      );
                    } else {
                      return (
                        <section
                          key={paymentType}
                          className="payment-method"
                          onClick={() =>
                            handleSelectPaymentMethod({
                              name: paymentType,
                              tenant_id: null,
                              provider_id: null,
                            })
                          }
                        >
                          <article className="flex items-center gap-4">
                            <Icon
                              icon={
                                paymentType === "Credit Balance"
                                  ? "icon-park-outline:vip-one"
                                  : "fluent-mdl2:money"
                              }
                              className="icon --purple"
                            />
                            <h4>
                              {" "}
                              {paymentType === "Credit Balance"
                                ? `${paymentType} ${
                                    login === "full"
                                      ? `- RM ${
                                          walletBalance > 0
                                            ? parseFloat(walletBalance).toFixed(
                                                2
                                              )
                                            : "0.00"
                                        }`
                                      : ""
                                  }`
                                : paymentType}
                            </h4>
                          </article>
                          <Icon
                            icon={
                              paymentMethod?.name === paymentType
                                ? "ic:twotone-circle"
                                : "line-md:circle"
                            }
                            className={`icon ${
                              paymentMethod?.name === paymentType
                                ? "--primary"
                                : ""
                            }`}
                          />
                        </section>
                      );
                    }
                  }
                )}
              </article>
            </MDBModalBody>
          </>
        }
      />
    </>
  );
}
