import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Icon } from "@iconify/react";
import { Skeleton } from "primereact/skeleton";
import { Swiper, SwiperSlide } from "swiper/react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function OtherOutlet() {
  const navigate = useNavigate()
  const { supermerchantList, supermerchantListLoading } = useSelector((state) => state.common);

  const handleSelectOther = (merchant) => {
    navigate(`/${merchant}`)
  } 

  return (
    <section className="other-outlet"> 
    {!supermerchantListLoading && supermerchantList?.length > 0 ? 
        <>
            <article className="relative" style={{ height: '2em' }}>
                <hr />
                <h6 className="title">Other Outlets</h6>
            </article>
            <Swiper
                slidesPerView={1.1}
                grabCursor={true}
                spaceBetween={13}
            >
                {supermerchantList.map((merchant, merchantIndex) => (
                    <SwiperSlide className="merchant-info --branch pointer" key={merchantIndex} onClick={() => handleSelectOther(merchant.display_name)}>
                        <article className="store-frame">
                            <LazyLoadImage src={merchant.logo_attachment} />
                        </article>
                        <article>
                            <h4 className="one-line me-3 mb-1">{merchant.store_name}</h4>
                            <h6 className={`tag mb-1 ${merchant.remark === 'closed' ? '--closed' : '' }`}>{merchant.remark === 'available' ? 'Open' : 'Closed'}</h6>
                            <p className="mb-0 two-line">{merchant.description}</p>
                            <article className="rating mt-1">
                                <Icon icon="line-md:map-marker-filled" />
                                <p className="text-capitalize">{merchant.state}</p>
                            </article>
                        </article>
                    </SwiperSlide>
                ))} 
            </Swiper>
        </>
      : supermerchantListLoading ?
            <Swiper
                slidesPerView={1.1}
                grabCursor={true}
                spaceBetween={13}
            >
                {Array.from({ length: 5 }, (_, merchantSkeletonIndex) => (
                <SwiperSlide key={merchantSkeletonIndex}>
                    <Skeleton 
                        height="120px"
                        className="merchant-info"
                    />
                </SwiperSlide>
                ))}
            </Swiper>
        : ''
    }
    </section>
  );
}
