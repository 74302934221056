import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api.service";

export const getCartInfo = createAsyncThunk(
  "getCartInfo",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getCartInfo(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCartCount = createAsyncThunk(
  "getCartCount",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getCartCount(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const clearCart = createAsyncThunk(
  "clearCart",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.clearCart(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllPayments = createAsyncThunk(
  "getAllPayments",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getAllPayments(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

let currentAbortController = null;

export const getMyPromotion = createAsyncThunk(
  "getMyPromotion",
  async (payload, thunkAPI) => {
    try {
      if (currentAbortController && !currentAbortController.signal.aborted) {
        currentAbortController.abort();
      }

      currentAbortController = new AbortController();
      const signal = currentAbortController.signal;

      const response = await apiService.getMyPromotion(payload, { signal });

      currentAbortController = null;

      return response;
    } catch (error) {
      if (error.name === "AbortError") {
      } else {
        currentAbortController = null;
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getAddOnMenu = createAsyncThunk(
  "getAddOnMenu",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getRecommendMenu(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getDeliveryTime = createAsyncThunk(
  "getDeliveryTime",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getDeliveryTime(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getPickUpTime = createAsyncThunk(
  "getPickUpTime",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getPickUpTime(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const initialData = {
  cartInfo: {},
  cartInfoLoading: true,
  cartCountLoading: false,
  cartCount: 0,

  paymentList: [],
  paymentListLoading: false,
  paymentMethod: "",

  scheduleDate: "",
  scheduleTime: "",

  editCartData: [],
  myPromotion: [],
  usedPromotion: [],
  addOnMenu: [],
  updateCartId: null,
  myPromotionLoading: false,
  paymentStatus: null,

  deliveryTime: [],
  deliveryTimeLoading: false,
  pickUpTime: [],
  pickUpTimeLoading: false,

  hasMore: false,
  length: 14,
  start: 0,
  usedData: [],
  usedLoading: false,
  availableData: [],
  availableLoading: false,
};

const dataSlice = createSlice({
  name: "cart",
  initialState: initialData,
  reducers: {
    handleChangeQuantity: (state, action) => {
      if (action.payload.type == "increases") {
        for (let i = 0; i < state.listing.length; i++) {
          if (i == action.payload.index) {
            void (state.listing[action.payload.index] = {
              ...state.listing[action.payload.index],
              quantity: state.listing[action.payload.index].quantity + 1,
            });
          }
        }
      } else if (action.payload.type == "decreases") {
        for (let i = 0; i < state.listing.length; i++) {
          if (i == action.payload.index) {
            void (state.listing[action.payload.index] = {
              ...state.listing[action.payload.index],
              quantity: state.listing[action.payload.index].quantity - 1,
            });
          }
        }
      }
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    setScheduleDate: (state, action) => {
      state.scheduleDate = action.payload;
    },
    setScheduleTime: (state, action) => {
      state.scheduleTime = action.payload;
    },
    setEditCartData: (state, action) => {
      state.editCartData = action.payload;
    },
    setCartInfoLoading: (state, action) => {
      state.cartInfoLoading = action.payload;
    },
    setUpdateCartId: (state, action) => {
      state.updateCartId = action.payload;
    },
    setPaymentStatus: (state, action) => {
      state.paymentStatus = action.payload;
    },
    setHasMore: (state, action) => {
      state.hasMore = action.payload;
    },
    setStart: (state, action) => {
      state.start = action.payload;
    },
    setUsedData: (state, action) => {
      state.usedData = action.payload;
    },
    setUsedLoading: (state, action) => {
      state.usedLoading = action.payload;
    },
    setAvailableData: (state, action) => {
      state.availableData = action.payload;
    },
    setAvailableLoading: (state, action) => {
      state.availableLoading = action.payload;
    },
    setLength: (state, action) => {
      state.length = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCartInfo.pending, (state) => {
        state.cartInfoLoading = true;
      })
      .addCase(getCartInfo.fulfilled, (state, action) => {
        state.cartInfoLoading = false;
        state.cartInfo = action.payload.data[0];
      })
      .addCase(getCartInfo.rejected, (state) => {
        state.cartInfoLoading = false;
      })
      .addCase(getCartCount.pending, (state) => {
        state.cartCountLoading = true;
      })
      .addCase(getCartCount.fulfilled, (state, action) => {
        state.cartCountLoading = false;
        state.cartCount = action.payload.data;
      })
      .addCase(getCartCount.rejected, (state) => {
        state.cartCountLoading = false;
      })
      .addCase(getMyPromotion.pending, (state) => {
        state.myPromotionLoading = true;
      })
      .addCase(getMyPromotion.fulfilled, (state, action) => {
        state.myPromotionLoading = false;

        const myPromotionList = action.payload.data;

        if (
          myPromotionList.promotion_bag?.length > 0 &&
          myPromotionList.promotion_bag[0].current_status === "completed"
        ) {
          state.usedPromotion = myPromotionList;
        } else {
          state.usedPromotion = [];
        }

        if (
          myPromotionList.promotion_bag?.length > 0 &&
          myPromotionList.promotion_bag[0].current_status === "pending"
        ) {
          state.myPromotion = myPromotionList;
        } else {
          state.myPromotion = [];
        }
      })
      .addCase(getMyPromotion.rejected, (state) => {
        state.myPromotionLoading = false;
      })
      .addCase(getAllPayments.pending, (state) => {
        state.paymentListLoading = true;
      })
      .addCase(getAllPayments.fulfilled, (state, action) => {
        state.paymentListLoading = false;
        state.paymentList = action.payload.data;
      })
      .addCase(getAllPayments.rejected, (state) => {
        state.paymentListLoading = false;
      })
      .addCase(getAddOnMenu.pending, (state) => {
        state.addOnMenuLoading = true;
      })
      .addCase(getAddOnMenu.fulfilled, (state, action) => {
        state.addOnMenuLoading = false;
        state.addOnMenu = action.payload.data;
      })
      .addCase(getAddOnMenu.rejected, (state) => {
        state.addOnMenuLoading = false;
      })
      .addCase(getDeliveryTime.pending, (state) => {
        state.deliveryTimeLoading = true;
      })
      .addCase(getDeliveryTime.fulfilled, (state, action) => {
        state.deliveryTimeLoading = false;
        state.deliveryTime = action.payload.data;
      })
      .addCase(getDeliveryTime.rejected, (state) => {
        state.deliveryTimeLoading = false;
      })
      .addCase(getPickUpTime.pending, (state) => {
        state.pickUpTimeLoading = true;
      })
      .addCase(getPickUpTime.fulfilled, (state, action) => {
        state.pickUpTimeLoading = false;
        state.pickUpTime = action.payload.data;
      })
      .addCase(getPickUpTime.rejected, (state) => {
        state.pickUpTimeLoading = false;
      });
  },
});

export const {
  handleChangeQuantity,
  setPaymentMethod,
  setEditCartData,
  setCartInfoLoading,
  setUpdateCartId,
  setPaymentStatus,
  setScheduleDate,
  setScheduleTime,
  setHasMore,
  setStart,
  setUsedData,
  setUsedLoading,
  setAvailableData,
  setAvailableLoading,
  setLength,
  setMyPromotionLoading,
} = dataSlice.actions;

export default dataSlice.reducer;
