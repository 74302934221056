//service
import http from "./http.service";

async function getGuestToken(payload) {
  // done
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + "/guest",
    payload
  );
  return response.data;
}

async function getWalletBalance(payload) {
  // done
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/get_wallet_balance`,
    payload
  );
  return response.data;
}

async function getOutletList(payload) {
  // http.setToken(localStorage.getItem("token"));
  // const response = await http.get(
  //     process.env.REACT_APP_API_URL +
  //     `/merchant_list?latitude=${payload.latitude}&longitude=${payload.longitude}`
  // );
  // return response.data;
}

async function getAddressList(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(process.env.REACT_APP_API_URL + `/address`);
  return response.data;
}

async function getAddressInfo(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL + `/address/${payload.id}`
  );
  return response.data;
}

async function createAddress(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/address`,
    payload
  );
  return response.data;
}

async function updateAddress(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.put(
    process.env.REACT_APP_API_URL + `/address/${payload.id}`,
    payload
  );
  return response.data;
}

async function deleteAddress(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.delete(
    process.env.REACT_APP_API_URL + `/address/${payload.id}`
  );
  return response.data;
}

// menu
async function getMerchantInfo(payload) {
  // done
  http.setToken(localStorage.getItem("token"));
  let url =
    process.env.REACT_APP_API_URL + `/merchant_card/${payload.display_name}`;
  if (payload.table_id) {
    url += `?table_id=${payload.table_id}`;
  }
  if (payload.session_no) {
    url += `&session_no=${payload.session_no}`;
  }
  const response = await http.get(url);
  return response.data;
}

async function getMenuList(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/display_menu?merchant_id=${payload.merchant_id}&remark=${payload.remark}&method=${payload.method}&table_id=${payload.table_id}&session_no=${payload.session_no}`
  );
  return response.data;
}

async function getCategoryList(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/category_listing?merchant_id=${payload.merchant_id}`
  );
  return response.data;
} // category de image how

async function getMenuInfo(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/option_listing/${payload.id}?method=${payload.method}`
  );
  return response.data;
} // category de image how

//checkout
async function getCartInfo(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/cart/display_cart`,
    payload
  );
  return response.data;
  // http.setToken(localStorage.getItem("token"));
  // const shipping = localStorage.getItem("shipping");
  // const response =
  //     shipping === "Delivery"
  //     ? await http.post(
  //         process.env.REACT_APP_API_URL +
  //             `/cart/display_cart?address_id=${payload.address_id ?? ""}&invitation_code=${
  //             payload.invitation_code}&delivery_method=${payload.delivery_method ?? ""}`
  //         )
  //     : await http.post(
  //         process.env.REACT_APP_API_URL +
  //             `/cart/display_cart?invitation_code=${payload.invitation_code}`
  //         );
  // return response.data;
}

async function addCart(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/cart`,
    payload
  );
  return response.data;
}

async function clearCart(payload) {
  // clear whole cart
  http.setToken(localStorage.getItem("token"));
  const response = await http.delete(
    process.env.REACT_APP_API_URL + `/cart/${payload.id}`
  );
  return response.data;
}

async function updateCart(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.put(
    process.env.REACT_APP_API_URL + `/cart/${payload.cart_id}`,
    payload
  );
  return response.data;
}

async function verifyUserContact(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/verify_user_if_exist`,
    payload
  );
  return response.data;
}

async function createOrder(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/transfer_cart_into_order`,
    payload
  );
  return response.data;
}

async function makePayment(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/make_payment`,
    payload
  );
  return response.data;
}

async function getAllPayments(payload) {
  http.setToken(localStorage.getItem("token"));
  const response =
    payload.cart_id !== undefined
      ? await http.get(
          process.env.REACT_APP_API_URL +
            `/get_all_channels?cart_id=${payload.cart_id}&amount=${payload.amount}&merchant_id=${payload.merchant_id}&type=${payload.type}&allow_online_payment=${payload.allow_online_payment}`
        )
      : await http.get(
          process.env.REACT_APP_API_URL +
            `/get_all_channels?order_id=${payload.order_id}&amount=${payload.amount}&merchant_id=${payload.merchant_id}&type=${payload.type}&allow_online_payment=${payload.allow_online_payment}`
        );
  return response.data;
}

async function generatePayment(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/transaction/generate_order_payment`,
    payload
  );
  return response.data;
}

//order
async function getOrderOngoingList(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/order?merchant_id=${payload.merchant_id}&filter_status=processing`
  );
  return response.data;
}

async function getOrderHistoryList(payload) {
  http.setToken(localStorage.getItem("token"));

  let url = `${process.env.REACT_APP_API_URL}/order?merchant_id=${payload.merchant_id}&filter_status=completed`;

  if (payload.hasOwnProperty("start")) {
    url += `&start=${payload.start}`;
  }

  if (payload.hasOwnProperty("length")) {
    url += `&length=${payload.length}`;
  }

  const response = await http.get(url);
  return response.data;
}

async function getProfile(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(process.env.REACT_APP_API_URL + `/profile`);
  return response.data;
}

async function updateProfile(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/update_profile`,
    payload
  );
  return response.data;
}

async function getPurchaseHistory(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/purchase_history`,
    payload
  );
  return response.data;
}

async function getReceiptInfo(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/order_detail`,
    payload
  );
  return response.data;
}

async function getPublicReceipt(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/share_order_detail`,
    payload
  );
  return response.data;
}

async function getWalletHistory(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/get_wallet_history`,
    payload
  );
  return response.data;
}

async function getCartCount(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/count_cart_item?merchant_id=${payload.merchant_id}&order_method=${payload.order_method}`
  );
  return response.data;
}

async function withdrawAmount(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/withdraw_wallet`,
    payload
  );
  return response.data;
}

async function getAnnouncement(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/announcement_listing?merchant_id=${payload.id}`,
    payload
  );
  return response.data;
}

async function getQueue(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/show_user_queue?merchant_id=${payload.merchant_id}&queue_entry_id=${payload.queue_entry_id}`
  );
  return response.data;
}

async function createQueue(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/join_queue`,
    payload
  );
  return response.data;
}

async function leaveQueue(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/leave_queue`,
    payload
  );
  return response.data;
}

async function getRecommendMenu(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    `${process.env.REACT_APP_API_URL}/menu_recommendation?type=${payload.type}&merchant_id=${payload.merchant_id}&method=${payload.method}` +
      (payload.type === "add on items" ? `&cart_id=${payload.cart_id}` : "")
  );
  return response.data;
}

async function getPromotionList(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/promotion_listing?merchant_id=${payload.merchant_id}${
        payload.applicable_type
          ? `&applicable_type=${payload.applicable_type}`
          : ""
      }${payload.columns ? `&columns=${payload.columns}` : ""}${
        payload.order ? `&order=${payload.order}` : ""
      }`,
    payload
  );
  return response.data;
}

async function getPromotionInfo(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL + `/show_user_promotion/${payload.id}`,
    payload
  );
  return response.data;
}

async function getPromotionCount(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/promotion_bag_quantity?merchant_id=${payload.merchant_id}`,
    payload
  );
  return response.data;
}

async function claimPromotion(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/claim_promotion/${payload.id}`,
    payload
  );
  return response.data;
}

async function getMyPromotion(payload, options = {}) {
  http.setToken(localStorage.getItem("token"));
  let url = `${process.env.REACT_APP_API_URL}/promotion_bag_listing?cart_id=${payload.cart_id}&merchant_id=${payload.merchant_id}&status=${payload.status}`;

  if (payload.hasOwnProperty("start")) {
    url += `&start=${payload.start}`;
  }

  if (payload.hasOwnProperty("length")) {
    url += `&length=${payload.length}`;
  }

  const response = await http.get(url, {
    signal: options.signal,
  });
  return response.data;
}

async function sendFeedback(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/feedback`,
    payload
  );
  return response.data;
}

async function getWheelList(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/spin_wheel/show_user_wheel/${payload.merchant_id}`,
    payload
  );
  return response.data;
}

async function spinWheel(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/spin_wheel/spin/${payload.merchant_id}`,
    payload
  );
  return response.data;
}

async function getSpinHistory(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/spin_wheel/history?merchant_id=${payload.merchant_id}&has_reward=${payload.has_reward}&startDate=${payload.startDate}&endDate=${payload.endDate}&start=${payload.start}&search=${payload.search}&length=${payload.length}`,
    payload
  );
  return response.data;
}

async function getCheckInList(payload) {
  http.setToken(localStorage.getItem("token"));
  let url = `${process.env.REACT_APP_API_URL}/check_in_listing/${payload.merchant_id}`;

  if (payload.hasOwnProperty("start")) {
    url += `?start=${payload.start}`;
  }

  if (payload.hasOwnProperty("length")) {
    url += `${payload.hasOwnProperty("start") ? "&" : "?"}length=${
      payload.length
    }`;
  }

  const response = await http.get(url);
  return response.data;
}

async function claimCheckInReward(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/claim_check_in_item`,
    payload
  );
  return response.data;
}

async function topUpWallet(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/topup_wallet`,
    payload
  );
  return response.data;
}

async function getTopUpPackageList(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/list_topup_packages?merchant_id=${payload.merchant_id}`,
    payload
  );
  return response.data;
}

async function getTopUpPaymentList(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/list_topup_payments?merchant_id=${payload.merchant_id}&allow_online_payment=${payload.allow_online_payment}&amount=${payload.amount}`,
    payload
  );
  return response.data;
}

async function useVoucherNow(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/promotion/use/${payload.id}`,
    payload
  );
  return response.data;
}

async function getUserRank(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/user_view_rank?merchant_id=${payload.merchant_id}`,
    payload
  );
  return response.data;
}

async function subscribeNotification(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/subscribe`,
    payload
  );
  return response.data;
}

async function getPointBalance(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/point/get?merchant_id=${payload.merchant_id}`,
    payload
  );
  return response.data;
}

async function buyPromotion(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/buy_marketplace_promotion/${payload.id}`,
    payload
  );
  return response.data;
}

async function getMerchantList(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/merchant_list?search=${payload.search}&start=${payload.start}&length=${payload.length}`,
    payload
  );
  return response.data;
}

async function getSupermerchantList(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/supermerchant_outlet?display_name=${payload.display_name}`,
    payload
  );
  return response.data;
}

async function checkAddress(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/check_valid_address?address_id=${payload.address_id}&merchant_id=${payload.merchant_id}`,
    payload
  );
  return response.data;
}

//reservation
async function getExperience(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/experience_listing?merchant_id=${payload.merchant_id}`,
    payload
  );
  return response.data;
}

async function getTableSection(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/table_section_listing?merchant_id=${payload.merchant_id}&experience_id=${payload.experience_id}`,
    payload
  );
  return response.data;
}

async function getReservationTime(payload) {
  http.setToken(localStorage.getItem("token"));

  let url = `${process.env.REACT_APP_API_URL}/reservation_time_listing?merchant_id=${payload.merchant_id}`;

  if (payload.hasOwnProperty("service_name")) {
    url += `&name=${payload.service_name}`;
  }

  const response = await http.get(url);
  return response.data;
}

async function createReservation(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/reservations`,
    payload
  );
  return response.data;
}

async function getReservationInfo(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/user/reservations/info`,
    payload
  );
  return response.data;
}

async function claimPoint(payload) {
  http.setToken(localStorage.getItem("token"), payload.hash);

  const response = await http.post(
    process.env.REACT_APP_API_URL + `/claim_membership`,
    payload
  );
  return response.data;
}

async function getDeliveryTime(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/available_delivery_slots?merchant_id=${payload.merchant_id}`,
    payload
  );
  return response.data;
}

async function getPickUpTime(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/available_pick_up_slots?merchant_id=${payload.merchant_id}`,
    payload
  );
  return response.data;
}

async function resubmitRefund(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/refund/manual_request`,
    payload
  );
  return response.data;
}

const apiService = {
  getOutletList,
  getGuestToken,
  getAddressList,
  getAddressInfo,
  checkAddress,
  createAddress,
  updateAddress,
  deleteAddress,
  getMerchantInfo,
  getMenuList,
  getCategoryList,
  getMenuInfo,
  getCartInfo,
  addCart,
  clearCart,
  updateCart,
  verifyUserContact,
  createOrder,
  makePayment,
  generatePayment,
  getOrderOngoingList,
  getOrderHistoryList,
  getProfile,
  updateProfile,
  getReceiptInfo,
  getPublicReceipt,
  getWalletHistory,
  getPurchaseHistory,
  getCartCount,
  withdrawAmount,
  getWalletBalance,
  getAnnouncement,
  getAllPayments,
  getQueue,
  createQueue,
  leaveQueue,
  getRecommendMenu,
  getPromotionList,
  getPromotionCount,
  claimPromotion,
  getMyPromotion,
  sendFeedback,
  getWheelList,
  spinWheel,
  getSpinHistory,
  getCheckInList,
  claimCheckInReward,
  topUpWallet,
  getTopUpPackageList,
  getTopUpPaymentList,
  useVoucherNow,
  getPromotionInfo,
  getUserRank,
  subscribeNotification,
  getPointBalance,
  buyPromotion,
  getMerchantList,
  getSupermerchantList,
  getExperience,
  getTableSection,
  getReservationTime,
  createReservation,
  getReservationInfo,
  claimPoint,
  getDeliveryTime,
  getPickUpTime,
  resubmitRefund,
};

export default apiService;
