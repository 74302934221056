import { useEffect, useRef, useState } from "react";

import { Coin, DefaultPromo } from "../../../assets/images";

import { Icon } from "@iconify/react";
import { Tooltip } from "react-tooltip";
import { MDBModalBody, MDBRow } from "mdb-react-ui-kit";
import InfiniteScroll from "react-infinite-scroll-component";
import { LazyLoadImage } from "react-lazy-load-image-component";

import scrollToTop from "../../../helpers/scroll.to.top";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import { getPromotionInfo } from "../../../redux/reducer/commonReducer";
import {
  getMarketplaceList,
  getUserRank,
  getWalletHistory,
  setIsBuyingPromo,
  setNeedLoading,
} from "../../../redux/reducer/homeReducer";
import {
  closeMarketModal,
  openLoginModal,
  openPromotionDetailsModal,
  setAnimationModal,
} from "../../../redux/reducer/modalReducer";

import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";
import formatNumber from "../../../helpers/format.number";
import Button from "../../../components/element/button";
import ActionMenu from "../../../components/element/action.menu";
import Textfit from "@namhong2001/react-textfit";

export default function Marketplace({ setLoginType }) {
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();

  const { merchantInfo } = useSelector((state) => state.merchant);
  const { merchantId, login } = useSelector((state) => state.common);
  const { isOpenMarketModal } = useSelector((state) => state.modal);
  const {
    pointBalance,
    walletHistoryLoading,
    marketplaceListLoading,
    marketplaceList,
    userRank,
    userRankLoading,
  } = useSelector((state) => state.home);

  const [length, setLength] = useState(14);
  const [start, setStart] = useState(0);
  const [data, setData] = useState([]);
  const [order, setOrder] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(
    merchantInfo.has_marketplace === 1 ? 1 : 2
  );

  let scrollHeight = height - (width > 550 ? height * 0.35 : height * 0.45);

  const sortMenu = [
    {
      label: "Low to high",
      icon: "octicon:sort-asc-24",
      action: "asc",
    },
    {
      label: "High to low",
      icon: "octicon:sort-desc-24",
      action: "desc",
    },
  ];

  const getDateTime = (dateTime) => {
    const dateObject = new Date(dateTime);

    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = new Intl.DateTimeFormat("en", { month: "short" }).format(
      dateObject
    );
    const year = dateObject.getFullYear();
    const hours = String(dateObject.getHours()).padStart(2, "0");
    const minutes = String(dateObject.getMinutes()).padStart(2, "0");

    const formattedDateTime = `${day} ${month} ${year} ${hours}:${minutes}`;

    return formattedDateTime;
  };

  const getMoreData = () => {
    dispatch(setNeedLoading(false));
    dispatch(
      getWalletHistory({
        merchant_id: merchantId,
        length: length,
        start: start + 14,
        type: "point",
      })
    )
      .unwrap()
      .then((res) => {
        setStart(start + 14);

        let newData = [];
        for (var key in res.data.aaData) {
          newData.push({
            ...res.data.aaData[key],
          });
        }
        if (data.length + newData.length >= res.data.iTotalRecords) {
          setHasMore(false);
        }
        setData([...data, ...newData]);
      })
      .catch((ex) => {});
  };

  const handleCloseMarket = () => {
    dispatch(setAnimationModal(true));
    dispatch(closeMarketModal());
  };

  const handleChangeStatus = () => {
    if (login === "full") {
      if (currentStatus === 1) {
        dispatch(
          getUserRank({
            merchant_id: merchantId,
          })
        )
          .unwrap()
          .then((res) => {
            setCurrentStatus(2);
          })
          .catch((ex) => {});
      } else {
        setCurrentStatus(1);

        if (marketplaceList?.length === 0) {
          dispatch(
            getMarketplaceList({
              columns: "costing",
              order: "asc",
              merchant_id: merchantId,
              applicable_type: "marketplace",
            })
          );
        }
      }
    } else {
      setLoginType("full-marketplace");
      dispatch(closeMarketModal());
      dispatch(openLoginModal());
    }
  };

  const handleViewReward = (id) => {
    dispatch(
      getPromotionInfo({
        id: id,
      })
    );
    dispatch(closeMarketModal());
    dispatch(setAnimationModal(false));
    dispatch(openPromotionDetailsModal());
    dispatch(setIsBuyingPromo(true));
  };

  const handleSortPrice = (order) => {
    setOrder(order);
    dispatch(
      getMarketplaceList({
        columns: "costing",
        order: order,
        merchant_id: merchantId,
        applicable_type: "marketplace",
      })
    );
  };

  const formatDate = (expiryDate) => {
    const dateObject = new Date(expiryDate);
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = new Intl.DateTimeFormat("en", { month: "short" }).format(
      dateObject
    );
    const year = dateObject.getFullYear();

    return `${day} ${month} ${year}`;
  };

  useEffect(() => {
    if (currentStatus === 2) {
      dispatch(
        getWalletHistory({
          merchant_id: merchantId,
          length: length,
          start: start,
          type: "point",
        })
      )
        .unwrap()
        .then((res) => {
          setData(res.data.aaData);
          if (data.length < res.data.iTotalRecords) {
            setHasMore(true);
          }
        })
        .catch((ex) => {});
    }
  }, [currentStatus]);

  useEffect(() => {
    dispatch(setAnimationModal(true));

    if (isOpenMarketModal === "point") {
      setCurrentStatus(2);
    }

    if (modalRef.current) {
      scrollToTop(modalRef.current);
    }
  }, [isOpenMarketModal]);

  return (
    <FullModal
      show={isOpenMarketModal || isOpenMarketModal === "point"}
      backButton={handleCloseMarket}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      className="--hidden"
      content={
        <>
          <ModalHeader
            title={currentStatus === 1 ? "Marketplace" : "My Membership"}
            backTo={handleCloseMarket}
            backToNoAnimation={handleCloseMarket}
            type="model2"
          />
          <MDBModalBody ref={modalRef}>
            <article
              style={{ paddingBottom: "4em" }}
              className="marketplace-modal"
            >
              <section className="curve-bg"></section>
              <section
                className="point-section w-100"
                style={{
                  top:
                    currentStatus === 1 ||
                    (currentStatus === 2 &&
                      !userRank.current_rank_name &&
                      !userRank.next_rank_name &&
                      !userRankLoading)
                      ? "-92px"
                      : "-110px",
                }}
              >
                {currentStatus === 1 ||
                (currentStatus === 2 &&
                  !userRank.current_rank_name &&
                  !userRank.next_rank_name &&
                  !userRankLoading) ? (
                  <article className="w-100">
                    <section className="flex items-end justify-between">
                      <article>
                        <h3>
                          {login === "full"
                            ? parseFloat(pointBalance).toFixed(2)
                            : "0"}
                          <span> pts</span>
                        </h3>
                        {(login !== "full" ||
                          (currentStatus === 1 &&
                            merchantInfo.has_membership === 1) ||
                          (currentStatus === 2 &&
                            merchantInfo.has_marketplace === 1)) && (
                          <article
                            className="flex items-center gap-1 mt-2 pointer relative-zindex"
                            onClick={() => handleChangeStatus()}
                          >
                            <p className="mb-0 pointer">
                              {login === "full"
                                ? currentStatus === 1
                                  ? "View Membership"
                                  : "Redeem Reward"
                                : "Log in or register to view your points"}
                            </p>
                            <Icon icon="mingcute:right-line" />
                          </article>
                        )}
                      </article>
                      <ActionMenu
                        className="sort-menu"
                        menus={sortMenu}
                        top={5}
                        handleSortPrice={handleSortPrice}
                      >
                        <Button
                          btnClassName="sort-button"
                          className="sort-position"
                        >
                          <article>
                            <Icon
                              className={order ? "--bigger" : "'"}
                              icon={
                                order === "asc"
                                  ? "octicon:sort-asc-24"
                                  : order === "desc"
                                  ? "octicon:sort-desc-24"
                                  : "fluent:arrow-sort-24-regular"
                              }
                            />
                          </article>
                          {order === "asc"
                            ? "Low to high"
                            : order === "desc"
                            ? "High to low"
                            : "Sort"}
                        </Button>
                      </ActionMenu>
                    </section>
                    <section className="point-frame">
                      <LazyLoadImage src={Coin} />
                    </section>
                  </article>
                ) : (
                  <article className="w-100">
                    <section className="member-card">
                      <article>
                        <section className="flex flex-wrap justify-between items-start px-3em">
                          <article className="flex items-center gap-2 rate-info">
                            <article className="dynamic-point">
                              <Textfit
                                mode="single"
                                forceSingleModeWidth={true}
                                max={22}
                              >
                                {parseFloat(pointBalance).toFixed(2)}{" "}
                                <span> pts</span>
                              </Textfit>
                            </article>
                            <article className="flex items-center gap-2 mt-2">
                              <article className="" data-tooltip-id="rate-desc">
                                <Tooltip
                                  id="rate-desc"
                                  className="rate-desc"
                                  place="top"
                                  content={
                                    <>
                                      <p>
                                        Cummulative spending power : RM{" "}
                                        {parseFloat(
                                          userRank.current_spending_power
                                        ).toFixed(2)}
                                      </p>
                                      <p>
                                        Point balance :{" "}
                                        {parseFloat(pointBalance).toFixed(2)}{" "}
                                        pts
                                      </p>
                                      <article className="flex items-center mt-2">
                                        {userRank.current_rank_rate ? (
                                          <>
                                            <Icon icon="mdi:crown-circle" />
                                            <h6>
                                              Spend every RM 1 will get{" "}
                                              {parseFloat(
                                                userRank.current_rank_rate
                                              ) %
                                                1 ===
                                              0
                                                ? parseFloat(
                                                    userRank.current_rank_rate
                                                  ).toFixed(0)
                                                : parseFloat(
                                                    userRank.current_rank_rate
                                                  ).toFixed(2)}{" "}
                                              point(s)
                                            </h6>
                                          </>
                                        ) : (
                                          <>
                                            <Icon
                                              icon="fluent:question-circle-16-filled"
                                              className="--question"
                                            />
                                            <h6>
                                              Spend minimum RM{" "}
                                              {parseFloat(
                                                userRank.next_rank_from_range -
                                                  userRank.current_spending_power
                                              ).toFixed(2)}{" "}
                                              to earn points
                                            </h6>
                                          </>
                                        )}
                                      </article>
                                    </>
                                  }
                                />
                                <Icon
                                  icon="eva:info-outline"
                                  className="mb-2 ms-50"
                                />
                              </article>
                            </article>
                          </article>
                          <article className="flex items-center gap-2">
                            <article
                              className="rank-frame"
                              style={{
                                background:
                                  userRank.current_media?.length > 0
                                    ? "transparent"
                                    : "transparent",
                              }}
                            >
                              {userRank.current_media?.length > 0 && (
                                <LazyLoadImage
                                  src={userRank.current_media[0].url}
                                />
                              )}
                            </article>
                            <h5 className="rank">
                              {userRank.current_rank_name
                                ? userRank.current_rank_name
                                : ""}
                            </h5>
                          </article>
                        </section>
                        <section className="relative mx-5 mt-3">
                          <article className="progress-bar">
                            <article
                              className="progress"
                              style={{
                                width:
                                  userRank.remainder_point_to_next_rank &&
                                  userRank.next_rank_from_range
                                    ? parseFloat(
                                        userRank.remainder_point_to_next_rank
                                      ).toFixed(2) ===
                                      parseFloat(
                                        userRank.next_rank_from_range
                                      ).toFixed(2)
                                      ? "0%"
                                      : `${
                                          (parseFloat(
                                            userRank.next_rank_from_range -
                                              userRank.remainder_point_to_next_rank
                                          ).toFixed(2) /
                                            parseFloat(
                                              userRank.next_rank_from_range
                                            ).toFixed(2)) *
                                            100 -
                                          4
                                        }%`
                                    : "100%",
                              }}
                            />
                            {userRank.next_rank_name && (
                              <article
                                data-tooltip-id="next-rate-desc"
                                className="rank-frame --absolute"
                                style={{
                                  background:
                                    userRank.next_media?.length > 0
                                      ? "transparent"
                                      : "",
                                }}
                              >
                                {userRank.next_media?.length > 0 && (
                                  <LazyLoadImage
                                    src={userRank.next_media[0].url}
                                  />
                                )}
                              </article>
                            )}
                          </article>
                        </section>
                        <section>
                          {userRank.next_rank_rate && (
                            <Tooltip
                              id="next-rate-desc"
                              className="rate-desc --next"
                              place="top"
                              content={
                                <>
                                  <article className="flex items-center mt-2">
                                    <Icon icon="mdi:crown-circle" />
                                    <h6>
                                      Spend every RM 1 will get{" "}
                                      {parseFloat(userRank.next_rank_rate) %
                                        1 ===
                                      0
                                        ? parseFloat(
                                            userRank.next_rank_rate
                                          ).toFixed(0)
                                        : parseFloat(
                                            userRank.next_rank_rate
                                          ).toFixed(2)}{" "}
                                      point(s) on next rank
                                    </h6>
                                  </article>
                                </>
                              }
                            />
                          )}
                          {!userRankLoading ? (
                            userRank.next_rank_name ? (
                              <p className="mt-3 earn-more">
                                Earn{" "}
                                {parseFloat(
                                  userRank.remainder_point_to_next_rank
                                ).toFixed(2)}{" "}
                                points more to reach{" "}
                                <span data-tooltip-id="next-rate-desc">
                                  {userRank.next_rank_name}
                                </span>
                                .
                              </p>
                            ) : (
                              <p className="mt-3 earn-more">
                                Congratulations! You're now on{" "}
                                <span data-tooltip-id="next-rate-desc">
                                  highest rank
                                </span>
                                .
                              </p>
                            )
                          ) : (
                            <p className="mt-3 earn-more">-</p>
                          )}
                          <article
                            className={`${
                              width > 400 ? "flex" : "d-block"
                            } justify-between items-end with-bg flex-wrap`}
                          >
                            {userRank.expiring_date &&
                            userRank.expiring_point ? (
                              <p className="m-0">
                                {parseFloat(userRank.expiring_point) % 1 === 0
                                  ? parseFloat(userRank.expiring_point).toFixed(
                                      0
                                    )
                                  : parseFloat(userRank.expiring_point).toFixed(
                                      2
                                    )}{" "}
                                points <span>will expire on</span>{" "}
                                {formatDate(userRank.expiring_date)}
                              </p>
                            ) : (
                              <p></p>
                            )}
                            {merchantInfo.has_marketplace === 1 && (
                              <article
                                className="flex items-center gap-1 pointer redeem-reward"
                                onClick={() => handleChangeStatus()}
                              >
                                <p
                                  className={`${
                                    width > 400 ? "m-0" : "mt-1 mb-0"
                                  }`}
                                >
                                  Redeem Reward
                                </p>
                                <Icon icon="mingcute:right-line" />
                              </article>
                            )}
                          </article>
                        </section>
                      </article>
                    </section>
                  </article>
                )}
              </section>
              {currentStatus === 2 ? (
                <section
                  className="body-section pt-3"
                  style={{
                    top:
                      currentStatus === 2 &&
                      !userRank.current_rank_name &&
                      !userRank.next_rank_name &&
                      !userRankLoading
                        ? "-61px"
                        : "-100px",
                  }}
                >
                  <h5>Transactions History</h5>
                  <InfiniteScroll
                    className={`--wallet`}
                    dataLength={data.length}
                    height={`${scrollHeight}px`}
                    hasMore={hasMore}
                    next={() => {
                      getMoreData();
                    }}
                    loader={
                      hasMore && (
                        <div className="text-center mt-5 infinite-loading-label">
                          Loading..
                        </div>
                      )
                    }
                  >
                    {(hasMore || !walletHistoryLoading) && data?.length > 0 ? (
                      data.map((history, historyIndex) => (
                        <MDBRow className="mt-4" key={historyIndex}>
                          <div
                            className={`flex justify-between items-center history-row ${
                              historyIndex === data.length - 1
                                ? "--no-line"
                                : ""
                            }`}
                          >
                            <div
                              className={`text-start mb-3 ${
                                width < 380 ? "col-6" : ""
                              }`}
                            >
                              <div className="_label --wallet-label">
                                {history.description}
                              </div>
                              <div className="_label --wallet-desc mt-3">
                                {getDateTime(history.created_at)}
                              </div>
                            </div>
                            <div
                              className={`_label --wallet-price ${
                                history.amount < 0
                                  ? "--red"
                                  : history.amount > 0
                                  ? "--green"
                                  : ""
                              } mb-3 ${width < 380 ? "col-6" : ""}`}
                            >
                              {history.amount < 0
                                ? "-"
                                : history.amount > 0
                                ? "+"
                                : ""}{" "}
                              {history.amount !== null
                                ? `${Math.abs(
                                    parseFloat(history.amount)
                                  ).toFixed(2)}`
                                : "0.00"}{" "}
                              pts
                            </div>
                          </div>
                        </MDBRow>
                      ))
                    ) : !hasMore && walletHistoryLoading ? (
                      <article className="general-loading">
                        <Icon icon="svg-spinners:12-dots-scale-rotate" />
                      </article>
                    ) : (
                      ""
                    )}
                    {!hasMore && !walletHistoryLoading ? (
                      data.length > 0 ? (
                        <div className="text-center mt-5 infinite-loading-label --nothing">
                          Nothing more..
                        </div>
                      ) : (
                        <article className="empty-container">
                          <p>No point history record found</p>
                        </article>
                      )
                    ) : (
                      ""
                    )}
                  </InfiniteScroll>
                </section>
              ) : (
                <section className="body-section pt-3">
                  {!marketplaceListLoading && marketplaceList.length > 0 ? (
                    <article className="reward-row">
                      {marketplaceList.map((promo, promoIndex) => (
                        <article
                          className="reward-col pointer"
                          key={promoIndex}
                          onClick={() => handleViewReward(promo.id)}
                        >
                          <section className="reward-frame">
                            <LazyLoadImage
                              src={
                                promo.media.length > 0
                                  ? promo.media[0].url
                                  : DefaultPromo
                              }
                            />
                          </section>
                          <section className="reward-detail">
                            <h3 className="two-line">{promo.title}</h3>
                            <br />
                            {/* <p className="one-line mb-0">{promo.description}</p> */}
                            <article
                              className="flex justify-between mt-4"
                              style={{ alignItems: "baseline" }}
                            >
                              <p className="m-0 view-details">View Details</p>
                              <h4>
                                <span>
                                  {parseFloat(promo.costing).toFixed(2)}
                                </span>{" "}
                                pts
                              </h4>
                            </article>
                          </section>
                        </article>
                      ))}
                    </article>
                  ) : marketplaceListLoading ? (
                    <article className="general-loading">
                      <Icon icon="svg-spinners:12-dots-scale-rotate" />
                    </article>
                  ) : (
                    <article className="empty-container">
                      <p>No marketplace voucher found</p>
                    </article>
                  )}
                </section>
              )}
            </article>
          </MDBModalBody>
        </>
      }
    />
  );
}
