import { useEffect } from "react";

//lib
import { Icon } from "@iconify/react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Delivery, DineIn, SelfPick } from "../../assets/images";
import {
  clearCart,
  getCartInfo,
  setCartInfoLoading,
} from "../../redux/reducer/cartReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  closeAllModal,
  openConfirmModal,
  openSetAddressModal,
} from "../../redux/reducer/modalReducer";
import { getMethod, setConfirmItem } from "../../redux/reducer/commonReducer";
import { getMenuList } from "../../redux/reducer/menuReducer";

//assets

//helper

//element

//component

//redux

export default function PageHeader({
  title,
  endsIn,
  backIcon,
  backTo,
  navIcon,
  navTo,
  navToOrder,
  className,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { store } = useParams();
  const { pathname } = useLocation();

  const { menuList, cartOwnerId } = useSelector((state) => state.menu);
  const { cartCount, cartCountLoading, cartInfo, cartInfoLoading } =
    useSelector((state) => state.cart);
  const { merchantInfo } = useSelector((state) => state.merchant);
  const {
    method,
    addressId,
    merchantId,
    userId,
    tableId,
    cartOwner,
    cartId,
    sessionNo,
  } = useSelector((state) => state.common);

  const backToHome = () => {
    navigate(`/${store}`);
    dispatch(closeAllModal());
  };

  const handleSwitchMethod = () => {
    if (cartId && cartOwner && cartOwner === "true") {
      dispatch(openConfirmModal());
      dispatch(
        setConfirmItem({
          type: "switch method",
        })
      );
    } else {
      if (pathname === `/${encodeURIComponent(store?.trim())}/cart`) {
        dispatch(
          getCartInfo({
            merchant_id: merchantId,
            order_method: method === "delivery" ? "take away" : "delivery",
          })
        );
      } else {
        dispatch(
          getMenuList({
            merchant_id: merchantId,
            remark: "food",
            method: method === "delivery" ? "take away" : "delivery",
            table_id:
              method === "dine in"
                ? tableId
                  ? tableId === "null"
                    ? null
                    : tableId
                  : null
                : null,
            session_no:
              method === "dine in"
                ? sessionNo
                  ? sessionNo === "null"
                    ? null
                    : sessionNo
                  : null
                : null,
          })
        );
      }

      if (method === "delivery") {
        localStorage.setItem("method", "take away");
      } else {
        localStorage.setItem("method", "delivery");

        if (!addressId) {
          dispatch(openSetAddressModal());
        }
      }

      dispatch(getMethod());
    }
  };

  return (
    <section
      className={`${
        title && !endsIn ? "items-center" : "items-start"
      } page-header ${className}`}
    >
      <nav
        onClick={
          pathname === `/${encodeURIComponent(store?.trim())}/order/all`
            ? backToHome
            : backTo
        }
        className={`mt-1 pointer`}
      >
        <Icon icon={backIcon} />
      </nav>
      {pathname !== `/${encodeURIComponent(store?.trim())}/order/all` && (
        <article
          className="grid center"
          style={{ marginLeft: navToOrder ? "4.9em" : "", marginTop: "0.3em" }}
        >
          <section className="flex justify-center items-end gap-3">
            <h1 className={method === "dine in" ? "mt-1" : ""}>
              {title
                ? title
                : method === "dine in"
                ? "DINE IN"
                : method === "delivery"
                ? "DELIVERY"
                : method === "take away"
                ? "Self Pick"
                : ""}
            </h1>
            {!title && (
              <article className="method-frame">
                <LazyLoadImage
                  src={
                    method === "dine in"
                      ? DineIn
                      : method === "delivery"
                      ? Delivery
                      : method === "take away"
                      ? SelfPick
                      : ""
                  }
                />
              </article>
            )}
          </section>
          {!title && method === "dine in" ? (
            <button>
              Table {merchantInfo.table_name}
              {/* <Icon icon="material-symbols:change-circle-outline" /> */}
            </button>
          ) : !title &&
            method !== "dine in" &&
            merchantInfo.has_delivery === 1 &&
            merchantInfo.has_take_away === 1 ? (
            <button onClick={() => handleSwitchMethod()}>
              Switch To {method === "delivery" ? "Self Pick" : "Delivery"}
              <Icon icon="material-symbols:change-circle-outline" />
            </button>
          ) : endsIn ? (
            <button>Event ends in {endsIn}</button>
          ) : (
            ""
          )}
          {/* <div style={{ minHeight: '23px' }} /> */}
        </article>
      )}
      {/* sasdasdaasdass */}
      <article className="flex gap-3">
        <nav
          onClick={navTo}
          className={`${
            navTo === undefined ? "transparent-icon" : "relative pointer"
          } ${method !== "dine in" ? "mt-1" : ""}`}
        >
          {pathname === `/${encodeURIComponent(store?.trim())}/menu` &&
            cartCount > 0 &&
            !cartCountLoading && <p className="badge">{cartCount}</p>}
          <Icon
            icon={
              navIcon ? navIcon : "material-symbols-light:order-approve-outline"
            }
          />
        </nav>
        {navToOrder && (
          <nav
            onClick={navToOrder}
            className={`pointer ${method !== "dine in" ? "mt-1" : ""}`}
          >
            <Icon icon={"material-symbols-light:order-approve-outline"} />
          </nav>
        )}
      </article>
    </section>
  );
}
