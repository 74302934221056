import { useEffect, useRef } from "react";

import { DefaultPrize } from "../../../assets/images";

import { MDBModalBody } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

import scrollToTop from "../../../helpers/scroll.to.top";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import {
  closePrizeModal,
  openPrizeDetailsModal,
  openPromotionDetailsModal,
  setAnimationModal,
} from "../../../redux/reducer/modalReducer";

import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";
import {
  getPromotionInfo,
  setPrizeItem,
} from "../../../redux/reducer/commonReducer";

export default function PrizeList({ prizeList }) {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const { width } = useWindowDimensions();

  const { isAnimationModal, isOpenPrizeModal } = useSelector(
    (state) => state.modal
  );

  const handleClosePrize = () => {
    dispatch(closePrizeModal());
  };

  const handleOpenPrizeDetails = (prize) => {
    dispatch(setPrizeItem(prize));
    handleClosePrize();
    dispatch(setAnimationModal(false));
    dispatch(openPrizeDetailsModal());
  };

  const handleOpenPromoDetails = (id) => {
    dispatch(openPromotionDetailsModal("not-claimed"));
    handleClosePrize();
    dispatch(setAnimationModal(false));
    dispatch(
      getPromotionInfo({
        id: id,
      })
    );
  };

  useEffect(() => {
    dispatch(setAnimationModal(true));

    if (modalRef.current) {
      scrollToTop(modalRef.current);
    }
  }, [isOpenPrizeModal]);

  return (
    <FullModal
      centered
      scrollable
      staticBackdrop={true}
      show={isOpenPrizeModal}
      backButton={handleClosePrize}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      contentClassName=""
      content={
        <>
          <ModalHeader
            title="Prize List"
            backTo={handleClosePrize}
            backToNoAnimation={handleClosePrize}
            type="model2"
          />
          <MDBModalBody ref={modalRef}>
            <article className="spin-modal">
              <section className="list-section pb-0">
                {prizeList.length > 0 &&
                  prizeList.map(
                    (prize, prizeIndex) =>
                      prize.reward_name !== "Better luck next time" && (
                        <article
                          className={`${
                            prize.description || prize.promotion_description
                              ? "mb-0 pointer"
                              : "mb-4"
                          }`}
                          key={prizeIndex}
                          onClick={() =>
                            prize.promotion_id
                              ? handleOpenPromoDetails(prize.promotion_id)
                              : prize.description && prize.tnc
                              ? handleOpenPrizeDetails(prize)
                              : undefined
                          }
                        >
                          <article className="prize-frame">
                            {prize.media?.length > 0 ||
                            prize.promotion_image?.length > 0 ||
                            prize.promotion_pic?.length > 0 ? (
                              <LazyLoadImage
                                src={
                                  (prize.media?.length > 0 &&
                                    prize.media[0].url) ||
                                  (prize.promotion_image?.length > 0 &&
                                    prize.promotion_image[0].url) ||
                                  (prize.promotion_pic?.length > 0 &&
                                    prize.promotion_pic[0].url)
                                }
                              />
                            ) : (
                              <LazyLoadImage src={DefaultPrize} />
                            )}
                          </article>
                          <h1 className="text-capitalize">
                            {prize.reward_amount
                              ? parseFloat(prize.reward_amount).toFixed(2)
                              : ""}{" "}
                            {prize.reward_name || prize.promotion_title}
                          </h1>
                          <h5>
                            {prize.description ||
                              prize.promotion_description ||
                              "All credit rewards will be auto credited to your wallet as soon as you receive them."}
                          </h5>
                          {((prize.description && prize.tnc) ||
                            (prize.promotion_description &&
                              prize.promotion_tnc)) && (
                            <p className="--view">View Details</p>
                          )}
                        </article>
                      )
                  )}
              </section>
            </article>
          </MDBModalBody>
        </>
      }
    />
  );
}
