import { Icon } from "@iconify/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Rider } from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/element/button";
import dayjs from "dayjs";
import { openStoreInfoModal } from "../../../redux/reducer/modalReducer";
import { useState } from "react";

export default function MethodInfo({ order }) {
  const dispatch = useDispatch();
  const { merchantInfo } = useSelector((state) => state.merchant);

  const [showAddressIds, setShowAddressIds] = useState([]);

  const handleNavToDelyva = () => {
    window.open(order.delivery_link, "_blank");
  };

  const handleOpenStore = () => {
    dispatch(openStoreInfoModal());
  };

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24?.split(":").map(Number);
    const period = hours >= 12 ? "pm" : "am";
    const adjustedHours = hours % 12 || 12;
    return `${adjustedHours}:${minutes < 10 ? "0" : ""}${minutes} ${period}`;
  };

  const calculateETA = (duration) => {
    if (duration?.split("~")?.length > 0) {
      const [startTime, endTime] = duration.split("~").map((str) => str.trim());

      // Parse start and end times into Date objects
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);

      // Helper function to format the time in 12-hour format
      const convertTo12HourFormat = (date) => {
        const hours = date.getHours() % 12 || 12; // Convert 24-hour format to 12-hour format
        const minutes = date.getMinutes().toString().padStart(2, "0"); // Add leading zero to minutes
        const ampm = date.getHours() >= 12 ? "PM" : "AM";
        return `${hours}:${minutes} ${ampm}`;
      };

      // Format the start and end times
      const startFormatted = convertTo12HourFormat(startDate);
      const endFormatted = convertTo12HourFormat(endDate);

      return `${startFormatted} ~ ${endFormatted}`;
    }
  };

  const calculateBetween = (dateTime) => {
    const timeOnly = dateTime?.split(" ")[1]?.slice(0, 5); // Extract 'hh:mm' from '2024-10-03 11:36:29'

    const [hours, minutes] = timeOnly?.split(":").map(Number);

    const startMinutes = hours * 60 + minutes;
    const endMinutes = startMinutes + 30;

    const endHours = Math.floor(endMinutes / 60) % 24;
    const endMins = endMinutes % 60;

    const startFormatted = convertTo12HourFormat(
      `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}`
    );
    const endFormatted = convertTo12HourFormat(
      `${endHours < 10 ? "0" : ""}${endHours}:${
        endMins < 10 ? "0" : ""
      }${endMins}`
    );

    return `${startFormatted} and ${endFormatted}`;
  };

  const getDayName = (dateTime) => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const date = new Date(dateTime);

    const today = new Date();
    const isToday =
      today.getFullYear() === date.getFullYear() &&
      today.getMonth() === date.getMonth() &&
      today.getDate() === date.getDate();

    if (isToday) {
      return "Today";
    } else {
      const dayIndex = date.getDay();
      return daysOfWeek[dayIndex];
    }
  };

  const handleShowAddress = (id) => {
    setShowAddressIds((prev) =>
      prev.includes(id)
        ? prev.filter((addressId) => addressId !== id)
        : [...prev, id]
    );
  };

  return (
    <>
      {order.method === "delivery" ? (
        <section className="method-info pointer">
          <article className="grid">
            <article className="location-time">
              <article>
                <Icon icon="line-md:map-marker" />
              </article>
              <article>
                <h3 className="mb-1">Delivery Address</h3>
                <p
                  className={
                    showAddressIds?.length > 0 &&
                    showAddressIds.includes(order.id)
                      ? "mb-1"
                      : "one-line"
                  }
                  onClick={() => handleShowAddress(order.id)}
                >
                  {order.delivery_address}
                </p>
                <span onClick={() => handleShowAddress(order.id)}>
                  {showAddressIds.includes(order.id) ? "Hide" : "Show Full"}
                </span>
              </article>
            </article>
            <article className="location-time mb-0">
              <article>
                <Icon icon="line-md:speed-loop" />
              </article>
              <article>
                <h3 className="mb-1">
                  ETA on {getDayName(order.rider_called_at)}{" "}
                  {order.duration && order.duration !== "-"
                    ? calculateETA(order.duration)
                    : ""}{" "}
                  ({order.distance})
                </h3>
                <p className="mb-0 extra">
                  {order.display_delivery_status === "Driver assigned"
                    ? "Your rider is on their way to pick up your order. You can track the progress in real time."
                    : order.display_delivery_status === "Picked up"
                    ? "Your order has been picked up by the rider and is on its way to you. Your meal will be with you shortly."
                    : `Your order will get a rider between ${calculateBetween(
                        order.rider_called_at
                      )}. It may be cancelled with a full refund if no rider is available.`}
                </p>
              </article>
            </article>
            {/* {(order.delivery_status === 'Finding for driver' || !order.delivery_status) && <article className="location-time mb-0">
              <article>
                <Icon icon="line-md:alert-circle" className="--alert" />
              </article>
              <article>
              </article>
            </article>} */}
          </article>
          {order.delivery_link && (
            <article
              className="grid center"
              onClick={() => handleNavToDelyva()}
            >
              <article className="rider-frame">
                <LazyLoadImage src={Rider} />
              </article>
              <p className="view-text">View</p>
            </article>
          )}
        </section>
      ) : order.method === "take away" ? (
        <section
          className="method-info pointer"
          onClick={() => handleOpenStore()}
        >
          <article className="grid w-100">
            <article className="location-time --order-pick w-100 mb-0">
              <article className="flex items-center">
                <article className="flex gap-3">
                  <article>
                    <Icon icon="line-md:map-marker" className="mt-1" />
                  </article>
                  <article>
                    <h3>{merchantInfo.store_name}</h3>
                    <p>
                      {merchantInfo.pic_name} | {merchantInfo.whatsapp_number}
                    </p>
                    <p className="address">{merchantInfo.display_address}</p>
                  </article>
                </article>
                <article>
                  <Icon icon="ion:chevron-right" className="view-icon" />
                </article>
              </article>
              {order.scheduled_at && (
                <article className="location-time row-gap-0">
                  <Icon icon="line-md:speed-loop" />
                  <article className="flex flex-wrap row-gap-0">
                    <h3 className="mb-0">
                      Pick up on{" "}
                      {dayjs(order.scheduled_at).isSame(dayjs(), "day")
                        ? "Today"
                        : dayjs(order.scheduled_at).format("ddd")}
                      , {dayjs(order.scheduled_at).format("h:mm A")}
                    </h3>
                  </article>
                </article>
              )}
              {(merchantInfo.google_map_link || merchantInfo.waze_link) && (
                <article className="store-location">
                  {merchantInfo.waze_link !== null && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();

                        if (merchantInfo.waze_link !== null) {
                          window.open(merchantInfo.waze_link, "_blank");
                        }
                      }}
                    >
                      <Icon icon="simple-icons:waze" className="--map" />
                    </Button>
                  )}
                  {merchantInfo.google_map_link !== null && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();

                        if (merchantInfo.google_map_link !== null) {
                          window.open(merchantInfo.google_map_link, "_blank");
                        }
                      }}
                    >
                      <Icon icon="simple-icons:googlemaps" className="--map" />
                    </Button>
                  )}
                </article>
              )}
            </article>
            {/* <article className="location-time mb-0">
              <Icon icon="line-md:my-location-loop" />
              <article>
                <h3>Estimate Pickup Time : 10Min</h3>
              </article>
            </article> */}
          </article>
        </section>
      ) : (
        ""
      )}
    </>
  );
}
