import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../hook/use.window.dimensions";
import { useParams } from "react-router-dom";
import FullModal from "../modal/full.modal.box";
import { MDBModalBody } from "mdb-react-ui-kit";
import Button from "../element/button";
import { Icon } from "@iconify/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FullLogo } from "../../assets/images";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
  closeReceiptModal,
  closeReceivePointModal,
  closeRefundModal,
  openCongratsModal,
} from "../../redux/reducer/modalReducer";
import { claimPoint } from "../../redux/reducer/homeReducer";
import { useToast } from "./toast.provider";
import CryptoJS from "crypto-js";
import Dropdown from "../element/dropdown";
import {
  getOrderHistoryList,
  resubmitRefund,
  setData,
  setHasMore,
  setInitialDone,
  setLength,
  setStart,
} from "../../redux/reducer/orderReducer";

export default function ResubmitRefund() {
  const toast = useToast();
  const dispatch = useDispatch();

  const { isOpenRefundModal } = useSelector((state) => state.modal);
  const { merchantInfo } = useSelector((state) => state.merchant);
  const { refundLoading, data } = useSelector((state) => state.order);
  const { merchantId } = useSelector((state) => state.common);

  const refundForm = {
    refund_id: "",
    request_payment_method: "bank",
    bank_account_name: "",
    bank_name: "",
    bank_account_number: "",
    phone_number: "",
    card_number: "",
    card_holder_name: "",
  };

  const refundInput = [
    {
      component: "dropdown",
      label: "Request Refund To",
      name: "request_payment_method",
      options: [
        { label: "FPX", value: "bank" },
        { label: "Card", value: "card" },
        { label: `Boost`, value: "boost" },
        { label: `Grab Pay`, value: "grabpay" },
        { label: `Touch n' Go`, value: "tng" },
      ],
    },
    {
      component: "input",
      label: "Bank Name",
      name: "bank_name",
      placeholder: "Public Bank",
    },
    {
      component: "input",
      label: "Account No.",
      name: "bank_account_number",
    },
    {
      component: "input",
      label: "Account Name",
      name: "bank_account_name",
      placeholder: "John Doe",
    },
    {
      component: "input",
      label: "Card No.",
      name: "card_number",
    },
    {
      component: "input",
      label: "Cardholder Name",
      name: "card_holder_name",
      placeholder: "John Doe",
    },
    {
      component: "input",
      label: "Phone No.",
      name: "phone_number",
      type: "number",
      prefix: "+60",
      placeholder: "123456789",
    },
  ];

  const refundSchema = Yup.object({
    bank_name: Yup.string().when("request_payment_method", {
      is: (value) => value === "bank",
      then: (schema) => schema.required("Bank name is required").nullable(),
      otherwise: (schema) => schema,
    }),
    bank_account_number: Yup.string().when("request_payment_method", {
      is: (value) => value === "bank",
      then: (schema) => schema.required("Account no is required").nullable(),
      otherwise: (schema) => schema,
    }),
    bank_account_name: Yup.string().when("request_payment_method", {
      is: (value) =>
        value === "bank" ||
        value === "tng" ||
        value === "boost" ||
        value === "grabpay",
      then: (schema) => schema.required("Name is required").nullable(),
      otherwise: (schema) => schema,
    }),
    phone_number: Yup.string().when("request_payment_method", {
      is: (value) =>
        value === "tng" || value === "boost" || value === "grabpay",
      then: (schema) =>
        schema
          .required("Phone no is required")
          .matches(
            /^(1)[0-9]{8,9}$/,
            "Enter a valid international phone number (e.g., 123456789)"
          )
          .nullable(),
      otherwise: (schema) => schema,
    }),
    card_number: Yup.string().when("request_payment_method", {
      is: (value) => value === "card",
      then: (schema) => schema.required("Card no is required").nullable(),
      otherwise: (schema) => schema,
    }),
    card_holder_name: Yup.string().when("request_payment_method", {
      is: (value) => value === "card",
      then: (schema) =>
        schema.required("Cardholder name is required").nullable(),
      otherwise: (schema) => schema,
    }),
  });

  const handleCloseRefund = () => {
    dispatch(closeRefundModal());
  };

  const handleSubmit = ({ values, setFieldError }) => {
    dispatch(
      resubmitRefund({
        refund_id: isOpenRefundModal,
        request_payment_method: values.request_payment_method,
        ...(values.request_payment_method === "bank"
          ? {
              bank_name: values.bank_name,
              bank_account_number: values.bank_account_number,
              bank_account_name: values.bank_account_name,
            }
          : values.request_payment_method === "card"
          ? {
              card_number: values.card_number,
              card_holder_name: values.card_holder_name,
            }
          : {
              bank_account_name: values.bank_account_name,
              phone_number: `+60${values.phone_number}`,
            }),
      })
    )
      .unwrap()
      .then((res) => {
        handleCloseRefund();

        dispatch(setStart(0));
        dispatch(setLength(10));
        dispatch(setInitialDone(false));

        dispatch(
          getOrderHistoryList({
            merchant_id: merchantId,
            start: 0,
            length: 10,
          })
        )
          .unwrap()
          .then((res) => {
            dispatch(setData(res.data.records));
            if (data.length < res.data.total_records) {
              dispatch(setHasMore(true));
            }
          })
          .catch((ex) => {});

        toast.success("Manual refund request submitted successful.");
      })
      .catch((ex) => {
        if (ex && ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item][0]);
            });
          }
        }
      });
  };

  return (
    <FullModal
      show={isOpenRefundModal !== null}
      screenSize="xl"
      backButton={handleCloseRefund}
      dialogClassName="--margin"
      contentClassName="--short --white"
      content={
        <>
          <MDBModalBody>
            <article className="receive-point-modal">
              <Formik
                initialValues={refundForm}
                validationSchema={refundSchema}
                enableReinitialize={true}
                onSubmit={(values, { setFieldError }) => {
                  handleSubmit({ values, setFieldError });
                }}
              >
                {({ values, errors, isValid, setFieldValue }) => (
                  <Form className="w-100">
                    <section className="receive-notification">
                      <article className="receive-header">
                        <article className="receive-title">
                          <section className="receive-frame">
                            <LazyLoadImage
                              src={
                                merchantInfo.company_logo.length > 0
                                  ? merchantInfo.company_logo[0].url
                                  : FullLogo
                              }
                            />
                          </section>
                          <section>
                            <h4>Manual Refund Request</h4>
                            <p>{merchantInfo.store_name}</p>
                          </section>
                        </article>
                        <article
                          className="pointer"
                          onClick={() => handleCloseRefund()}
                        >
                          <Icon icon="ic:round-close" />
                        </article>
                      </article>
                      <article className="receive-summary">
                        {refundInput.map(
                          (input, inputIndex) =>
                            (values.request_payment_method === "bank"
                              ? inputIndex < 4
                              : values.request_payment_method === "tng" ||
                                values.request_payment_method === "boost" ||
                                values.request_payment_method === "grabpay"
                              ? inputIndex === 0 ||
                                inputIndex === 3 ||
                                inputIndex === 6
                              : inputIndex === 0 ||
                                (inputIndex > 3 && inputIndex < 6)) && (
                              <article className="flex flex-column gap-1 mb-4">
                                <p>
                                  {input.label === "Account Name"
                                    ? values.request_payment_method !== "bank"
                                      ? "Name"
                                      : input.label
                                    : input.label}
                                </p>
                                <article className="flex items-center gap-3">
                                  {input.component === "input" ? (
                                    <article className="phone-input w-100">
                                      {input.prefix && <p>{input.prefix}</p>}
                                      <input
                                        type={input.type}
                                        name={input.name}
                                        placeholder={input.placeholder}
                                        className={
                                          !input.prefix ? "border-0" : ""
                                        }
                                        onChange={(e) =>
                                          setFieldValue(
                                            input.name,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </article>
                                  ) : (
                                    <Dropdown
                                      value={
                                        input.options?.length > 0
                                          ? input.options.find(
                                              (option) =>
                                                option.value ===
                                                values[input.name]
                                            )?.label
                                          : ""
                                      }
                                      name={input.name}
                                      options={input.options}
                                      className="request-to"
                                    />
                                  )}
                                </article>
                                {errors && errors[input.name] && (
                                  <p className="element _errors text-right no-padding m-0">
                                    {values.request_payment_method === "bank" &&
                                    input.name === "bank_account_name" &&
                                    errors[input.name] === "Name is required"
                                      ? "Account name is required"
                                      : errors[input.name]}
                                  </p>
                                )}
                                {/* <p className="mt-3">Note: Please ensure that you enter the <span>correct phone number</span>, as it can only be submitted once.</p> */}
                              </article>
                            )
                        )}
                        <Button
                          type="submit"
                          disabled={!isValid || refundLoading}
                          className="h-100 flex justify-end"
                          btnClassName="receive-btn --submit"
                        >
                          Submit
                        </Button>
                      </article>
                    </section>
                  </Form>
                )}
              </Formik>
            </article>
          </MDBModalBody>
        </>
      }
    />
  );
}
