import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Icon } from "@iconify/react";

import PageHeader from "../../../components/header/page.header";
import { useDispatch } from "react-redux";
import { openFaqModal } from "../../../redux/reducer/modalReducer";

export default function OrderHeader({ method }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { store } = useParams();
  const { pathname } = useLocation();

  const handleBackToMenu = () => {
    navigate(`/${store}/menu`);
  };

  const handleBackToHome = () => {
    navigate(`/${store}`);
  };

  const handleOpenFAQ = () => {
    dispatch(openFaqModal());
  };

  return (
    <header
      className={`order-header ${method === "dine in" ? "--dine-in" : ""}`}
    >
      <PageHeader
        backIcon="mdi:backburger"
        backTo={handleBackToMenu}
        navIcon="ri:home-smile-line"
        navTo={handleBackToHome}
      />
      <section className="sub-header">
        <article className="flex items-center justify-between">
          <h1>My Order</h1>
          <article
            className="help-button pointer"
            onClick={() => handleOpenFAQ()}
          >
            <p>FAQ</p>
            <article className="help-icon">
              <Icon icon="akar-icons:chat-question" />
            </article>
          </article>
        </article>
        <p>Order History</p>
        <article className="steps mt-4 relative">
          <hr className="step-line" />
          <section className="grid center">
            <article className="step --checked">
              <Icon icon="material-symbols:check" />
            </article>
            <p className="step-text">Select</p>
          </section>
          <section className="grid center">
            <article className="step --checked">
              <Icon icon="material-symbols:check" />
            </article>
            <p className="step-text">Confirm</p>
          </section>
          <section className="grid center">
            <article className="step --checked">
              <Icon icon="material-symbols:check" />
            </article>
            <p className="step-text">Order</p>
          </section>
        </article>
      </section>
    </header>
  );
}
