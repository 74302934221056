import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";

//redux
import { useDispatch, useSelector } from "react-redux";
// import apiServices from "../../services/api.services";
// import { getCartCount, getCartInfo, setLimitedError } from "../../redux/reducer/checkoutReducer";
import apiService from "../../services/api.service";
import {
  getCartCount,
  getCartInfo,
  setUpdateCartId,
} from "../../redux/reducer/cartReducer";
import {
  closePlaceOrderModal,
  openConfirmModal,
} from "../../redux/reducer/modalReducer";
import { getCartId, setConfirmItem } from "../../redux/reducer/commonReducer";
import { useToast } from "../common/toast.provider";

export default function InputCount({
  className,
  quantity,
  setQuantity,
  handleChangeQuantity,
  index,
  food_id,
  promo_id,
  user_promo_id,
  cart_id,
  setId,
  option,
  ticket_id,
  max,
  remark,
  balance,
  cartIndex,
  setQuantityChange,
  update_id,
  initialPrice,
  type,
  quantitySum,
  setTotalPrice,
  disabled,
}) {
  const dispatch = useDispatch();
  const toast = useToast();
  const [number, setNumber] = useState(quantity || 1); //quantity
  const [updateLoading, setUpdateLoading] = useState(false);

  const { deviceId, merchantId, cartId, method } = useSelector(
    (state) => state.common
  );
  const { updateCartId, scheduleDate, scheduleTime } = useSelector(
    (state) => state.cart
  );

  // const { addressList } = useSelector((state) => state.store);
  // const { updateCartLoading } = useSelector((state) => state.checkout);

  // const shipping = localStorage.getItem("shipping");
  // const invitationCode = localStorage.getItem("invitationCode");

  const updateCart = async ({ no, original }) => {
    setNumber(no);

    if (updateCartId === null) {
      if (food_id) {
        dispatch(setUpdateCartId(food_id));
        try {
          // setUpdateLoading(true)
          const response = await apiService.updateCart({
            cart_id: cart_id ? cart_id : "",
            cart_item_id: food_id ? food_id : "",
            remark: remark ? remark : "",
            quantity: no ? no : 0,
            promotion_id: promo_id ?? null,
            do_not_use_any: null,
            option: option,
            user_promotion_bag_id: user_promo_id ?? null,
            ...((method === "take away" || method === "delivery") &&
              scheduleTime?.time && {
                selfpick_mode: scheduleTime?.asap ? "ASAP" : "scheduled",
                ...(!scheduleTime?.asap &&
                  scheduleTime?.time !== "ASAP" && {
                    scheduled_at: `${scheduleDate.full_date} ${scheduleTime["24hr_format"]}:00`,
                  }),
              }),
          });

          if (response) {
            if (setId) {
              setId(food_id);
            }
            dispatch(
              getCartInfo({
                // device_id: deviceId,
                merchant_id: merchantId,
                order_method: method,
              })
            )
              .then((res) => {
                dispatch(setUpdateCartId(null));
                setId("");
                // setUpdateLoading(false)
              })
              .catch((ex) => {
                dispatch(setUpdateCartId(null));
                if (ex && ex.response?.status === 422) {
                  const errors = ex.response.data.errors;
                  if (errors && Object.keys(errors).length > 0) {
                    Object.keys(errors).map((item, i) => {
                      toast.error(errors[item][0]);
                    });
                  }
                }
              });
          }
        } catch (ex) {
          setId("");
          setNumber(original);
          dispatch(setUpdateCartId(null));
          // setUpdateLoading(false);
          if (ex && Object.keys(ex).length > 0) {
            let errorMsg = [];
            if (ex.response?.status === 422) {
              const errors = ex.response.data.errors;
              if (errors && Object.keys(errors).length > 0) {
                Object.keys(errors).map((item, i) => {
                  if (
                    errors?.cart &&
                    errors?.cart ===
                      "There is cart that still in payment processing. "
                  ) {
                    dispatch(openConfirmModal());
                    dispatch(
                      setConfirmItem({
                        type: "cart processing",
                        item: cartId,
                      })
                    );
                  } else {
                    errorMsg = errors[item][0];
                    toast.error(errorMsg);
                  }
                });
              }
            }

            if (ex.response?.status === 404) {
              localStorage.removeItem("cart_id");
              dispatch(getCartId());
              toast.error("Your cart has been outdated. Please refresh.");

              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    setNumber(quantity);
  }, [quantity]);

  return (
    <div className={`element _input-count ${className ? className : ""}`}>
      <div className="count-button">
        <MDBCol className="col-4 pointer">
          {!disabled && (
            <Icon
              onClick={() => {
                if (updateCartId === null) {
                  if (setQuantityChange) {
                    setQuantityChange(true);
                  }

                  if (
                    number !== 1 &&
                    !quantity <= 1 &&
                    type !== "place order"
                  ) {
                    // setNumber(number - 1);
                    updateCart({ no: number - 1, original: number });
                    index == null
                      ? dispatch(setQuantity(number - 1))
                      : dispatch(
                          handleChangeQuantity({
                            index: index,
                            type: "decreases",
                          })
                        );
                  } else if (number !== 1) {
                    if (initialPrice) {
                      setNumber(number - 1);
                      dispatch(setQuantity(number - 1));
                      if (setTotalPrice) {
                        setTotalPrice(null);
                      }
                    }
                  } else if (number === 1 && type !== "place order") {
                    dispatch(closePlaceOrderModal());
                    dispatch(
                      setConfirmItem({
                        type: "remove cart",
                        cart_id: cart_id,
                        cart_item_id: food_id,
                        promotion_id: promo_id,
                        user_promotion_bag_id: user_promo_id,
                        remark: remark,
                      })
                    );
                    dispatch(openConfirmModal());
                  }
                }
              }}
              icon={
                type === "place order"
                  ? "zondicons:minus-solid"
                  : "memory:minus-box"
              }
              className={`count-svg ${
                updateLoading
                  ? "--loading"
                  : type === "place order"
                  ? "--secondary"
                  : "--disable"
              }`}
            />
          )}
        </MDBCol>
        <MDBCol className="col-4 mt-1">
          {/* {updateCartLoading && food_id === update_id ? 
            <Icon
              icon="line-md:loading-twotone-loop"
              width="50px"
              height="50px"
              color="#fdfdff"
              className="ms-3 mb-1"
            />
          : */}
          <label
            className={`count-label
              ${
                ((max !== null && number > max) ||
                  (balance !== undefined && quantitySum >= balance) ||
                  (balance !== undefined && number > balance)) &&
                type === "place order"
                  ? "--loading"
                  : type === "place order"
                  ? "--secondary"
                  : "--disable"
              }`}
          >
            {number}
          </label>
          {/* } */}
        </MDBCol>
        <MDBCol className="col-4 pointer">
          {!disabled && (
            <Icon
              className={`count-svg
            ${
              (max !== null && number >= max) ||
              (balance !== undefined && quantitySum >= balance) ||
              (balance !== undefined && number >= balance) ||
              updateLoading
                ? type === "place order"
                  ? "--loading"
                  : "--cart-loading"
                : type === "place order"
                ? "--secondary"
                : "--disable"
            }`}
              onClick={() => {
                if (updateCartId === null) {
                  if (setQuantityChange) {
                    setQuantityChange(true);
                  }

                  if (max !== null && number === max) {
                    toast.error(`This menu only left ${max} quantity`);
                  } else {
                    if (
                      // number < 99 &&
                      // quantity < 99 &&
                      // max == null &&
                      // balance == undefined &&
                      type !== "place order"
                    ) {
                      if (max !== null && number === max) {
                        toast.error(`This menu only left ${max} quantity`);
                      } else {
                        updateCart({ no: number + 1, original: number });
                        index == null
                          ? dispatch(setQuantity(number + 1))
                          : dispatch(
                              handleChangeQuantity({
                                index: index,
                                type: "increases",
                              })
                            );
                      }
                    }
                    // if (max && number < max && balance == undefined)
                    else {
                      if (initialPrice) {
                        if (setTotalPrice) {
                          setTotalPrice(null);
                        }
                        setNumber(number + 1);
                        dispatch(setQuantity(number + 1));
                      }
                    }
                  }

                  // if (
                  //   balance !== undefined &&
                  //   number < 99 &&
                  //   number < balance &&
                  //   balance > quantitySum &&
                  //   max == null && type !== 'place order'
                  // ) {
                  //   // setNumber(number + 1);
                  //   updateCart({ no: number + 1, original: number });
                  //   if(index == null) {
                  //     dispatch(setQuantity(number + 1))
                  //   } else {
                  //     dispatch(
                  //       handleChangeQuantity({ index: index, type: "increases" })
                  //     );
                  //   }
                  // } else if (max && number < max && balance == undefined) {
                  //   setNumber(number + 1);
                  //   dispatch(setQuantity(number + 1));
                  // } else {
                  //   // dispatch(setLimitedError(true))
                  // }
                }
              }}
              icon={
                type === "place order"
                  ? "zondicons:add-solid"
                  : "memory:plus-box"
              }
            />
          )}
        </MDBCol>
      </div>
    </div>
  );
}
