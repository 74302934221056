import { useNavigate, useParams } from "react-router-dom";

import { Icon } from "@iconify/react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useSelector } from "react-redux";

import PageHeader from "../../../components/header/page.header";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

export default function ReservationHeader() {
  const navigate = useNavigate();
  const { store } = useParams();
  const { width } = useWindowDimensions();

  const { merchantInfo } = useSelector((state) => state.merchant);

  const handleBackToHome = () => {
    navigate(`/${store}`);
  };

  return (
    <>
      <header className="reservation-header">
        <PageHeader
          title={merchantInfo.store_name}
          backIcon="fluent:person-arrow-back-48-regular"
          backTo={handleBackToHome}
          navTo={undefined}
          className="--blue pb-4"
        />
      </header>
      <section className="restaurant-banner">
        <h2>Reserve Your Spot To Skip Lines</h2>
        <article className="banner-frame">
          <img src={merchantInfo.picture} />
        </article>
        <article className="restaurant-info">
          <h3>{merchantInfo.store_name}</h3>
          <section
            className={`info-grid ${width > 550 ? "justify-start gap-5" : ""}`}
          >
            <article className="info-col">
              <Icon icon="mynaui:store" />
              <p>{merchantInfo.store_name}</p>
            </article>
            <article className="info-col gap-2">
              <Icon icon="fluent:call-24-regular" />
              <p>{merchantInfo.contact_no}</p>
            </article>
          </section>
          <section className="info-col">
            <article>
              <Icon icon="carbon:location" />
            </article>
            <p className="two-line">{merchantInfo.display_address}</p>
          </section>
        </article>
      </section>
    </>
  );
}
