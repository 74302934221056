import { Icon } from "@iconify/react";

import { useDispatch, useSelector } from "react-redux";
import {
  openSetAddressModal,
  openStoreInfoModal,
  openTimeModal,
} from "../../../redux/reducer/modalReducer";

export default function MethodInfo({ method, asap }) {
  const dispatch = useDispatch();

  const { username } = useSelector((state) => state.common);
  const { cartAddressInfo } = useSelector((state) => state.address);
  const {
    cartInfo,
    cartInfoLoading,
    updateCartId,
    scheduleDate,
    scheduleTime,
  } = useSelector((state) => state.cart);
  const { merchantInfo, themeColor } = useSelector((state) => state.merchant);

  const handleOpenTime = () => {
    dispatch(openTimeModal());
  };

  const handleOpenSetAddress = () => {
    dispatch(openSetAddressModal());
  };

  return (
    <>
      {method === "delivery" ? (
        <section className="method-info pointer">
          <article className="grid">
            <article className="location-time">
              <article>
                <Icon icon="line-md:map-marker" />
              </article>
              <article
                className="pointer"
                onClick={() => handleOpenSetAddress()}
              >
                <h3 className="mb-1">
                  Delivery Address&nbsp;&nbsp;<span>Change</span>
                </h3>
                {/* {(username && username !== "null") && <p>Phone no. | {username}</p>} */}
                {updateCartId === "address_loading" ? (
                  <Icon
                    icon="eos-icons:three-dots-loading"
                    className="loading-icon"
                    color={themeColor.btn_color}
                  />
                ) : (
                  <p className="two-line">{cartAddressInfo.address}</p>
                )}
              </article>
            </article>
            <article
              className="location-time row-gap-0 pointer"
              onClick={() => handleOpenTime()}
            >
              <Icon icon="line-md:speed-loop" />
              <article className="flex flex-wrap row-gap-0">
                {updateCartId !== "time_loading" ? (
                  <h3 className="mb-0">
                    Deliver on{" "}
                    {scheduleTime.time && !scheduleTime.asap
                      ? `${scheduleDate?.day}, ${scheduleTime?.time} (${scheduleTime?.from_now})`
                      : asap
                      ? `Today, ASAP (Now)`
                      : "-"}
                    <>
                      &nbsp;&nbsp;<span>Change</span>
                    </>
                  </h3>
                ) : (
                  <Icon
                    icon="eos-icons:three-dots-loading"
                    className="loading-icon"
                    color={themeColor.btn_color}
                  />
                )}
              </article>
            </article>
            {/* {cartInfo?.duration_delivery && (
              <article className="location-time">
                <Icon icon="line-md:speed-loop" color="transparent" />
                <article>
                  <p>
                    ETA : {cartInfo.duration_delivery} (
                    {cartInfo.distance_delivery})
                  </p>
                </article>
              </article>
            )} */}
            <article className="location-time mb-0">
              <Icon icon="line-md:alert-circle" />
              <article>
                <p className="mb-0 extra">
                  Orders will be delivered by the merchant
                  <br /> Review address correctly, rider will send exact address
                </p>
              </article>
            </article>
          </article>
          {/* <Icon icon="ion:chevron-right" className="view-icon" /> */}
        </section>
      ) : method === "take away" ? (
        <section
          className="method-info pointer"
          onClick={() => handleOpenTime()}
        >
          <article className="grid">
            <article className="location-time mb-0">
              <article>
                <Icon icon="line-md:map-marker" />
              </article>
              <article>
                <h3>{merchantInfo.store_name}</h3>
                <p>
                  {merchantInfo.pic_name} | {merchantInfo.whatsapp_number}
                </p>
                <p className="two-line">{merchantInfo.display_address}</p>
              </article>
            </article>
            <article className="location-time row-gap-0">
              <Icon icon="line-md:speed-loop" />
              <article className="flex flex-wrap row-gap-0">
                {updateCartId !== "time_loading" ? (
                  <h3 className="mb-0">
                    Pick up on{" "}
                    {scheduleTime.time && !scheduleTime.asap
                      ? `${scheduleDate?.day}, ${scheduleTime?.time} (${scheduleTime?.from_now})`
                      : asap
                      ? `Today, ASAP (Now)`
                      : "-"}
                    <>
                      &nbsp;&nbsp;<span>Change</span>
                    </>
                  </h3>
                ) : (
                  <Icon
                    icon="eos-icons:three-dots-loading"
                    className="loading-icon"
                    color={themeColor.btn_color}
                  />
                )}
                {/* <p className="--change">Change</p> */}
              </article>
            </article>
          </article>
          {/* <article>
              <Icon icon="ion:chevron-right" className="view-icon" />
            </article> */}
        </section>
      ) : (
        ""
      )}
    </>
  );
}
