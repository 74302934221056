export default function scrollToTop(modal) {
  const contentContainer = document.querySelector(".content-container");

  if (modal) {
    modal.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  } else {
    if (window.innerWidth > 450 && contentContainer) {
      contentContainer.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
}
