import { useNavigate, useParams } from "react-router-dom";

import { Icon } from "@iconify/react";
import { Skeleton } from "primereact/skeleton";

import { useToast } from "../../../components/common/toast.provider";

import { useDispatch, useSelector } from "react-redux";
import { openPrizeModal } from "../../../redux/reducer/modalReducer";

import TheWheel from "./the.wheel";
import Button from "../../../components/element/button";
import PageHeader from "../../../components/header/page.header";

export default function SpinWheel() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { store } = useParams();

  const { spinPeriod, spinToken, spinWheelLoading, wheelList } = useSelector(
    (state) => state.spin_to_win
  );

  const handleBackToHome = () => {
    navigate(`/${store}`);
  };

  const handleOpenPrize = () => {
    if (wheelList.length > 0) {
      dispatch(openPrizeModal());
    } else {
      toast.error(`Merchant didn't setup any spin wheel prizes`);
    }
  };

  return (
    <>
      <header className="spin-header">
        <PageHeader
          title="Spin & Win"
          endsIn={spinPeriod.end_at ? spinPeriod.end_at.split(" ")[0] : null}
          backIcon="fluent:person-arrow-back-48-regular"
          backTo={handleBackToHome}
          navTo={undefined}
          className="--blue pb-4"
        />
      </header>
      <section className="spin-wheel">
        <article className="w-100 flex justify-end">
          {!spinWheelLoading ? (
            <Button
              variant="outline"
              btnClassName="prize-detail"
              onClick={() => handleOpenPrize()}
            >
              <Icon icon="emojione:wrapped-gift" />
              Prize Details
            </Button>
          ) : (
            <Skeleton width="128px" height="36px" borderRadius="8px" />
          )}
        </article>
        <TheWheel />
        <article className="token">
          <Icon icon="ic:outline-generating-tokens" />
          <h5>Token : {spinWheelLoading ? 0 : spinToken?.remaining_token}</h5>
        </article>
      </section>
    </>
  );
}
