import { useEffect, useRef } from "react";

import { Icon } from "@iconify/react";
import { MDBModalBody } from "mdb-react-ui-kit";

import { useToast } from "../../../components/common/toast.provider";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import {
  getCartId,
  setConfirmItem,
} from "../../../redux/reducer/commonReducer";
import {
  closeTimeModal,
  openConfirmModal,
} from "../../../redux/reducer/modalReducer";

import HalfModal from "../../../components/modal/half.modal.box";
import ModalHeader from "../../../components/header/modal.header";

import apiService from "../../../services/api.service";
import {
  getCartInfo,
  setScheduleDate,
  setScheduleTime,
  setUpdateCartId,
} from "../../../redux/reducer/cartReducer";

export default function SelectTime() {
  const toast = useToast();
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const { realWidth, width } = useWindowDimensions();

  const { method, cartId, merchantId } = useSelector((state) => state.common);
  const { cartInfo, scheduleDate, scheduleTime, deliveryTime, pickUpTime } =
    useSelector((state) => state.cart);
  const { isOpenTimeModal } = useSelector((state) => state.modal);
  const { merchantInfo } = useSelector((state) => state.merchant);
  const { cartAddressInfo } = useSelector((state) => state.address);

  const handleCloseTimeModal = () => {
    if (
      method === "delivery" &&
      cartId &&
      deliveryTime?.length > 0 &&
      deliveryTime[0] !== "ASAP" &&
      !scheduleTime.time
    ) {
      toast.error(
        `Delivery now is not available. Please choose a scheduled delivery time to proceed.`
      );

      return;
    }

    if (
      method === "take away" &&
      cartId &&
      pickUpTime?.length > 0 &&
      pickUpTime[0] !== "ASAP" &&
      !scheduleTime.time
    ) {
      toast.error(
        `Pick up now is not available. Please choose a scheduled self pick time to proceed.`
      );

      return;
    }

    dispatch(closeTimeModal());
  };

  const handleChooseDate = (date) => {
    dispatch(setScheduleDate(date));
    dispatch(setScheduleTime({}));
  };

  const handleChooseTime = async (time) => {
    dispatch(closeTimeModal());

    if (cartId && cartInfo !== undefined) {
      dispatch(setUpdateCartId("time_loading"));

      try {
        const response = await apiService.updateCart({
          cart_id: cartId,
          do_not_use_any: null,
          promotion_id: cartInfo.promotion_id ?? null,
          user_promotion_bag_id: cartInfo.user_promotion_bag_id ?? null,
          ...(method === "delivery" && {
            address_id: cartAddressInfo.id,
          }),
          ...((method === "take away" || method === "delivery") &&
            time?.time && {
              selfpick_mode: time?.asap ? "ASAP" : "scheduled",
              ...(!time?.asap &&
                time?.time !== "ASAP" && {
                  scheduled_at: `${scheduleDate.full_date} ${time["24hr_format"]}:00`,
                }),
            }),
        });

        if (response) {
          dispatch(
            getCartInfo({
              order_method: method,
              merchant_id: merchantId,
            })
          )
            .then((res) => {
              dispatch(setScheduleTime(time));
              dispatch(setUpdateCartId(null));
            })
            .catch((ex) => {
              dispatch(setUpdateCartId(null));
              if (ex && ex.response?.status === 422) {
                const errors = ex.response.data.errors;
                if (errors && Object.keys(errors).length > 0) {
                  Object.keys(errors).map((item, i) => {
                    toast.error(errors[item][0]);
                  });
                }
              }
            });
        }
      } catch (ex) {
        dispatch(setUpdateCartId(null));
        if (ex && Object.keys(ex).length > 0) {
          let errorMsg = [];
          if (ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                if (
                  errors[item][0] ===
                  "There is cart that still in payment processing. "
                ) {
                  dispatch(openConfirmModal());
                  dispatch(
                    setConfirmItem({
                      type: "cart processing",
                      item: cartId,
                    })
                  );
                } else {
                  errorMsg = errors[item][0];
                  toast.error(errorMsg);
                }
              });
            }
          }

          if (ex.response?.status === 404) {
            localStorage.removeItem("cart_id");
            dispatch(getCartId());
            toast.error("Your cart has been outdated. Please refresh.");

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      }
    } else {
      dispatch(setScheduleTime(time));
    }
  };

  function generateTimeSlot(timeSlot) {
    const now = new Date();

    const getTimeFromNow = (time) => {
      const deliveryDate = new Date(time);
      const timeDiffInMinutes = Math.floor((deliveryDate - now) / (1000 * 60));

      const hours = Math.floor(timeDiffInMinutes / 60);
      const minutes = timeDiffInMinutes % 60;

      return timeDiffInMinutes > 0
        ? `${hours > 0 ? `${hours} hrs ` : ""}${
            minutes > 0 ? `${minutes} mins` : ""
          }`
        : "Now";
    };

    const timeSlotList = timeSlot.reduce((acc, timeStr) => {
      let date, time;
      if (timeStr === "ASAP") {
        const todayDate = now.getDate();
        let todayEntry = acc.find((item) => item.day === "Today");

        const asapEntry = {
          time: "ASAP",
          from_now: "Now",
          "24hr_format": "ASAP",
          asap: true,
        };

        if (!todayEntry) {
          todayEntry = {
            day: "Today",
            date: todayDate,
            full_date: now.toISOString().split("T")[0],
            times: [],
          };
          acc.push(todayEntry);
        }

        todayEntry.times.unshift(asapEntry);
      } else {
        date = timeStr.split(" ")[0];
        time = timeStr.split(" ")[1];

        const fullDate = new Date(timeStr);
        const dayLabel =
          fullDate.toDateString() === now.toDateString()
            ? "Today"
            : fullDate.toLocaleString("en-US", { weekday: "short" });

        const formattedTime = fullDate.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        const fromNow = getTimeFromNow(timeStr);

        const timeEntry = {
          time: formattedTime,
          from_now: fromNow,
          "24hr_format": time.substring(0, 5),
          asap: false,
        };

        let existingDay = acc.find((item) => item.full_date === date);

        if (!existingDay) {
          existingDay = {
            day: dayLabel,
            date: fullDate.getDate(),
            full_date: date,
            times: [],
          };
          acc.push(existingDay);
        }

        existingDay.times.push(timeEntry);
      }

      return acc;
    }, []);

    const todayEntry = timeSlotList.find((item) => item.day === "Today");
    if (!todayEntry) {
      timeSlotList.unshift({
        day: "Today",
        date: now.getDate(),
        full_date: now.toISOString().split("T")[0],
        times: [],
      });
    }

    return timeSlotList;
  }

  const deliveryTimeList = generateTimeSlot(deliveryTime);
  const pickUpTimeList = generateTimeSlot(pickUpTime);

  useEffect(() => {
    if (pickUpTimeList?.length > 0 && !scheduleDate?.date) {
      dispatch(setScheduleDate(pickUpTimeList[0]));
    }
  }, [pickUpTimeList?.length, scheduleDate]);

  return (
    <>
      <HalfModal
        className="select-time"
        show={isOpenTimeModal}
        backButton={handleCloseTimeModal}
        type={realWidth >= 450 ? "mobile" : ""}
        desktopModal={width >= 991 ? true : false}
        screenSize={width >= 991 ? "xl" : ""}
        content={
          <>
            <ModalHeader
              title={`${
                method === "delivery" ? "Delivery" : "Pick Up"
              } Details`}
              backTo={
                (method === "delivery" &&
                  cartId &&
                  deliveryTime?.length > 0 &&
                  deliveryTime[0] !== "ASAP" &&
                  !scheduleTime.time) ||
                (method === "take away" &&
                  cartId &&
                  pickUpTime?.length > 0 &&
                  pickUpTime[0] !== "ASAP" &&
                  !scheduleTime.time)
                  ? undefined
                  : handleCloseTimeModal
              }
              backToNoAnimation={
                (method === "delivery" &&
                  deliveryTime?.length > 0 &&
                  deliveryTime[0] !== "ASAP" &&
                  !scheduleTime.time) ||
                (method === "take away" &&
                  pickUpTime?.length > 0 &&
                  pickUpTime[0] !== "ASAP" &&
                  !scheduleTime.time)
                  ? undefined
                  : handleCloseTimeModal
              }
              type={`model2 ${realWidth < 450 ? "half" : ""}`}
            />
            <MDBModalBody ref={modalRef} className="fixed-body p-0">
              <article className="select-time-modal">
                {((cartAddressInfo && method === "delivery") ||
                  method === "take away") && (
                  <article className="pickup-info">
                    <article className="flex items-end gap-3">
                      <article>
                        <Icon icon="clarity:store-solid" />
                      </article>
                      <h6>
                        {method === "delivery"
                          ? cartAddressInfo.name
                          : merchantInfo.store_name}
                      </h6>
                    </article>
                    <article className="flex items-start gap-3">
                      <article>
                        <Icon
                          icon="hugeicons:location-04"
                          color="transparent"
                        />
                      </article>
                      <p>
                        {method === "delivery"
                          ? cartAddressInfo.address
                          : merchantInfo.display_address}
                      </p>
                    </article>
                  </article>
                )}
                <article className="select-date-time">
                  <section className="select-date">
                    {(method === "take away"
                      ? pickUpTimeList
                      : deliveryTimeList
                    )?.length > 0 &&
                      (method === "take away"
                        ? pickUpTimeList
                        : deliveryTimeList
                      ).map((tDay, tDayIndex) => (
                        <article
                          onClick={() => handleChooseDate(tDay)}
                          className={`date ${
                            scheduleDate?.full_date === tDay.full_date
                              ? "--active"
                              : ""
                          } pointer`}
                          index={tDayIndex}
                        >
                          <p>{tDay.day}</p>
                          <article className="date-bg">
                            <h5>{tDay.date}</h5>
                          </article>
                        </article>
                      ))}
                  </section>
                  <section className="select-time">
                    <h6>
                      Choose {method === "delivery" ? "Delivery" : "Pick Up"}{" "}
                      Time :{" "}
                    </h6>
                    <article className="time-list">
                      {(method === "take away"
                        ? pickUpTimeList
                        : deliveryTimeList
                      )?.length > 0 &&
                        (method === "take away"
                          ? pickUpTimeList
                          : deliveryTimeList
                        )
                          .filter(
                            (pTime) =>
                              scheduleDate?.full_date === pTime.full_date
                          )
                          .map(
                            (pTime) =>
                              pTime.times?.length > 0 &&
                              pTime.times.map((times, timesIndex) => (
                                <article
                                  className="time pointer"
                                  key={timesIndex}
                                  onClick={() => handleChooseTime(times)}
                                >
                                  <h5>{times.time}</h5>
                                  <article className="flex items-center gap-2">
                                    {/* {method !== 'delivery' && <span>{timesIndex === 0 && pTime.day === 'Today' ? 'ASAP' : ''}</span>} */}
                                    {((scheduleDate?.full_date ===
                                      pTime.full_date &&
                                      scheduleTime.time === times.time) ||
                                      ((!scheduleDate.full_date ||
                                        !scheduleTime.time) &&
                                        times.asap)) && (
                                      <article>
                                        <Icon
                                          icon="charm:tick"
                                          className="mb-1"
                                        />
                                      </article>
                                    )}
                                  </article>
                                </article>
                              ))
                          )}
                    </article>
                  </section>
                </article>
              </article>
            </MDBModalBody>
          </>
        }
      />
    </>
  );
}

// function generatePickUpTimeList(operationHours, afterDurationMinutes, days) {
//   const interval = 30;
//   let firstTimeSlotFound = false;

//   return days.map((day, dayIndex) => {
//     let availableTimes = [];
//     const currentTime = new Date();

//     const earliestTime = new Date(
//       currentTime.getTime() + afterDurationMinutes * 60000
//     );

//     if(operationHours?.length > 0) {
//       operationHours.forEach((period) => {
//         let [start, end] = period.split("-");
//         let startDateTime = new Date(`${day.full_date}T${start}:00`);
//         let endDateTime = new Date(`${day.full_date}T${end}:00`);

//         if (endDateTime <= startDateTime) {
//           endDateTime.setDate(endDateTime.getDate() + 1);
//         }

//         let currentSlot;

//         if (earliestTime >= startDateTime && earliestTime <= endDateTime) {
//           currentSlot = new Date(earliestTime);
//           currentSlot.setMinutes(
//             Math.ceil(currentSlot.getMinutes() / interval) * interval,
//             0,
//             0
//           );
//         } else if (earliestTime < startDateTime) {
//           currentSlot = new Date(
//             startDateTime.getTime() + afterDurationMinutes * 60000
//           );
//           currentSlot.setMinutes(
//             Math.ceil(currentSlot.getMinutes() / interval) * interval,
//             0,
//             0
//           );
//         } else {
//           return;
//         }

//         while (currentSlot <= endDateTime) {
//           const timeString = currentSlot.toLocaleTimeString("en-US", {
//             hour: "2-digit",
//             minute: "2-digit",
//           });
//           const time24HrFormat = currentSlot.toTimeString().slice(0, 5);
//           const fromNowMinutes = Math.round(
//             (currentSlot - currentTime) / 60000
//           );
//           const fromNowText =
//             fromNowMinutes >= 60
//               ? `${Math.floor(fromNowMinutes / 60)} hrs ${
//                   fromNowMinutes % 60
//                 } mins`
//               : `${fromNowMinutes} mins`;

//           if (
//             dayIndex === 0 &&
//             time24HrFormat === "00:00" &&
//             currentTime.getHours() >= 3
//           ) {
//             currentSlot = new Date(currentSlot.getTime() + interval * 60000);
//             continue;
//           }

//           availableTimes.push({
//             time: timeString,
//             from_now: fromNowText,
//             "24hr_format": time24HrFormat,
//             asap: !firstTimeSlotFound && dayIndex === 0,
//           });

//           if (!firstTimeSlotFound && dayIndex === 0) {
//             firstTimeSlotFound = true;
//           }

//           currentSlot = new Date(currentSlot.getTime() + interval * 60000);
//         }
//       });
//     }

//     // availableTimes = availableTimes.sort((a, b) => {
//     //   let timeA = new Date(${day.full_date} ${a["24hr_format"]}:00);
//     //   let timeB = new Date(${day.full_date} ${b["24hr_format"]}:00);
//     //   return timeA - timeB;
//     // });

//     // if (
//     //   dayIndex > 0 &&
//     //   availableTimes.length > 0 &&
//     //   availableTimes[0]["24hr_format"] === "00:00"
//     // ) {
//     //   availableTimes.shift();
//     //   availableTimes.push({
//     //     time: "12:00 AM",
//     //     from_now: "1 day 0 hrs",
//     //     "24hr_format": "00:00",
//     //     asap: false,
//     //   });
//     // }
//     availableTimes = availableTimes.sort((a, b) => {
//       let timeA = new Date(`${day.full_date} ${a["24hr_format"]}:00`);
//       let timeB = new Date(`${day.full_date} ${b["24hr_format"]}:00`);

//       if (
//         a["24hr_format"] === "00:00" ||
//         parseInt(a["24hr_format"].split(":")[0]) < 6
//       ) {
//         timeA.setDate(timeA.getDate() + 1);
//       }
//       if (
//         b["24hr_format"] === "00:00" ||
//         parseInt(b["24hr_format"].split(":")[0]) < 6
//       ) {
//         timeB.setDate(timeB.getDate() + 1);
//       }

//       return timeA - timeB;
//     });

//     return {
//       day: day.day,
//       date: day.date,
//       full_date: day.full_date,
//       times: availableTimes,
//     };
//   });
// }
