import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useWindowDimensions from "../hook/use.window.dimensions";
import {
  closePromotionDetailsModal,
  openConfirmModal,
  openLoginModal,
  openMarketModal,
  openPrizeModal,
  openPromotionModal,
  openReceiptModal,
  openTopUpModal,
  openVoucherModal,
} from "../../redux/reducer/modalReducer";
import FullModal from "../modal/full.modal.box";
import ModalHeader from "../header/modal.header";
import { MDBModalBody } from "mdb-react-ui-kit";
import Coupon from "./coupon";
import Button from "../element/button";
import {
  claimPromotion,
  getPromotionCount,
  getPromotionList,
  setIsBuyingPromo,
  setIsViewingPromo,
} from "../../redux/reducer/homeReducer";
import {
  getPromotionInfo,
  setConfirmItem,
} from "../../redux/reducer/commonReducer";
import { Icon } from "@iconify/react";
import { useToast } from "./toast.provider";
import { RWebShare } from "react-web-share";
import { formatNumber } from "./../common/return.number";

export default function PromoDetails({ setLoginType }) {
  const toast = useToast();
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { pathname } = useLocation();
  const { store } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const [claimLoading, setClaimLoading] = useState(false);

  const { promoInfoLoading, promoInfo, login, merchantId } = useSelector(
    (state) => state.common
  );
  const { isBuyingPromo, isViewingPromo, pointBalance } = useSelector(
    (state) => state.home
  );
  const {
    isOpenPromotionDetailsModal,
    isClaimed,
    isInBag,
    isInReceipt,
    showClaimButton,
  } = useSelector((state) => state.modal);

  const handleBuyPromotion = (type, id, price) => {
    dispatch(closePromotionDetailsModal());

    if (login === "full") {
      dispatch(
        setConfirmItem({
          item_type: type,
          type: "buy promotion",
          costing: price,
          item: id,
        })
      );
      dispatch(openConfirmModal());
    } else {
      setLoginType("full-promo");
      dispatch(openLoginModal());
    }
  };

  const handleClaimVoucher = async (id) => {
    if (login === "full") {
      setClaimLoading(true);
      dispatch(
        claimPromotion({
          id: id,
        })
      )
        .unwrap()
        .then((res) => {
          dispatch(
            getPromotionInfo({
              id: id,
            })
          )
            .unwrap()
            .then((res) => {
              setClaimLoading(false);
              toast.success(
                "Claim voucher successful. Please use it before the expiration."
              );
            })
            .catch((ex) => {});
          dispatch(
            getPromotionList({
              merchant_id: merchantId,
            })
          );
          dispatch(
            getPromotionCount({
              merchant_id: merchantId,
            })
          );
        })
        .catch((ex) => {
          setClaimLoading(false);
          if (ex && Object.keys(ex).length > 0) {
            let errorMsg = [];
            if (ex.response?.status === 422) {
              const errors = ex.response.data.errors;
              if (errors && Object.keys(errors).length > 0) {
                Object.keys(errors).map((item, i) => {
                  errorMsg = errors[item][0];
                  toast.error(errorMsg);
                });
              }
            }
          }
        });
    } else {
      dispatch(closePromotionDetailsModal());
      setLoginType("full-promo-detail");
      dispatch(openLoginModal());
    }
  };

  const handleClosePromoDetails = () => {
    dispatch(closePromotionDetailsModal());

    if (isInBag) {
      dispatch(openPromotionModal());
    }

    if (isViewingPromo) {
      dispatch(openTopUpModal());
      dispatch(setIsViewingPromo(false));
    }

    if (isBuyingPromo) {
      dispatch(openMarketModal());
      dispatch(setIsBuyingPromo(false));
    }

    if (
      !isClaimed &&
      (pathname === `/${encodeURIComponent(store?.trim())}/spin-wheel` ||
        pathname === `/${encodeURIComponent(store?.trim())}/check-in`)
    ) {
      dispatch(openPrizeModal());
    }

    if (isInReceipt) {
      dispatch(openReceiptModal());
    }

    if (pathname === `/${encodeURIComponent(store?.trim())}/cart`) {
      dispatch(openVoucherModal());
    }
  };

  const convertTime = (time) => {
    return time.slice(0, 5);
  };

  useEffect(() => {
    setSearchParams("");
  }, []);

  return (
    <FullModal
      centered
      scrollable
      staticBackdrop={true}
      show={isOpenPromotionDetailsModal}
      backButton={handleClosePromoDetails}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      contentClassName="--white"
      content={
        <>
          <ModalHeader
            title={isBuyingPromo ? "Redeem Voucher" : "Voucher Details"}
            backTo={handleClosePromoDetails}
            backToNoAnimation={handleClosePromoDetails}
            type="model2"
          />
          <MDBModalBody className="fixed-body" ref={modalRef}>
            <article className="details-modal">
              <section className="curve-bg">
                <article className="promo-coupon">
                  <Coupon
                    tnc={false}
                    promoInfo={promoInfo}
                    textClassName="--details"
                  />
                </article>
              </section>
              {!promoInfoLoading ? (
                <section className="details-info">
                  {promoInfo.activate_start_at && promoInfo.activate_end_at && (
                    <article>
                      <h3>Promotion Period</h3>
                      <p>
                        {promoInfo.activate_start_at} ~{" "}
                        {promoInfo.activate_end_at}
                      </p>
                    </article>
                  )}
                  {promoInfo.activate_at && (
                    <article>
                      <h3>Usable Day</h3>
                      <p className="text-capitalize">{promoInfo.activate_at}</p>
                    </article>
                  )}
                  {promoInfo.start_at && promoInfo.end_at && (
                    <article>
                      <h3>Usable Time</h3>
                      <p className="text-capitalize">
                        {convertTime(promoInfo.start_at)} ~{" "}
                        {convertTime(promoInfo.end_at)}
                      </p>
                    </article>
                  )}
                  {isClaimed && (
                    <article className="text-capitalize">
                      <h3>Obtained From</h3>
                      <p>
                        {promoInfo.applicable_type === "order" ||
                        promoInfo.applicable_type === "order item" ||
                        promoInfo.applicable_type === "AI"
                          ? "Free Voucher"
                          : promoInfo.applicable_type}
                      </p>
                    </article>
                  )}
                  <article>
                    <h3>Description</h3>
                    <p>{promoInfo.description}</p>
                  </article>
                  {promoInfo.minimum_subtotal && !promoInfo.menu_name && (
                    <article>
                      <h3>Min. Spend</h3>
                      <p>
                        RM {parseFloat(promoInfo.minimum_subtotal).toFixed(2)}
                      </p>
                    </article>
                  )}
                  {promoInfo.menu_name && (
                    <article>
                      <h3>Requirement</h3>
                      <p>
                        Purchase at least {promoInfo.menu_qty}{" "}
                        {promoInfo.menu_name}
                      </p>
                    </article>
                  )}
                  {(promoInfo.free_menu_qty ||
                    promoInfo.free_promotion?.title ||
                    promoInfo.amount_value > 0) && (
                    <article>
                      <h3>Reward</h3>
                      {promoInfo.amount_value > 0 ? (
                        <p>
                          {promoInfo.amount_type === "amount"
                            ? `RM ${parseFloat(promoInfo.amount_value).toFixed(
                                2
                              )} off`
                            : `${parseInt(promoInfo.amount_value).toFixed(
                                0
                              )}% off, max capped at RM ${parseFloat(
                                promoInfo.max_amount_capped_at
                              ).toFixed(2)}`}
                        </p>
                      ) : (
                        <p>
                          x{promoInfo.free_menu_qty || 1}{" "}
                          {promoInfo.free_menu_name ||
                            promoInfo.free_promotion.title}{" "}
                          {promoInfo.free_promotion?.title && "Voucher"}
                        </p>
                      )}
                    </article>
                  )}
                  {promoInfo.method?.length > 0 && (
                    <article>
                      <h3>Method</h3>
                      <p>
                        {promoInfo.method?.length === 3 ? "All methods (" : ""}
                        {promoInfo.method?.map((method, methodIndex) => (
                          <span className="text-capitalize" key={methodIndex}>
                            {method}
                            {methodIndex !== promoInfo.method.length - 1 && (
                              <span>,&nbsp;</span>
                            )}
                          </span>
                        ))}
                        {promoInfo.method?.length === 3 ? ")" : ""}
                      </p>
                    </article>
                  )}
                  <article>
                    <h3>Terms & Conditions</h3>
                    <div dangerouslySetInnerHTML={{ __html: promoInfo.tnc }} />
                  </article>
                </section>
              ) : (
                <article className="general-loading mt-5">
                  <Icon icon="svg-spinners:12-dots-scale-rotate" />
                </article>
              )}
            </article>
          </MDBModalBody>
          {((isBuyingPromo && login === "full" && pointBalance > 0) ||
            (isBuyingPromo && login !== "full") ||
            !isBuyingPromo) && (
            <section className="model2-summary --fixed">
              {isBuyingPromo ? (
                <Button
                  onClick={() =>
                    handleBuyPromotion(
                      promoInfo.type,
                      promoInfo.id,
                      promoInfo.costing
                    )
                  }
                  className="w-100"
                  disabled={promoInfoLoading}
                >
                  {login === "full" && isBuyingPromo
                    ? `Redeem with ${formatNumber(promoInfo.costing)} Points`
                    : "Login to redeem"}
                </Button>
              ) : showClaimButton ? (
                <Button
                  disabled={
                    promoInfo.claimed_status === "claimed" || claimLoading
                  }
                  onClick={() => {
                    handleClaimVoucher(promoInfo.id);
                  }}
                  className="w-100"
                  // disabled={promoInfoLoading}
                >
                  {login === "full"
                    ? promoInfo.claimed_status === "claimed"
                      ? "Claimed"
                      : "Claim"
                    : "Login to claim"}
                </Button>
              ) : (
                <RWebShare
                  data={{
                    text: "I've just redeemed a great offer. Don't miss your chance to do the same! Click this link:",
                    url: `${process.env.REACT_APP_URL}/${store}?promotion=${promoInfo.id}`,
                    title: "Invite your friends to enjoy this deal!",
                  }}
                  onClick={() => toast.success("Shared promotion successful.")}
                >
                  <Button className="w-100">Share</Button>
                </RWebShare>
              )}
              {showClaimButton && (
                <RWebShare
                  data={{
                    text: "I've just redeemed a great offer. Don't miss your chance to do the same! Click this link:",
                    url: `${process.env.REACT_APP_URL}/${store}?promotion=${promoInfo.id}`,
                    title: "Invite your friends to enjoy this deal!",
                  }}
                  onClick={() => toast.success("Shared promotion successful.")}
                >
                  <Button
                    type="button"
                    btnClassName={`share-btn ${
                      promoInfo.claimed_status === "claimed" ? "--claimed" : ""
                    }`}
                  >
                    <Icon icon="gridicons:share-ios" />
                  </Button>
                </RWebShare>
              )}
            </section>
          )}
        </>
      }
    />
  );
}
