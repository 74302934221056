import { useRef } from "react";
import { useToast } from "./toast.provider";
import { useDispatch, useSelector } from "react-redux";

import { QrReader } from "react-qr-reader";
import { MDBModalBody } from "mdb-react-ui-kit";

import ModalHeader from "../header/modal.header";
import FullModal from "../modal/full.modal.box";

import { closeScanQRModal } from "../../redux/reducer/modalReducer";
import { Navigate, useNavigate, useParams } from "react-router-dom";

export default function ScanQR() {
  const toast = useToast();
  const modalRef = useRef(null);
  const { store } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isOpenScanQRModal } = useSelector((state) => state.modal);

  const handleScanQR = (text) => {
    // http://localhost:3001/domecafe/?table=111&session_no=1718952579_M00033_111
    // https://localhost:3000/domecafe/

    let newUrl = "";

    if (text.includes("?")) {
      const [baseUrl, queryParams] = text.split("?");
      newUrl = `${baseUrl}/menu?${queryParams}`;
    } else {
      newUrl = text;
    }

    window.location.href = newUrl;
    toast.success("Scan QR successful.");
  };

  const handleCloseScanQRModal = () => {
    dispatch(closeScanQRModal());
  };

  return (
    <FullModal
      show={isOpenScanQRModal}
      backButton={handleCloseScanQRModal}
      dialogClassName="--margin"
      contentClassName="--short --white"
      screenSize="xl"
      content={
        <>
          <ModalHeader
            title="Scan Table QR"
            backTo={handleCloseScanQRModal}
            backToNoAnimation={handleCloseScanQRModal}
            type="model2 scan"
          />
          <MDBModalBody ref={modalRef}>
            <article className="scan-qr-modal">
              <section className="scanner-frame">
                <article className="corner top-left-corner"></article>
                <article className="corner top-right-corner"></article>
                <article className="corner bottom-left-corner"></article>
                <article className="corner bottom-right-corner"></article>
                <QrReader
                  scanDelay={500}
                  containerStyle={{ width: 300, height: 300 }}
                  constraints={{
                    facingMode: "environment",
                    aspectRatio: { ideal: 1 },
                  }}
                  onResult={(result, error) => {
                    if (result) {
                      handleScanQR(result.text);
                    }

                    if (
                      error &&
                      error.toString().includes("Permission denied")
                    ) {
                      toast.error(`${error}`);
                    }
                  }}
                  style={{ width: "100%" }}
                  showViewFinder={true}
                />
              </section>
            </article>
          </MDBModalBody>
        </>
      }
    />
  );
}
