import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Rate1,
  Rate1GIF,
  Rate2,
  Rate2GIF,
  Rate3,
  Rate3GIF,
  Rate4,
  Rate4GIF,
  Rate5,
  Rate5GIF,
} from "../../../assets/images";

import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { MDBModalBody } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useToast } from "../../../components/common/toast.provider";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import {
  closeFeedbackModal,
  openLoginModal,
} from "../../../redux/reducer/modalReducer";

import Button from "../../../components/element/button";
import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";

import apiService from "../../../services/api.service";

export default function Feedback({ setLoginType }) {
  const toast = useToast();
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const { login, merchantId } = useSelector((state) => state.common);
  const { isOpenFeedbackModal } = useSelector((state) => state.modal);

  const [selectedRating, setSelectedRating] = useState("");
  const [selectedTopics, setSelectedTopics] = useState([]);

  const feedbackForm = {
    text: "",
    rating: "",
    topic: [],
  };

  const feedbackSchema = Yup.object({
    rating: Yup.string().required(),
    topic: Yup.array()
      .min(1, "At least one topic must be selected")
      .required("At least one topic must be selected"),
    text: Yup.string().when("topic", {
      is: (value) => value.includes("Others (Please specify)"),
      then: (schema) =>
        schema.required("Feedback content is required if 'Others' is chosen."),
      otherwise: (schema) => schema,
    }),
  });

  const ratingOption = [
    { value: 1, image: Rate1, gif: Rate1GIF },
    { value: 2, image: Rate2, gif: Rate2GIF },
    { value: 3, image: Rate3, gif: Rate3GIF },
    { value: 4, image: Rate4, gif: Rate4GIF },
    { value: 5, image: Rate5, gif: Rate5GIF },
  ];

  const topicOption = [
    "Overall Experience",
    "Online Ordering Experience",
    "Delivery Service",
    "Service Experience",
    "Menu Variety",
    "Food Quality",
    "Value for Money",
    "Ambiance",
    "Others (Please specify)",
  ];

  const handleTopicSelect = ({ setFieldValue, topic }) => {
    if (selectedTopics.includes(topic)) {
      const updatedTopics = selectedTopics.filter(
        (selectedTopic) => selectedTopic !== topic
      );
      setSelectedTopics(updatedTopics);
      setFieldValue("topic", updatedTopics);
    } else {
      const updatedTopics = [...selectedTopics, topic];
      setSelectedTopics(updatedTopics);
      setFieldValue("topic", updatedTopics);
    }
  };

  const handleRatingSelect = ({ setFieldValue }, value) => {
    setSelectedRating(value);
    setFieldValue("rating", value);
  };

  const handleSubmit = async ({ values, setFieldValue }) => {
    if (login === "partial" || login === "full") {
      const selectedTopics = values.topic.filter(
        (topic) => topic !== "Others (Please specify)"
      );
      const topicText =
        selectedTopics.length > 0 ? selectedTopics.join(". ") : "";
      const feedbackText =
        values.text !== ""
          ? `${topicText}. Others (Please specify: ${values.text})`
          : topicText;

      try {
        const response = await apiService.sendFeedback({
          text: feedbackText,
          rating: values.rating,
          merchant_id: merchantId,
        });

        if (response) {
          toast.success("Send feedback successful.");
          handleCloseFeedback();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          let errorMsg = [];
          if (ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                errorMsg = errors[item][0];
                toast.error(errorMsg);
              });
            }
          }
        }
      }
    } else {
      handleCloseFeedback();
      setLoginType("feedback");
      dispatch(openLoginModal());
    }
  };

  const handleCloseFeedback = () => {
    dispatch(closeFeedbackModal());
  };

  return (
    <FullModal
      show={isOpenFeedbackModal}
      backButton={handleCloseFeedback}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      contentClassName="--white"
      content={
        <>
          <ModalHeader
            title="Help Us Improve"
            backTo={handleCloseFeedback}
            backToNoAnimation={handleCloseFeedback}
            type="model2"
          />
          <Formik
            initialValues={feedbackForm}
            validationSchema={feedbackSchema}
            onSubmit={(values, { setFieldError, setFieldValue }) => {
              handleSubmit({ values, setFieldError, setFieldValue });
            }}
          >
            {({ isSubmitting, setFieldValue, values, errors, isValid }) => (
              <Form>
                <MDBModalBody ref={modalRef} className="fixed-body">
                  <article className="feedback-modal">
                    <section className="rating-section">
                      <h2>Rate your experience</h2>
                      <p>Are you satisfied with the service?</p>
                      <article className="flex justify-center gap-3 mt-4 mb-0">
                        {ratingOption.map((rate, rateIndex) => (
                          <article
                            className="rate-frame pointer"
                            onClick={() =>
                              handleRatingSelect({ setFieldValue }, rate.value)
                            }
                            key={rateIndex}
                          >
                            {selectedRating === rate.value ? (
                              <LazyLoadImage src={rate.gif} />
                            ) : (
                              <LazyLoadImage src={rate.image} />
                            )}
                          </article>
                        ))}
                      </article>
                    </section>
                    <hr />
                    <section className="topic-section">
                      <h4>Tell us what can be improved?</h4>
                      <article className="flex flex-wrap gap-3 mt-4 mb-4">
                        {topicOption.map((topic, topicIndex) => (
                          <article
                            className={`topic ${
                              selectedTopics.length > 0
                                ? selectedTopics.includes(topic)
                                  ? "--selected"
                                  : ""
                                : ""
                            }`}
                            onClick={() =>
                              handleTopicSelect({ setFieldValue, topic })
                            }
                            key={topicIndex}
                          >
                            <p>{topic}</p>
                          </article>
                        ))}
                      </article>
                      <article className="feedback-content">
                        <Field
                          name="text"
                          className="w-100"
                          placeholder="Tell us on how can we improve.."
                          as="textarea"
                        />
                      </article>
                      {errors &&
                        errors["text"] &&
                        selectedTopics.length > 0 &&
                        selectedTopics.includes("Others (Please specify)") && (
                          <p className="element _errors text-start no-padding error-message">
                            {errors["text"]}
                          </p>
                        )}
                    </section>
                  </article>
                </MDBModalBody>
                <section className="model2-summary --fixed">
                  <Button
                    disabled={!isValid || values.topic.length === 0}
                    type="submit"
                    className="w-100"
                  >
                    Send Feedback
                  </Button>
                </section>
              </Form>
            )}
          </Formik>
        </>
      }
    />
  );
}
