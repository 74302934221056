import { MDBModalBody } from "mdb-react-ui-kit";

import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import { closeTncModal } from "../../../redux/reducer/modalReducer";

import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";

export default function Tnc({ tnc }) {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const { isOpenTncModal } = useSelector((state) => state.modal);

  const handleSendEmail = () => {
    const subject = "Regarding Terms and Conditions";
    const body =
      "Dear recipient,\n\nI have a question regarding the terms and conditions. Can we discuss further?\n\nRegards,\n[Your Name]";
    const mailtoUrl = `mailto:${
      process.env.REACT_APP_SUPPORT_EMAIL
    }?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoUrl;
  };

  const handleCloseTnc = () => {
    dispatch(closeTncModal());
  };

  const termsWithTitle = tnc.filter((term) => term.title);
  const termsWithoutTitle = tnc.filter((term) => !term.title);

  return (
    <FullModal
      centered
      scrollable
      staticBackdrop={true}
      show={isOpenTncModal}
      backButton={handleCloseTnc}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      contentClassName=""
      content={
        <>
          <ModalHeader
            title="Terms and Conditions"
            backTo={handleCloseTnc}
            backToNoAnimation={handleCloseTnc}
            type="model2"
          />
          <MDBModalBody>
            <article className="spin-modal">
              <section className="list-section">
                <p>{termsWithoutTitle[0].content}</p>
                <ol>
                  {termsWithTitle?.length > 0 &&
                    termsWithTitle.map((term, termWithIndex) => (
                      <li key={termWithIndex}>
                        <article className="ms-2">
                          <h5>{term.title}</h5>
                          <p>{term.content}</p>
                        </article>
                      </li>
                    ))}
                </ol>
                {termsWithoutTitle.map(
                  (term, termWithoutIndex) =>
                    termWithoutIndex !== 0 && (
                      <p key={termWithoutIndex}>
                        {term.content}{" "}
                        <span onClick={() => handleSendEmail()}>
                          {term.email}
                        </span>
                        {term.email && "."}
                      </p>
                    )
                )}
              </section>
            </article>
          </MDBModalBody>
        </>
      }
    />
  );
}
