import { useEffect, useRef, useState } from "react";

import { Icon } from "@iconify/react";
import { MDBModalBody } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useToast } from "../../../components/common/toast.provider";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import { setFullLoading } from "../../../redux/reducer/commonReducer";
import { closeInstantTopUpModal } from "../../../redux/reducer/modalReducer";

import Button from "../../../components/element/button";
import HalfModal from "../../../components/modal/half.modal.box";
import ModalHeader from "../../../components/header/modal.header";

import apiService from "../../../services/api.service";

export default function InstantTopUp() {
  const toast = useToast();
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const { realWidth, width } = useWindowDimensions();

  const { cartInfo } = useSelector((state) => state.cart);
  const { walletBalance } = useSelector((state) => state.home);
  const { isOpenInstantTopUpModal } = useSelector((state) => state.modal);
  const { topUpPaymentList, merchantId, cartId } = useSelector(
    (state) => state.common
  );

  const [openPaymentItem, setOpenPaymentItem] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState({});

  const handleCloseInstantTopUpModal = () => {
    dispatch(closeInstantTopUpModal());
  };

  const handleConfirmTopUp = async (value) => {
    if (paymentMethod.name) {
      dispatch(setFullLoading(true));

      try {
        const response = await apiService.topUpWallet({
          cart_id: cartId,
          amount: parseFloat(cartInfo.total_price - walletBalance).toFixed(2),
          merchant_id: merchantId,
          tenant_channel_id: paymentMethod.tenant_channel_id,
          provider_channel_id: paymentMethod.provider_channel_id,
        });

        if (response) {
          if (response.data.url) {
            handleCloseInstantTopUpModal();
            window.parent.location.replace(response.data.url);
          } else {
            toast.error(
              "Oops! Something went wrong unexpectedly. Please try other payment methods."
            );
          }
        }
      } catch (ex) {
        dispatch(setFullLoading(false));

        if (ex && Object.keys(ex).length > 0) {
          let errorMsg = [];
          if (ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                errorMsg = errors[item][0];
                toast.error(errorMsg);
              });
            }
          }
        }
      }
    } else {
      toast.error("Please select a payment method to proceed the payment");
    }
  };

  const handleShowPaymentItem = (type) => {
    if (openPaymentItem.includes(type)) {
      setOpenPaymentItem(openPaymentItem.filter((id) => id !== type));
    } else {
      setOpenPaymentItem([...openPaymentItem, type]);
    }
  };

  useEffect(() => {
    dispatch(setFullLoading(false));
  }, []);

  return (
    <>
      <HalfModal
        className={"instant-topup"}
        show={isOpenInstantTopUpModal}
        backButton={handleCloseInstantTopUpModal}
        type={realWidth >= 450 ? "mobile" : ""}
        desktopModal={width >= 991 ? true : false}
        screenSize={width >= 991 ? "xl" : ""}
        content={
          <>
            <ModalHeader
              title="Top Up & Order"
              backTo={handleCloseInstantTopUpModal}
              backToNoAnimation={handleCloseInstantTopUpModal}
              type={`model2 ${realWidth < 450 ? "half" : ""}`}
            />
            <MDBModalBody ref={modalRef} className="fixed-body p-0">
              <article className="instant-topup-modal">
                <section className="topup-info">
                  <article className="flex justify-between items-baseline gap-3">
                    <h3>Required Top-up Amount</h3>
                    <article className="wallet-balance">
                      <Icon icon="solar:wallet-bold-duotone" />
                      <p>{parseFloat(walletBalance).toFixed(2)}</p>
                    </article>
                  </article>
                  <article className="flex items-center gap-3">
                    <h2>RM</h2>
                    <h2 className="amount">
                      {parseFloat(cartInfo.total_price - walletBalance).toFixed(
                        2
                      )}
                    </h2>
                  </article>
                  <hr />
                  <h3 className="mt-5">Payment Methods</h3>
                  <article className="payment-method-list">
                    {Object.entries(topUpPaymentList).map(
                      ([paymentType, paymentMethods], paymentIndex) => {
                        const totalLength =
                          Object.entries(topUpPaymentList).length;

                        return (
                          <section key={paymentType}>
                            <article
                              className={`payment-method ${
                                openPaymentItem.includes(paymentType) ||
                                paymentIndex === totalLength - 1
                                  ? "--withoutBorder"
                                  : ""
                              }`}
                              onClick={() => handleShowPaymentItem(paymentType)}
                            >
                              <article className="flex items-center gap-4">
                                <Icon
                                  icon="bi:credit-card-2-back"
                                  className="icon --primary smaller"
                                />
                                <h4>{paymentType}</h4>
                              </article>
                              <article>
                                <Icon
                                  icon={
                                    openPaymentItem.includes(paymentType)
                                      ? "ion:chevron-down"
                                      : "ion:chevron-right"
                                  }
                                  className={`icon ${
                                    openPaymentItem.includes(paymentType)
                                      ? ""
                                      : "smaller"
                                  }`}
                                />
                              </article>
                            </article>
                            {openPaymentItem.includes(paymentType) && (
                              <article className="p-4 pt-0 pb-0">
                                {paymentMethods.map((method) => (
                                  <section
                                    key={method.image_url}
                                    className="payment-method --withoutBorder"
                                    onClick={() => {
                                      setPaymentMethod({
                                        name: method.display_name,
                                        tenant_channel_id:
                                          method.tenant_channel_id,
                                        provider_channel_id:
                                          method.provider_channel_id,
                                      });
                                    }}
                                  >
                                    <article className="flex items-center gap-4">
                                      <article className="bank-frame">
                                        <LazyLoadImage
                                          src={method.image_url}
                                          alt={method.name}
                                        />
                                      </article>
                                      <h4>{method.display_name}</h4>
                                    </article>
                                    <article>
                                      <Icon
                                        icon={
                                          paymentMethod.tenant_channel_id ===
                                            method.tenant_channel_id &&
                                          paymentMethod.provider_channel_id ===
                                            method.provider_channel_id
                                            ? "pepicons-pop:bank-circle"
                                            : ""
                                        }
                                        className={
                                          paymentMethod.tenant_channel_id ===
                                            method.tenant_channel_id &&
                                          paymentMethod.provider_channel_id ===
                                            method.provider_channel_id
                                            ? "icon --primary"
                                            : ""
                                        }
                                      />
                                    </article>
                                  </section>
                                ))}
                              </article>
                            )}
                          </section>
                        );
                      }
                    )}
                  </article>
                </section>

                <section className="topup-summary">
                  <h4 className="mb-3">Payment Summary</h4>
                  <article className="flex justify-between items-center mb-2">
                    <p>Top-up charge</p>
                    <p>
                      {parseFloat(cartInfo.total_price - walletBalance).toFixed(
                        2
                      )}
                    </p>
                  </article>
                  <article className="flex justify-between items-center">
                    <h4>Order total</h4>
                    <h4>RM {parseFloat(cartInfo.total_price).toFixed(2)}</h4>
                  </article>
                </section>
              </article>
            </MDBModalBody>
            <section className="model2-summary --fixed --must-fixed">
              <Button
                className="w-100"
                btnClassName="w-100"
                onClick={() => handleConfirmTopUp()}
              >
                Confirm Top Up
              </Button>
            </section>
          </>
        }
      />
    </>
  );
}
