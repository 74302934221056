import { Icon } from "@iconify/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

import { useDispatch, useSelector } from "react-redux";
import { closePopUp, openPopUp } from "../../redux/reducer/modalReducer";

import Button from "../../components/element/button";

export default function PopUp() {
  const dispatch = useDispatch();
  const { merchantInfo } = useSelector((state) => state.merchant);

  const handleClosePopUp = () => {
    dispatch(closePopUp());
    document.body.style.overflow = "auto";

    setTimeout(() => {
      localStorage.removeItem("is_popup_displayed");
      dispatch(openPopUp());
    }, 15 * 60 * 1000);
  };

  return (
    <article className={`popup-container`}>
      <section className="popup-content">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {merchantInfo.pop_up?.length > 0 &&
            merchantInfo.pop_up.map(
              (popUp, popUpIndex) =>
                popUpIndex < 6 && (
                  <SwiperSlide key={popUpIndex}>
                    <article className="popup-frame">
                      <img src={popUp.url} alt="" />
                    </article>
                  </SwiperSlide>
                )
            )}
        </Swiper>
        <Button btnClassName="close-btn" onClick={() => handleClosePopUp()}>
          <Icon icon="maki:cross" />
        </Button>
      </section>
    </article>
  );
}
