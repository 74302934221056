import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api.service";

export const getAddressList = createAsyncThunk(
  "getAddressList",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getAddressList(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAddressInfo = createAsyncThunk(
  "getAddressInfo",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getAddressInfo(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const checkAddress = createAsyncThunk(
  "checkAddress",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.checkAddress(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAddress = createAsyncThunk(
  "deleteAddress",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.deleteAddress(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createAddress = createAsyncThunk(
  "createAddress",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.createAddress(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateAddress = createAsyncThunk(
  "updateAddress",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.updateAddress(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const initialData = {
  isNoAddressFound: false,
  searchingAddress: "",
  searchedAddress: "",
  editAddressId: "",

  addressList: [],
  addressListLoading: false,
  addressInfo: {},
  cartAddressInfo: {},
  addressMenu: [
    {
      label: "Edit",
      icon: "iconamoon:edit-light",
      action: "edit",
    },
    {
      label: "Delete",
      icon: "ep:delete",
      action: "delete",
    },
  ],
};

const dataSlice = createSlice({
  name: "address",
  initialState: initialData,
  reducers: {
    setIsNoAddressFound: (state, action) => {
      state.isNoAddressFound = action.payload;
    },
    setSearchingAddress: (state, action) => {
      state.searchingAddress = action.payload;
    },
    setSearchedAddress: (state, action) => {
      state.searchedAddress = action.payload;
    },
    clearAddressInfo: (state, action) => {
      state.addressInfo = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAddressList.pending, (state) => {
        state.addressListLoading = true;
      })
      .addCase(getAddressList.fulfilled, (state, action) => {
        state.addressListLoading = false;
        state.addressList = action.payload.data;
      })
      .addCase(getAddressList.rejected, (state) => {
        state.addressListLoading = false;
      })
      .addCase(getAddressInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAddressInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.cartAddressInfo = action.payload.data;
        state.addressInfo = action.payload.data;
      })
      .addCase(getAddressInfo.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  setSearchingAddress,
  setSearchedAddress,
  setIsNoAddressFound,
  clearAddressInfo,
} = dataSlice.actions;

export default dataSlice.reducer;
