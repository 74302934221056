//lib
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useSearchParams,
  useParams,
  useNavigate,
} from "react-router-dom";

//scss
import "./scss/index.scss";
import "./scss/components/_mobile.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-spring-bottom-sheet/dist/style.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";

//pages
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import Cart from "./pages/Cart";

//toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./scss/components/_one-signal.scss";
import "react-tooltip/dist/react-tooltip.css";

//component
import MainLayout from "./components/layout/main.layout";
import { useEffect, useState } from "react";
import PopUp from "./pages/PopUp";
import { useDispatch, useSelector } from "react-redux";
import {
  closeConfirmModal,
  openConfirmModal,
  openPopUp,
} from "./redux/reducer/modalReducer";
import Order from "./pages/Order";
import SpinToWin from "./pages/SpinToWin";
import CheckIn from "./pages/CheckIn";
import { Toaster } from "react-hot-toast";
import Queue from "./pages/Queue";
import Unauthorized from "./pages/Unauthorized";
import {
  initializeOneSignal,
  observeNotificationChanges,
} from "./utility/one_signal/OneSignalSetup";
import { lifecycle } from "react-lifecycle";
import { setConfirmItem, setType } from "./redux/reducer/commonReducer";
import { ToastProvider, useToast } from "./components/common/toast.provider";
import httpService from "./services/http.service";
import useWindowDimensions from "./components/hook/use.window.dimensions";
import { setMerchantInfoLoading } from "./redux/reducer/merchantReducer";
import Reservation from "./pages/Reservation";
import Receipt from "./pages/Receipt";
import MerchantList from "./pages/Unauthorized/components/merchant.list";

function ThemeColorHandler({ unauthorized, original }) {
  const { merchantInfoLoading, themeColor } = useSelector(
    (state) => state.merchant
  );

  useEffect(() => {
    const metaThemeColor = document.querySelector("meta[name='theme-color']");

    if (original) {
      if (themeColor.main_color) {
        metaThemeColor.setAttribute("content", themeColor.main_color);
      }

      return;
    }

    if (unauthorized) {
      metaThemeColor.setAttribute("content", "#ebf1ff");
      return;
    }

    if (merchantInfoLoading === "finished") {
      if (themeColor.main_color) {
        metaThemeColor.setAttribute("content", themeColor.main_color);
      } else {
        metaThemeColor.setAttribute("content", "f6f5f5");
      }
    } else {
      metaThemeColor.setAttribute("content", "f6f5f5");
    }
  }, [unauthorized, original, merchantInfoLoading, themeColor]);

  return null;
}

const useNetworkStatus = () => {
  const { type } = useSelector((state) => state.common);

  // State to store the online status
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [connectionType, setConnectionType] = useState(
    navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection ||
      "unknown"
  );

  useEffect(() => {
    const updateConnectionType = () => {
      const connection =
        navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection;

      if (connection) {
        setConnectionType(connection.effectiveType);
      } else {
        setConnectionType("unknown");
      }
    };

    updateConnectionType();

    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    // Add event listeners
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Cleanup
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  // Return network status as an object
  return { isOnline, connectionType };
};

function App() {
  const dispatch = useDispatch();
  const toast = useToast();
  const { realWidth } = useWindowDimensions();

  const merchant = useParams();

  const [initialized, setInitialized] = useState(false);
  const [notificationPermission, setNotificationPermission] =
    useState("default");
  // const [type, setType] = useState("");

  const { type } = useSelector((state) => state.common);
  const { merchantInfoLoading, themeColor } = useSelector(
    (state) => state.merchant
  );
  const { isOnline, connectionType } = useNetworkStatus();

  const open = window.location.search;

  useEffect(() => {
    if (!isOnline) {
      dispatch(setType("no internet"));
      dispatch(setMerchantInfoLoading("finished"));
    } else {
      if (type === "no internet") {
        dispatch(setType(""));
        dispatch(setMerchantInfoLoading("splashing"));
        // Consider removing the page reload and handling this case differently
        // window.location.reload();
      }

      if (connectionType === "2g" && type !== "2g") {
        dispatch(setType("2g"));
        toast?.error(
          "Slow connection. Please check your connection and try again."
        );
      } else if (connectionType !== "2g" && type === "2g") {
        dispatch(setType(""));
        dispatch(closeConfirmModal());
      }
    }
  }, [isOnline, connectionType, type, dispatch]);

  useEffect(() => {
    if ("Notification" in window) {
      if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
        setNotificationPermission(Notification.permission);
      } else if (typeof Notification.requestPermission === "function") {
        Notification.requestPermission()
          .then((permission) => {
            setNotificationPermission(permission);
          })
          .catch((error) => {
            console.error("Notification permission request failed", error);
          });
      }
    } else {
      console.warn("This browser does not support desktop notification");
    }
  }, []);

  useEffect(() => {
    if (
      merchantInfoLoading === "finished" &&
      !initialized &&
      realWidth < 450 &&
      process.env.REACT_APP_ENV !== "local"
    ) {
      initializeOneSignal(
        setInitialized,
        setNotificationPermission,
        notificationPermission,
        dispatch,
        themeColor
      );
    }
  }, [merchantInfoLoading]);

  useEffect(() => {
    observeNotificationChanges(notificationPermission);
  }, [notificationPermission]);

  useEffect(() => {
    httpService.initializeInterceptors(toast);
  }, []);

  return (
    <BrowserRouter>
      <ThemeColorHandler
        unauthorized={window.location.pathname === "/" || !isOnline}
        original={window.location.pathname?.includes("/branch")}
      />
      {!isOnline ? (
        <MainLayout>
          <Unauthorized type={type} />
        </MainLayout>
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              <MainLayout>
                <Unauthorized />
              </MainLayout>
            }
          />
          <Route
            path="/:store"
            element={
              <MainLayout>
                <Home />
              </MainLayout>
            }
          />
          <Route
            path="/:merchant/branch"
            element={
              <MainLayout>
                <Unauthorized />
              </MainLayout>
            }
          />
          <Route
            path="/:store/menu"
            element={
              <MainLayout>
                <Menu />
              </MainLayout>
            }
          />
          <Route
            path="/:store/cart"
            element={
              <MainLayout>
                <Cart />
              </MainLayout>
            }
          />
          <Route
            path="/:store/order"
            element={
              <MainLayout>
                <Order />
              </MainLayout>
            }
          />
          <Route
            path="/:store/order/all"
            element={
              <MainLayout>
                <Order />
              </MainLayout>
            }
          />
          <Route
            path="/:store/spin-wheel"
            element={
              <MainLayout>
                <SpinToWin />
              </MainLayout>
            }
          />
          <Route
            path="/:store/check-in"
            element={
              <MainLayout>
                <CheckIn />
              </MainLayout>
            }
          />
          <Route
            path="/:store/queue"
            element={
              <MainLayout>
                <Queue />
              </MainLayout>
            }
          />
          <Route
            path="/:store/reservation"
            element={
              <MainLayout>
                <Reservation />
              </MainLayout>
            }
          />
          <Route
            path="/receipt/:id"
            element={
              <MainLayout>
                <Receipt />
              </MainLayout>
            }
          />
          {/* used to auto open modal */}
          {/* <Route 
            path="/:state"
            element={
              <MainLayout>
                <Home />
              </MainLayout>
            } 
          /> */}
          {/* <Route 
            path="/post/:state"
            element={
              <MainLayout>
                <Home />
              </MainLayout>
            } 
          /> */}
          {/* handle 404 */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
      {/* <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName="toaster"
        toastOptions={{
          className: '',
          duration: 4000,
          style: {
            background: '#363636',
            color: '#fff',
          },

          success: {
            icon: '💁‍♀️',
            duration: 4000,
            style: {
              background: '#7fd290cc',
              backdropFilter: 'blur(5px)',
              borderRadius: '30px',
              fontFamily: 'poppins',
              marginTop: 0,
              lineHeight: '20px'
            }
          },

          error: {
            icon: '🔌',
            duration: 4000,
            style: {
              background: '#fa4e4ecc',
              backdropFilter: 'blur(5px)',
              borderRadius: '30px',
              fontFamily: 'poppins',
              marginTop: 0,
              lineHeight: '20px'
            }
          },
        }}
      /> */}
    </BrowserRouter>
  );
}

export default App;
