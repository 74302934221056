import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api.service";

export const getOrderOngoingList = createAsyncThunk(
  "getOrderOngoingList",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getOrderOngoingList(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getOrderHistoryList = createAsyncThunk(
  "getOrderHistoryList",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getOrderHistoryList(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getReceiptInfo = createAsyncThunk(
  "getReceiptInfo",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getReceiptInfo(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getPublicReceipt = createAsyncThunk(
  "getPublicReceipt",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getPublicReceipt(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resubmitRefund = createAsyncThunk(
  "resubmitRefund",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.resubmitRefund(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const initialData = {
  orderOngoingList: [],
  orderHistoryList: [],
  refundLoading: false,
  orderOngoingLoading: false,
  orderHistoryLoading: false,
  isInitialDone: false,
  receiptInfoLoading: false,
  publicReceiptLoading: false,
  receiptInfo: {},
  publicReceipt: {},
  makePaymentData: {},

  hasMore: false,
  length: 10,
  start: 0,
  data: [],
};

const dataSlice = createSlice({
  name: "order",
  initialState: initialData,
  reducers: {
    setHasMore: (state, action) => {
      state.hasMore = action.payload;
    },
    setStart: (state, action) => {
      state.start = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLength: (state, action) => {
      state.length = action.payload;
    },
    setNoLoading: (state, action) => {
      state.noLoading = action.payload;
    },
    setInitialDone: (state, action) => {
      state.isInitialDone = action.payload;
    },
    setMakePaymentData: (state, action) => {
      state.makePaymentData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderOngoingList.pending, (state) => {
        state.orderOngoingLoading = true;
      })
      .addCase(getOrderOngoingList.fulfilled, (state, action) => {
        state.orderOngoingLoading = false;
        state.orderOngoingList = action.payload.data.records;
      })
      .addCase(getOrderOngoingList.rejected, (state) => {
        state.orderOngoingLoading = false;
      })

      .addCase(getOrderHistoryList.pending, (state) => {
        const intialDone = state.isInitialDone;

        if (!intialDone) {
          state.orderHistoryLoading = true;
        }
      })
      .addCase(getOrderHistoryList.fulfilled, (state, action) => {
        const intialDone = state.isInitialDone;

        if (!intialDone) {
          state.orderHistoryLoading = false;
          state.isInitialDone = true;
        }

        state.orderHistoryList = action.payload.data.records;
      })
      .addCase(getOrderHistoryList.rejected, (state) => {
        state.orderHistoryLoading = false;
      })

      .addCase(getReceiptInfo.pending, (state) => {
        state.receiptInfoLoading = true;
      })
      .addCase(getReceiptInfo.fulfilled, (state, action) => {
        state.receiptInfoLoading = false;
        state.receiptInfo = action.payload.data;
      })
      .addCase(getReceiptInfo.rejected, (state) => {
        state.receiptInfoLoading = false;
      })

      .addCase(getPublicReceipt.pending, (state) => {
        state.publicReceiptLoading = true;
      })
      .addCase(getPublicReceipt.fulfilled, (state, action) => {
        state.publicReceiptLoading = false;
        state.publicReceipt = action.payload.data;
      })
      .addCase(getPublicReceipt.rejected, (state) => {
        state.publicReceiptLoading = false;
      })

      .addCase(resubmitRefund.pending, (state) => {
        state.refundLoading = true;
      })
      .addCase(resubmitRefund.fulfilled, (state, action) => {
        state.refundLoading = false;
      })
      .addCase(resubmitRefund.rejected, (state) => {
        state.refundLoading = false;
      });
  },
});

export const {
  setNoLoading,
  setHasMore,
  setStart,
  setData,
  setLength,
  setInitialDone,
  setMakePaymentData,
} = dataSlice.actions;

export default dataSlice.reducer;
