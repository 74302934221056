import { useEffect, useRef, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { MDBModalBody } from "mdb-react-ui-kit";

import scrollToTop from "../../../helpers/scroll.to.top";
import { useToast } from "../../../components/common/toast.provider";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import {
  closeSaveAddressModal,
  openSetAddressModal,
  setAnimationModal,
} from "../../../redux/reducer/modalReducer";
import {
  clearAddressInfo,
  createAddress,
  getAddressList,
  setSearchedAddress,
  updateAddress,
} from "../../../redux/reducer/addressReducer";

import Input from "../../../components/element/input";
import Button from "../../../components/element/button";
import MapComponent from "../../../components/common/google.map";
import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";

export default function SaveAddress() {
  const toast = useToast();
  const formikRef = useRef();
  const modalRef = useRef(null);
  const dispatch = useDispatch();

  const { width } = useWindowDimensions();
  const [markerPlaced, setMarkerPlaced] = useState({});
  const [isSavingAddress, setIsSavingAddress] = useState(false);

  const { isOpenSaveAddressModal } = useSelector((state) => state.modal);
  const { searchedAddress, addressInfo } = useSelector(
    (state) => state.address
  );

  function getAddressComponent(components, type) {
    const component = components.find((component) =>
      component.types.includes(type)
    );
    return component ? component.long_name : "";
  }

  const includesAddressComponentShortName = () => {
    if (searchedAddress?.address_components?.length > 0) {
      const { name, address_components } = searchedAddress;

      let streetNumber = "";
      let route = "";

      address_components.forEach((component) => {
        if (component.types.includes("street_number")) {
          streetNumber = component.long_name;
        } else if (component.types.includes("route")) {
          route = component.long_name;
        }
      });

      const normalizeStreetName = (streetName) => {
        return streetName?.replace(/Jln\.?|Jalan/i, "jalan");
      };

      const unitWithStreetAddress =
        streetNumber || route
          ? `${streetNumber ? streetNumber : ""}${streetNumber ? ", " : ""}${
              route ? route : ""
            }`
          : "";
      if (
        normalizeStreetName(name) ===
          normalizeStreetName(unitWithStreetAddress) &&
        unitWithStreetAddress !== ""
      ) {
        return true;
      }
    }

    return false;
  };

  const shouldIgnoreShortName = includesAddressComponentShortName();

  const addressForm = {
    id: addressInfo.id,
    name: addressInfo.name ? addressInfo.name : "",
    unit:
      !searchedAddress?.address_components?.length > 0 && addressInfo.unit_no
        ? addressInfo.unit_no
        : getAddressComponent(
            searchedAddress?.address_components || [],
            "street_number"
          ) || "",
    building_name:
      !searchedAddress?.name && addressInfo.building_name
        ? addressInfo.building_name
        : !shouldIgnoreShortName && searchedAddress?.name
        ? searchedAddress.name
        : "",
    street_name:
      !searchedAddress?.address_components?.length > 0 && addressInfo.street
        ? addressInfo.street
        : getAddressComponent(
            searchedAddress?.address_components || [],
            "route"
          ) || "",
    city:
      !searchedAddress?.address_components?.length > 0 && addressInfo.city
        ? addressInfo.city
        : getAddressComponent(
            searchedAddress?.address_components || [],
            "locality"
          ) || "",
    postcode:
      !searchedAddress?.address_components?.length > 0 &&
      addressInfo.postal_code
        ? addressInfo.postal_code
        : getAddressComponent(
            searchedAddress?.address_components || [],
            "postal_code"
          ) || "",
    state:
      !searchedAddress?.address_components?.length > 0 && addressInfo.state
        ? addressInfo.state
        : getAddressComponent(
            searchedAddress?.address_components || [],
            "administrative_area_level_1"
          ) || "",
    country:
      !searchedAddress?.address_components?.length > 0 && addressInfo.country
        ? addressInfo.country
        : getAddressComponent(
            searchedAddress?.address_components || [],
            "country"
          ) || "",
    notes: addressInfo.remark ? addressInfo.remark : "",
    address:
      !searchedAddress?.address_components?.length > 0 && addressInfo.address
        ? addressInfo.address
        : searchedAddress?.formatted_address
        ? searchedAddress.formatted_address
        : "",
    lat:
      !searchedAddress?.address_components?.length > 0 && addressInfo.latitude
        ? addressInfo.latitude
        : typeof searchedAddress?.geometry?.location?.lat === "function"
        ? searchedAddress.geometry.location.lat()
        : typeof searchedAddress?.geometry?.location?.lat === "number"
        ? searchedAddress.geometry.location.lat
        : 3.139,
    lng:
      !searchedAddress?.address_components?.length > 0 && addressInfo.longitude
        ? addressInfo.longitude
        : typeof searchedAddress?.geometry?.location?.lng === "function"
        ? searchedAddress.geometry.location.lng()
        : typeof searchedAddress?.geometry?.location?.lng === "number"
        ? searchedAddress.geometry.location.lng
        : 101.6869,
  };

  const addressSchema = Yup.object({
    name: Yup.string().required("Address name is required"),
    // building_name: Yup.string().required("Building name is required"),
    unit: Yup.string().required("Unit or house number is required"),
    street_name: Yup.string().required(
      "Invalid address, drop the marker on the map to identify your location."
    ),
    city: Yup.string(),
    postcode: Yup.string(),
    state: Yup.string(),
    country: Yup.string(),
  });

  const handleSubmit = ({ values, setFieldError }) => {
    setIsSavingAddress(true);

    if (addressInfo.id) {
      editSubmit({ values, setFieldError });
    } else {
      addSubmit({ values, setFieldError });
    }
  };

  const addSubmit = ({ values, setFieldError }) => {
    try {
      const addressData = {
        name: values.name,
        unit_no: values.unit,
        building_name: values.building_name,
        street: values.street_name,
        city: values.city,
        postal_code: values.postcode,
        state: values.state,
        country: values.country,
        remark: values.notes,
        address: `${values.unit}, ${values.building_name}${
          values.building_name ? ", " : ""
        }${values.street_name ?? ""}${values.street_name ? "," : ""} ${
          values.city
        }, ${values.postcode} ${values.state} ${values.country}`,
        latitude: parseFloat(values.lat),
        longitude: parseFloat(values.lng),
      };

      dispatch(createAddress(addressData))
        .unwrap()
        .then((res) => {
          setIsSavingAddress(false);
          handleOpenSetAddress();
          dispatch(setSearchedAddress({}));
          toast.success("Save address successful.");
        })
        .catch((ex) => {
          setIsSavingAddress(false);
          if (ex && ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                setFieldError(item, errors[item]);
              });
              toast.error("Save address unsuccessful.");
            }
          }
        });
    } catch (ex) {
      setIsSavingAddress(false);
      if (ex && ex.response?.status === 422) {
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) =>
            setFieldError(item, errors[item])
          );
        }
      }
    }
  };

  const editSubmit = ({ values, setFieldError }) => {
    try {
      const updatedAddressData = {
        id: addressInfo.id,
        name: values.name,
        unit_no: values.unit,
        building_name: values.building_name,
        street: values.street_name,
        city: values.city,
        postal_code: values.postcode,
        state: values.state,
        country: values.country,
        remark: values.notes,
        address: `${values.unit}, ${values.building_name}${
          values.building_name ? ", " : ""
        }${values.street_name ?? ""}${values.street_name ? "," : ""} ${
          values.city
        }, ${values.postcode} ${values.state} ${values.country}`,
        latitude: values.lat,
        longitude: values.lng,
      };

      dispatch(updateAddress(updatedAddressData))
        .then((res) => {
          handleOpenSetAddress();
          dispatch(clearAddressInfo());
          toast.success("Save address successful.");
        })
        .catch((ex) => {});
    } catch (ex) {
      setIsSavingAddress(false);
      if (ex && ex.response?.status === 422) {
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) =>
            setFieldError(item, errors[item])
          );
        }
      }
    }
  };

  const handleMarkerPlaced = (value) => {
    setMarkerPlaced({ lat: value.lat, lng: value.lng });
  };

  const handleOpenSetAddress = () => {
    dispatch(setAnimationModal(false));
    dispatch(closeSaveAddressModal());
    dispatch(openSetAddressModal());
    dispatch(setSearchedAddress({}));
    dispatch(clearAddressInfo());
  };

  useEffect(() => {
    if (
      formikRef.current &&
      searchedAddress?.address_components === undefined &&
      !addressInfo.name
    ) {
      formikRef.current.setFieldError(
        "street_name",
        "Drop the marker on the map to identify your location."
      );
    } else if (
      formikRef.current &&
      // !getAddressComponent(searchedAddress?.address_components || [], "street_number") ||
      (!getAddressComponent(
        searchedAddress?.address_components || [],
        "route"
      ) ||
        !getAddressComponent(
          searchedAddress?.address_components || [],
          "locality"
        ) ||
        !getAddressComponent(
          searchedAddress?.address_components || [],
          "postal_code"
        ) ||
        !getAddressComponent(
          searchedAddress?.address_components || [],
          "administrative_area_level_1"
        ) ||
        !getAddressComponent(
          searchedAddress?.address_components || [],
          "country"
        )) &&
      !addressInfo.name
    ) {
      formikRef.current.setFieldError(
        "street_name",
        "Invalid address, drop the marker on the map to identify your location."
      );
    }
  }, [searchedAddress, formikRef]);

  useEffect(() => {
    if (modalRef.current) {
      scrollToTop(modalRef.current);
    }
  }, [isOpenSaveAddressModal]);

  return (
    <FullModal
      show={isOpenSaveAddressModal}
      backButton={handleOpenSetAddress}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      content={
        <>
          <ModalHeader
            title="Save Address"
            backTo={handleOpenSetAddress}
            backToNoAnimation={handleOpenSetAddress}
            type="model2"
          />
          <MDBModalBody ref={modalRef}>
            <Formik
              innerRef={formikRef}
              initialValues={addressForm}
              validationSchema={addressSchema}
              enableReinitialize={true}
              onSubmit={(values, { errors, setFieldError }) => {
                handleSubmit({ values, errors, setFieldError });
              }}
            >
              {({ values, isValid }) => (
                <Form>
                  <article className="save-address-modal">
                    <h2>Information Details</h2>
                    <section className="map-frame">
                      <MapComponent
                        onMarkerPlaced={handleMarkerPlaced}
                        markerPlaced={markerPlaced}
                        address={addressInfo.id ? addressInfo : searchedAddress}
                      />
                    </section>
                    <section className="input-section">
                      <Input
                        name="name"
                        label="Name"
                        placeholder="Save address as, e.g My House, Office"
                        as="round-field"
                        isRequired={true}
                        inputClassName="--grey"
                      />
                      <Input
                        name="building_name"
                        label="Building Name"
                        as="round-field"
                        // isRequired={true}
                        inputClassName={"--grey"}
                      />
                      <Input
                        name="unit"
                        label="Unit/House Number"
                        as="round-field"
                        isRequired={true}
                        inputClassName={"--grey"}
                      />
                      <Input
                        name="street_name"
                        label="Street Name"
                        as="round-field"
                        isRequired={false}
                        disabled={true}
                        inputClassName={
                          getAddressComponent(
                            searchedAddress?.address_components || [],
                            "route"
                          )
                            ? "--autofill"
                            : ""
                        }
                      />
                      <Input
                        name="city"
                        label="City"
                        as="round-field"
                        isRequired={false}
                        disabled={true}
                        inputClassName={
                          getAddressComponent(
                            searchedAddress?.address_components || [],
                            "locality"
                          )
                            ? "--autofill"
                            : ""
                        }
                      />
                      <Input
                        name="postcode"
                        label="Postal Code"
                        as="round-field"
                        isRequired={false}
                        disabled={true}
                        inputClassName={
                          getAddressComponent(
                            searchedAddress?.address_components || [],
                            "postal_code"
                          )
                            ? "--autofill"
                            : ""
                        }
                      />
                      <Input
                        name="state"
                        label="State"
                        as="round-field"
                        isRequired={false}
                        disabled={true}
                        inputClassName={
                          getAddressComponent(
                            searchedAddress?.address_components || [],
                            "administrative_area_level_1"
                          )
                            ? "--autofill"
                            : ""
                        }
                      />
                      <Input
                        name="country"
                        label="Country"
                        as="round-field"
                        isRequired={false}
                        disabled={true}
                        inputClassName={
                          getAddressComponent(
                            searchedAddress?.address_components || [],
                            "country"
                          )
                            ? "--autofill"
                            : ""
                        }
                      />
                      <article className="rider-note">
                        <p>Add Notes To Rider (Optional)</p>
                        <div className="p-1 mt-3">
                          <div className="note-frame">
                            <Input
                              name="notes"
                              placeholder="Add delivery instructions, e.g. nearest building, nearest place"
                              as="textarea"
                              isRequired={false}
                              maxLength={60}
                              inputClassName="--grey"
                            />
                          </div>
                        </div>
                      </article>
                      <article className="address-summary">
                        <Button
                          type="submit"
                          btnClassName="w-100"
                          disabled={!isValid || isSavingAddress}
                        >
                          Save Address
                        </Button>
                      </article>
                    </section>
                  </article>
                  <div
                    className="button-container hide"
                    style={{ display: "none" }}
                  >
                    <Button>Save Address</Button>
                  </div>
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </>
      }
    />
  );
}
