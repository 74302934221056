import { useEffect, useState } from "react";

import { DefaultMenu } from "../../../assets/images";

import { Icon } from "@iconify/react";
import { Skeleton } from "primereact/skeleton";
import { motion, AnimatePresence } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";

import CategoryBar from "./category.bar";
import RecommendMenu from "./recommend.menu";

import { useToast } from "../../../components/common/toast.provider";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

import { useDispatch, useSelector } from "react-redux";
import { getCartCount, getCartInfo } from "../../../redux/reducer/cartReducer";
import {
  getCartId,
  getCartOwner,
  setConfirmItem,
} from "../../../redux/reducer/commonReducer";
import {
  closePlaceOrderModal,
  openConfirmModal,
  openPlaceOrderModal,
} from "../../../redux/reducer/modalReducer";
import {
  getMenuInfo,
  getMenuList,
  getRecommendMenu,
  setIsAddingCartId,
  setMenuId,
  setShowRecommend,
} from "../../../redux/reducer/menuReducer";

import apiService from "../../../services/api.service";

export default function MenuListing({ menuRef }) {
  const toast = useToast();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const { cartInfo } = useSelector((state) => state.cart);
  const { merchantInfo } = useSelector((state) => state.merchant);
  const { method, cartId, tableId, sessionNo, addressId, merchantId, userId } =
    useSelector((state) => state.common);
  const {
    isAddingCartId,
    deliveryMenuList,
    dineInMenuList,
    takeAwayMenuList,
    menuListLoading,
    showRecommend,
    recommendMenuLoading,
    recommendMenu,
  } = useSelector((state) => state.menu);

  const [isClickCategory, setIsClickCategory] = useState(false);
  const [clickActiveCategory, setClickActiveCategory] = useState(null);
  const [scrollActiveCategory, setScrollActiveCategory] = useState(null);

  const menuList =
    method === "delivery"
      ? deliveryMenuList
      : method === "dine in"
      ? dineInMenuList
      : takeAwayMenuList;

  const handleScroll = () => {
    const section = menuRef.current;
    const scrollPosition = section.scrollTop;
    const maxScroll = section.scrollHeight - section.clientHeight;

    if (!isClickCategory && menuList.length > 0) {
      setClickActiveCategory(null);
      let foundActiveId = null;

      if (maxScroll - scrollPosition < 1) {
        const lastCategory = menuList[menuList.length - 1];
        foundActiveId = lastCategory.category_id;
      } else {
        for (const category of menuList) {
          const element = document.getElementById(
            `category-${category.category_id}`
          );
          if (
            element &&
            scrollPosition >= element.offsetTop - 140 &&
            scrollPosition < element.offsetTop - 140 + element.offsetHeight
          ) {
            foundActiveId = category.category_id;
            break;
          }
        }
      }

      setScrollActiveCategory(foundActiveId);
    }

    if (scrollPosition > 0 && showRecommend) {
      dispatch(setShowRecommend(false));
    } else if (scrollPosition < 1 && !showRecommend) {
      if (
        (!recommendMenuLoading || isAddingCartId) &&
        recommendMenu?.length > 0
      ) {
        dispatch(setShowRecommend(true));
      } else {
        dispatch(setShowRecommend(false));
      }
    }
  };

  const handlePlaceOrder = (id) => {
    if (cartId && cartInfo?.payment_status === "pending") {
      dispatch(
        setConfirmItem({
          type: "cart processing",
          item: cartId,
          total: cartInfo.total_price,
        })
      );
      dispatch(openConfirmModal());
    } else {
      dispatch(setMenuId(id));
      dispatch(openPlaceOrderModal());
      dispatch(
        getMenuInfo({
          id: id,
          method: method,
        })
      )
        .unwrap()
        .catch((ex) => {
          if (ex && ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                toast.error(errors[item][0]);
              });
            }
          }
        });
    }
  };

  const handleDirectPlaceOrder = async (e, id) => {
    e.stopPropagation();
    dispatch(setIsAddingCartId(id));

    try {
      const response = await apiService.addCart({
        cart_id: cartId,
        menu_id: id,
        quantity: 1,
        remark: "",
        method: method,
        table_id:
          method === "dine in"
            ? tableId
              ? tableId === "null"
                ? null
                : tableId
              : null
            : null,
        session_no:
          method === "dine in"
            ? sessionNo
              ? sessionNo === "null"
                ? null
                : sessionNo
              : null
            : null,

        ...(method === "delivery" && {
          address_id: addressId,
        }),
      });

      if (response) {
        // dispatch(
        //   getMenuList({
        //     merchant_id: merchantId,
        //     remark: "food",
        //     method: method,
        //     table_id: method === "dine in" ? tableId : null,
        //     session_no: sessionNo ?? null,
        //   })
        // );
        // dispatch(
        //   getRecommendMenu({
        //     type: "merchant recommendation",
        //     merchant_id: merchantId,
        //     method: method,
        //   })
        // );
        dispatch(
          getCartInfo({
            merchant_id: merchantId,
            order_method: method,
          })
        )
          .unwrap()
          .then((res) => {
            dispatch(setIsAddingCartId(null));
            toast.success("Add cart successful.");

            if (res.data.length > 0) {
              localStorage.setItem("cart_id", res.data[0].cart_id);
              if (res.data[0].user_id === parseInt(userId)) {
                localStorage.setItem("cart_owner", true);
              }
              dispatch(getCartId());
              dispatch(getCartOwner());
            }
          })
          .catch((ex) => {});
        dispatch(
          getCartCount({
            order_method: method,
            merchant_id: merchantId,
          })
        );
      }
    } catch (ex) {
      dispatch(setIsAddingCartId(null));

      if (ex && Object.keys(ex).length > 0) {
        let errorMsg = [];
        if (ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              errorMsg = errors[item][0];
              toast.error(errorMsg);
            });
          }
        }
      }
    }
  };

  const handleCategoryClick = (categoryId) => {
    setIsClickCategory(true);
    setClickActiveCategory(categoryId);

    const section = menuRef.current;

    if (section) {
      const categoryElement = section.querySelector(`#category-${categoryId}`);

      if (categoryElement) {
        const topPosition = categoryElement.offsetTop - 140;
        section.scrollTo({
          top: topPosition,
          behavior: "smooth",
        });

        const onScroll = () => {
          if (Math.abs(section.scrollTop - topPosition) < 5) {
            setIsClickCategory(false);
            section.removeEventListener("scroll", onScroll);
          }
        };

        section.addEventListener("scroll", onScroll);
      }
    }
  };

  useEffect(() => {
    handleScroll();
  }, [menuList]);

  return (
    <article className="menu-body">
      <AnimatePresence>
        {(!recommendMenuLoading || isAddingCartId) &&
          recommendMenu?.length > 0 &&
          showRecommend && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.4 }}
              className="w-100 flex justify-center"
            >
              <RecommendMenu
                show={showRecommend}
                handleDirectPlaceOrder={handleDirectPlaceOrder}
              />
            </motion.div>
          )}
      </AnimatePresence>
      <article className="menu-listing">
        <CategoryBar
          onCategoryClick={handleCategoryClick}
          scrollActiveCategory={scrollActiveCategory}
          clickActiveCategory={clickActiveCategory}
        />
        <section
          className="menu-section"
          ref={menuRef}
          onScroll={handleScroll}
          style={{
            paddingTop:
              isAddingCartId && recommendMenu?.length > 0 && showRecommend
                ? ""
                : recommendMenuLoading ||
                  (!recommendMenuLoading && recommendMenu?.length === 0)
                ? "0em"
                : !showRecommend
                ? "7em"
                : "",
          }}
        >
          {!menuListLoading || isAddingCartId ? (
            menuList?.length > 0 &&
            menuList.map((menuCategory, menuCategoryIndex) => {
              const isFirstCategoryWithMenuItems =
                menuCategoryIndex ===
                menuList.findIndex((category) => category.menu.length > 0);

              return (
                menuCategory.menu.length > 0 && (
                  <article
                    id={`category-${
                      menuCategory.category_id
                        ? menuCategory.category_id
                        : menuCategory.id
                    }`}
                    key={menuCategoryIndex}
                  >
                    <article id={menuCategory.category_id}>
                      <h2
                        style={{
                          margin:
                            recommendMenu?.length === 0 &&
                            isFirstCategoryWithMenuItems
                              ? "0.8em 0 1em"
                              : "",
                        }}
                      >
                        {menuCategory.name}
                      </h2>
                    </article>
                    <article className="menu-grid">
                      {menuCategory.menu.map((menu, menuIndex) => {
                        return (
                          <section
                            className={`w-100 ${
                              menu.has_stock === 1 ? "pointer" : "not-allowed"
                            }`}
                            onClick={() =>
                              !isAddingCartId && menu.has_stock === 1
                                ? handlePlaceOrder(menu.menu_id)
                                : undefined
                            }
                            key={menuIndex}
                          >
                            <article
                              className={`menu-frame ${
                                menu.has_stock === 0
                                  ? "--out-stock"
                                  : !menu.picture &&
                                    merchantInfo.company_logo.length > 0
                                  ? "--store-logo"
                                  : ""
                              }`}
                            >
                              {menu.has_stock === 0 && (
                                <article className="out-stock-overlay">
                                  <p>Out Of Stock</p>
                                </article>
                              )}
                              <LazyLoadImage
                                src={
                                  menu.picture ||
                                  (merchantInfo.company_logo.length > 0
                                    ? merchantInfo.company_logo[0].url
                                    : DefaultMenu)
                                }
                              />
                            </article>

                            <article
                              className={`menu-name ${
                                menu.has_stock === 0 ? "--out-stock" : ""
                              } ${
                                process.env.REACT_APP_ENV === "sit"
                                  ? "copy"
                                  : "pointer"
                              }`}
                            >
                              <h4
                                className={
                                  menu.chinese_name === null
                                    ? "--noChinese"
                                    : ""
                                }
                              >
                                {menu.menu_name}
                              </h4>
                              {menu.chinese_name && (
                                <>
                                  <br />
                                  <h4>{menu.chinese_name}</h4>
                                </>
                              )}
                            </article>

                            <article
                              className="flex justify-between"
                              style={{ alignItems: "flex-end" }}
                            >
                              <h4
                                className={`menu-price ${
                                  menu.has_stock === 0 ? "--out-stock" : ""
                                }`}
                              >
                                {" "}
                                RM{" "}
                                {parseFloat(
                                  menu.promotion_price !== null
                                    ? menu.promotion_price
                                    : menu.unit_price
                                ).toFixed(2)}{" "}
                                {menu.promotion_price !== null && (
                                  <span>
                                    {parseFloat(menu.unit_price).toFixed(2)}
                                  </span>
                                )}
                              </h4>
                              {menu.has_stock === 1 && (
                                <button
                                  className={`add-button ${
                                    isAddingCartId === menu.menu_id
                                      ? "--loading"
                                      : ""
                                  }`}
                                  onClick={(e) =>
                                    !isAddingCartId &&
                                    menu.has_compulsory_food_option === 0
                                      ? handleDirectPlaceOrder(e, menu.menu_id)
                                      : undefined
                                  }
                                >
                                  {isAddingCartId === menu.menu_id ? (
                                    <Icon icon="svg-spinners:3-dots-scale" />
                                  ) : (
                                    "Add"
                                  )}
                                </button>
                              )}
                            </article>
                          </section>
                        );
                      })}
                    </article>
                  </article>
                )
              );
            })
          ) : (
            <article className="menu-grid" style={{ marginTop: "5em" }}>
              {Array.from({ length: 5 }, (_, menuSkeletonIndex) => (
                <section className="w-100" key={menuSkeletonIndex}>
                  <Skeleton
                    width="100%"
                    height={width > 991 ? "148px" : "96px"}
                    className="menu-frame"
                  />
                  <Skeleton width="60%" height="16px" borderRadius="8px" />
                  <Skeleton
                    width="100%"
                    height="12px"
                    borderRadius="8px"
                    className="mt-3"
                  />
                  <article className="flex items-start  justify-between mt-3">
                    <Skeleton
                      width={width > 991 ? "30%" : "40%"}
                      height="12px"
                      borderRadius="8px"
                    />
                    <Skeleton width="50px" height="24px" borderRadius="15px" />
                  </article>
                </section>
              ))}
            </article>
          )}
        </section>
      </article>
    </article>
  );
}
