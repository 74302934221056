import { RemoveScroll } from "react-remove-scroll";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnimationModal,
  setShowModal,
} from "../../redux/reducer/modalReducer";
import { MDBModal, MDBModalContent, MDBModalDialog } from "mdb-react-ui-kit";
import useWindowDimensions from "../hook/use.window.dimensions";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function FullModal({
  show,
  setShow,
  content,
  centered = true,
  screenSize,
  scrollable = true,
  className,
  backButton,
  dialogClassName,
  contentClassName,
  modalRef,
}) {
  const dispatch = useDispatch();
  const { isShowModalLoading } = useSelector((state) => state.common);
  const { showModal, isAnimationModal } = useSelector((state) => state.modal);

  const handleCloseModalWithDelay = () => {
    dispatch(setShowModal(false));

    setTimeout(() => {
      dispatch(setAnimationModal(true));
      backButton();
    }, 500);
  };

  useEffect(() => {
    dispatch(setShowModal(show));
  }, [show]);

  return (
    <RemoveScroll>
      <AnimatePresence>
        {showModal && (
          <MDBModal
            className={`full-modal-box-container ${className ? className : ""}`}
            // tabIndex="-1"
            // show={true}
          >
            {backButton && (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                }}
                onClick={() => handleCloseModalWithDelay()}
              ></div>
            )}
            <motion.div
              style={{ willChange: "unset" }}
              initial={
                isAnimationModal && {
                  scale: 0.2,
                }
              }
              animate={
                isAnimationModal && {
                  scale: 1,
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                  },
                }
              }
              exit={
                isAnimationModal && {
                  opacity: [1, 0],
                  scale: [1, 0.8],
                  transition: {
                    duration: 0.3,
                  },
                }
              }
            >
              <MDBModalDialog
                centered={centered}
                size={`${screenSize}`}
                scrollable={scrollable ? true : false}
                className={dialogClassName}
              >
                <MDBModalContent className={contentClassName}>
                  <motion.div
                    style={{
                      willChange: "unset",
                    }}
                    initial={
                      isAnimationModal && {
                        opacity: 0,
                      }
                    }
                    animate={
                      isAnimationModal && {
                        opacity: 1,
                        transition: {
                          delay: 0.3,
                          duration: 0.3,
                        },
                      }
                    }
                    exit={
                      isAnimationModal && {
                        opacity: 0,
                        transition: {
                          duration: 0.3,
                        },
                      }
                    }
                  >
                    {isShowModalLoading && (
                      <section className="modal-overlay">
                        <article className="loading-box">
                          <Icon icon="line-md:loading-twotone-loop" />
                        </article>
                      </section>
                    )}
                    {content}
                  </motion.div>
                </MDBModalContent>
              </MDBModalDialog>
            </motion.div>
          </MDBModal>
        )}
      </AnimatePresence>
    </RemoveScroll>
  );
}
