import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setThemeColor } from "../../../redux/reducer/merchantReducer";

export default function HomeHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { merchantInfo } = useSelector((state) => state.merchant);

  const handleBackMerchant = () => {
    // dispatch(setThemeColor({ main_color: merchantInfo.supermerchant_color?.main_color ?? "#f6f5f5", bg_color: merchantInfo.supermerchant_color?.bg_color ?? "#f6f5f5" }))

    // if (merchantInfo.supermerchant_color?.main_color) {
    //   document.body.style.setProperty(
    //     "--primary_modal_color",
    //     merchantInfo.supermerchant_color?.main_color
    //   );
    // }

    // if (merchantInfo.supermerchant_color?.bg_color) {
    //   document.body.style.setProperty(
    //     "--primary_background_color",
    //     merchantInfo.supermerchant_color?.bg_color
    //   );
    // }

    navigate(`/${merchantInfo?.supermerchant_display_name}/branch`);
  };

  return (
    <header>
      {merchantInfo?.supermerchant_display_name && (
        <article
          className="back-list pointer"
          onClick={() => handleBackMerchant()}
        >
          <Icon
            icon="ic:round-arrow-back"
            color=""
            className="loading-icon --smaller ms-3 mb-1"
          />
        </article>
      )}
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {merchantInfo.banner?.length > 0 ? (
          merchantInfo.banner.map((banner, bannerIndex) => (
            <SwiperSlide className="home-header" key={bannerIndex}>
              <img src={banner.url} alt="" />
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide className="home-header"></SwiperSlide>
        )}
      </Swiper>
    </header>
  );
}
